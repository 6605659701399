import REMINDERS_DATA from "test/data/patient/reminders-data";
import simulateRequestWithResolve from "test/mock-api/simulateRequestWithResolve";

let NEXT_ID = Object.values(REMINDERS_DATA.REMINDERS).length + 1;

const REMINDERS_MOCK_API = {
  async requestReminders() {
    return new Promise((resolve) => {
      simulateRequestWithResolve(resolve, Object.values(REMINDERS_DATA.REMINDERS));
    });
  },

  async addReminder(data) {
    return new Promise(resolve => {
      REMINDERS_DATA.REMINDERS[NEXT_ID] = {
        ...data,
        id: NEXT_ID,
      };
      NEXT_ID++;
      simulateRequestWithResolve(resolve, true);
    })
  },

  async editReminder(data) {
    return new Promise(resolve => {
      REMINDERS_DATA.REMINDERS[data.id] = {
        ...data,
      };
      simulateRequestWithResolve(resolve, true);
    })
  },

  async deleteReminder(id) {
    return new Promise(resolve => {
      if (REMINDERS_DATA.REMINDERS[id]) {
        delete REMINDERS_DATA.REMINDERS[id];
        simulateRequestWithResolve(resolve, true);
      } else {
        simulateRequestWithResolve(resolve, false);
      }

    })
  },
};

export default REMINDERS_MOCK_API;