import ExercisesActions from "redux/actions/common/exercises-actions";
import groupByKey from "lib/groupByKey";

const initialState = {
  current: {
    id: undefined,
    data: {},
  },

  summary: {
    available: false,
    data: {},
  },

  running: false,

  categories: undefined,
  exerciseList: undefined,
  byId: undefined,
};

const TYPES = ExercisesActions.TYPES;

export default function exercises(state = initialState, action = {}) {
  const {type, payload} = action;

  switch(type) {
    case TYPES.EXERCISES_CHANGED: {
      const {exerciseList} = payload;
      const byId = groupByKey(exerciseList, "id");

      return {
        ...state,
        exerciseList,
        byId,
      };
    }

    case TYPES.EXERCISE_CATEGORIES_CHANGED: {
      const {categories} = payload;

      return {
        ...state,
        categories,
      }
    }

    case TYPES.SET_CURRENT_EXERCISE_ID: {
      const {currentId} = payload;
      const currentExercise = state.byId[currentId];

      if (!currentExercise) {
        return {
          ...state,
        }
      } else {
        return {
          ...state,
          current: {
            id: currentId,
            data: {...currentExercise},
          }
        }
      }
    }

    case TYPES.UPDATE_CURRENT_EXERCISE: {
      const {newData} = payload;

      return {
        ...state,
        current: {
          ...state.current,
          data: {
            ...state.current.data,
            ...newData
          }
        }
      }
    }

    case TYPES.START_EXERCISE: {
      return {
        ...state,
        running: true,
        summary: {
          available: false,
        }
      }
    }

    case TYPES.EXERCISE_FINISHED: {
      const {results, level} = payload;

      return {
        ...state,
        running: false,
        summary: {
          available: true,
          data: {
            finishLevel: level,
            results,
          }
        }
      }
    }

    default:
      return state
  }
}