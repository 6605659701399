import React, {useEffect} from "react";
import {Col, Container, Row} from "react-bootstrap";

import "vuexy/css/pages/authentication.css";
import VContent from "vuexy/components/VContent";
import VRow from "vuexy/components/VRow";

import "./Auth.scss";

import loginImg from "./images/login-img.svg";
import backgroundImg from "./images/auth-background.svg";

export default function AuthBase({children}) {
  useEffect(() => {
    document.body.classList.add("blank-page");

    return () => {
      document.body.classList.remove("blank-page")
    }
  }, []);

  return (
    <VContent className="Auth" style={{
      backgroundImage: `url(${backgroundImg}`,
    }}>
      <VContent.Content>
        <Container>
        <VRow>
          <Col xs={11} xl={12}
            className="LoginScreen d-flex justify-content-center"
          >
            {/*<div className="card bg-authentication mb-0">*/}
              <Container className="p-0">
                <Row className="m-0">
                  <Col lg={6}
                    className="d-lg-block d-none p-0"
                  >
                    <div className="loginImg"
                      style={{
                        backgroundImage: `url(${loginImg})`,
                      }}
                    />
                  </Col>
                  <Col lg={6}
                    className="p-0 auth-form"
                  >
                    {children}
                  </Col>
                </Row>
              </Container>
            {/*</div>*/}
          </Col>
        </VRow>
          </Container>
      </VContent.Content>
    </VContent>
  )
}