import FranciszekPhoto from "images/avatars/franciszek-terapeutowski.jpg";
import BoxingFeedbackVideo from "videos/boxing-feedback.webm";
import BozenaPhoto from "images/avatars/bozena-stokowa.jpg";

export const TYPES = {
  MESSAGE: 'message',
  REPORT_SENT: 'sent',
  NEW_SESSION: 'new_session',
};

const MESSAGES_DATA = {
  messages: {
    1: {
      id: 1,
      type: TYPES.MESSAGE,
      name: "Franek Terapeutowski",
      subject: "Pierwszy tydzień ćwiczeń",
      avatar: FranciszekPhoto,
      date: "09.01.2020 13:42",
      content: "Pani Bożeno, sesje w tym tygodniu super. Proszę trochę bardziej uważać na prawą rękę w boksie, powinna ją Pani unosić troszkę wyżej. Załączam film poglądowy, jak to powinno wyglądać.",
      video: BoxingFeedbackVideo,
      read: false,
    },
    2: {
      id: 2,
      type: TYPES.REPORT_SENT,
      name: "Bożena Stokowa",
      subject: "Wysłano raport z ćwiczeń.",
      avatar: BozenaPhoto,
      date: "08.01.2020 13:45",
      content: "Pani Bożeno, Dziękujemy za zakup Neuroformy wraz z Teleopieką. Przez następny miesiąc opieka i ćwiczenia będą aktywne.",
      read: true,
    },
    3: {
      id: 3,
      type: TYPES.NEW_SESSION,
      name: "Franek Terapeutowski",
      subject: "Dziękuję za wybór mnie jako terapeuty! Przesyłam pierwszą sesję ćwiczeniową.",
      avatar: FranciszekPhoto,
      date: "07.01.2020 13:42",
      content: "Pani Bożeno, Do roboty! Ćwiczymy, nie śpimy.",
      read: true,
    },
    4: {
      id: 4,
      type: TYPES.MESSAGE,
      name: "Janek Neuroformowski",
      subject: "Teleopieka aktywna!",
      avatar: FranciszekPhoto,
      date: "07.01.2020 11:23",
      content: "Pani Bożeno, Dziękujemy za zakup Neuroformy wraz z Teleopieką. Przez następny miesiąc opieka i ćwiczenia będą aktywne.",
      read: true,
    },
  },
};

export default MESSAGES_DATA;