import {useDispatch, useSelector} from "react-redux";
import React, {useEffect, useState} from "react";
import AuthActions from "redux/actions/common/auth-actions";
// import JOBS from "enums/therapist/jobs";
// import SPECIALIZATIONS from "enums/therapist/specializations";
import AuthBase from ".";
import {Button, FormGroup, Card, Form} from "react-bootstrap";
import FormError from "./subcomponents/FormError";
import GoogleLogin from "react-google-login";
import {FACEBOOK_APP_ID, GOOGLE_CLIENT_ID} from ".";
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
import {Link, Redirect} from "react-router-dom";
import useErrors from "lib/hooks/useErrors";
import validate from "validate.js";
import {loginFacebookWithDipsatch$, loginGoogleWithDipsatch$} from "./helpers/social";
import FormField from "./subcomponents/FormField";
import fbLogo from "./images/facebook.png";
import gLogo from "./images/google.png";

validate.validators.isTrue = function(value) {
  if (value !== true) {
    return "Musisz zaakceptować warunki użytkowania i politykę prywatności";
  }
};

const FIELDS = [
  {
    key: "first_name",
    name: "Imię",
    type: "text",
  },
  {
    key: "last_name",
    name: "Nazwisko",
    type: "text",
  },
  {
    key: "email",
    name: "E-mail",
    type: "text",
  },
  {
    key: "password1",
    name: "Hasło",
    type: "password",
  },
  {
    key: "password2",
    name: "Powtórz hasło",
    type: "password",
  },
]

const CONSTRAINTS = {
  "first_name": {
    presence: {
      allowEmpty: false,
      message: "Podaj swoje imię"
    },
  },
  "last_name": {
    presence: {
      allowEmpty: false,
      message: "Podaj swoje nazwisko"
    },
  },
  "email": {
    presence: {
      allowEmpty: false,
      message: "Podaj swój adres e-mail"
    },
    email: {
      message: "Podaj prawidłowy adres e-mail"
    },
  },
  "password1": {
    presence: {
      allowEmpty: false,
      message: "Podaj hasło zabezpieczające konto",
    },
    length: {
      minimum: 6,
      message: "Hasło musi mieć minimum 6 znaków"
    },
  },
  "password2": {
    equality: {
      attribute: "password1",
      message: "Powtórzone hasło nie jest takie samo jak hasło powyżej",
    }
  },
  "consent": {
    isTrue: true,
  }
}

// TODO: Add therapist part
// const THERAPIST_FIELDS = [
//   {...FIELDS[0]},
//   {...FIELDS[1]},
//   {...FIELDS[2]},
//   {
//     key: "job_type",
//     name: "Stanowisko",
//     type: "choices",
//     choices: JOBS,
//   },
//   {
//     key: "specialization",
//     name: "Specjalizacja",
//     type: "choices",
//     choices: SPECIALIZATIONS,
//   },
//   {...FIELDS[3]},
//   {...FIELDS[4]},
// ]
//
// const THERAPIST_CONSTRAINTS = {
//   ...CONSTRAINTS
// }

export default function Register({isTherapist}) {
  const dispatch = useDispatch();
  const isTryingToRegister = useSelector(state => state.auth.isProcessing);
  const errors = useSelector(state => state.auth.errors);
  const isUserLoggedIn = useSelector(state => state.auth.isUserLoggedIn);
  const [data, setData] = useState({
    first_name: "",
    last_name: "",
    email: "",
    job_type: "",
    specialization: "",
    password1: "",
    password2: "",
    consent: false,
  });
  const [processedErrors, setProcessedErrors] = useErrors({}, [
    "first_name", "last_name", "email", "password1", "password2", "job_type", "specialization",
  ]);

  useEffect(() => {
    setProcessedErrors(errors);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errors]);

  const responseFacebook = loginFacebookWithDipsatch$(dispatch);
  const responseGoogle = loginGoogleWithDipsatch$(dispatch);

  const failureGoogle = (error) => {
    console.error("Google login error");
  };

  const setDataForKey = (key, value) => {
    setData({
      ...data,
      [key]: value,
    });
  }

  const register = () => {
    const validationErrors = validate(data, CONSTRAINTS, {fullMessages: false});
    if (validationErrors === undefined) {
      setProcessedErrors({});
      dispatch(AuthActions.register(data, isTherapist));
    } else {
      setProcessedErrors(validationErrors);
    }
  };

  if (isUserLoggedIn) {
    return <Redirect to="/" />
  }

  return <AuthBase>
    <Card className="mb-0 px-2">
      <Card.Header className="pb-1">
        <Card.Title className="w-100">
          <h1 className="mb-0 text-center text-primary">Zarejestruj się</h1>
        </Card.Title>
      </Card.Header>
      <p className="px-2">Witamy w Neuroformie. Skorzystaj z poniższego formularza i zarejestruj <strong>bezpłatne konto</strong>.</p>
      <div className="card-content">
        <div className="card-body">
          <Form className="form-vertical">
            <div className="form-body">
              {FIELDS.map(({key, name, type}, index) => (
                <FormField key={index}
                  fieldKey={key}
                  name={name}
                  type={type}

                  data={data} processedErrors={processedErrors} setDataForKey={setDataForKey}
                />
              ))}
              <FormGroup className="mb-0">
                <fieldset className="checkbox">
                  <div className="vs-checkbox-con vs-checkbox-primary">
                    <input id="consent"
                      type="checkbox"

                      value={data["consent"]}
                      onChange={(e) => setDataForKey("consent", e.target.checked)}
                    />
                    <span className="vs-checkbox">
                          <span className="vs-checkbox--check">
                            <i className="vs-icon feather icon-check" />
                          </span>
                      </span>
                    <span>Akceptuję <a href="/usage">Warunki użytkowania</a> i <a href="/privacy">Politykę prywatności</a></span>
                  </div>
                </fieldset>
              </FormGroup>
              <FormError errors={processedErrors} errorKey={"other"} />
              <Button
                className="btn-block mt-1"
                onClick={(e) => {
                  e.preventDefault();
                  register();
                }}
                disabled={isTryingToRegister}

                type="submit"
              >
                {isTryingToRegister ? <><div className="spinner-border spinner-border-sm mr-1" /> Trwa rejestracja</> : <>Zarejestruj się</>}
              </Button>
            </div>
          </Form>
          <hr className="mt-3 mb-3" />
          <div className="d-flex justify-content-between align-items-center">
            <GoogleLogin
              clientId={GOOGLE_CLIENT_ID}
              onSuccess={responseGoogle}
              onFailure={failureGoogle}
              cookiePolicy={'single_host_origin'}

              render={renderProps => (
                <button className="btn btn-block btn-outline-primary mr-1 social-login google" onClick={renderProps.onClick} disabled={renderProps.disabled}>
                  <img src={gLogo} alt="Google" /> Zarejestruj się z Google
                </button>
              )}
            />
            <FacebookLogin
              appId={FACEBOOK_APP_ID}
              fields="name,email,picture"
              callback={responseFacebook}

              render={renderProps => (
                <button className="btn btn-block btn-primary mt-0 social-login facebook" onClick={renderProps.onClick} disabled={renderProps.disabled}>
                  <img src={fbLogo} alt="Facebook" /> Zarejestruj się z Facebook
                </button>
              )}
            />
          </div>
          <p className="text-center mt-1">
            Jeśli posiadasz już konto <Link to={`/login/`}>zaloguj się</Link>.
          </p>
        </div>
      </div>
    </Card>
  </AuthBase>;
}
