const PROFILE_DATA = {
  "b.stokowa12@wp.pl": {
    first_name: "Bożena",
    last_name: "Stokowa",
    email: "b.stokowa12@wp.pl",
    uses_push: true,
  },
  "franek.terapeutowski@neuroforma.pl": {
    first_name: "Franek",
    last_name: "Terapeutowski",
    email: "franek.terapeutowski@neuroforma.pl",
    job_type: "Fizjoterapeuta",
    specialisation: "mobility",
    uses_push: true,
  },
  "ft": {
    first_name: "Franek",
    last_name: "Terapeutowski",
    email: "franek.terapeutowski@neuroforma.pl",
    job_type: "Fizjoterapeuta",
    specialisation: "mobility",
    uses_push: true,
  },
};

export default PROFILE_DATA;