import PATIENTS_MOCK_API from "test/mock-api/therapist/patients-mock-api";
import {put, all, takeEvery} from "redux-saga/effects";
import PatientsActions from "redux/actions/therapist/patients-actions";

class PatientsSagas {
  static* updatePatientsList() {
    const patientsList = yield PATIENTS_MOCK_API.requestPatientList();
    yield put(PatientsActions.patientsUpdated(patientsList));
  }

  static* watchUpdatePatientsList() {
    yield takeEvery(PatientsActions.TYPES.UPDATE_PATIENTS, PatientsSagas.updatePatientsList);
  }
}

export default function* patientsSaga() {
  yield all([
    PatientsSagas.watchUpdatePatientsList(),
  ])
}