import React from "react";
import {Button, H2, Intent} from "@blueprintjs/core";
import FlexDiv from "../../../ui/layout/FlexDiv";
import {useSelector} from "react-redux";

export default function ExerciseSummary({onClose}) {
  const summary = useSelector(state => state.exercises.summary);

  if (!summary.available) {
    onClose();
    return;
  }

  return <div className="ExerciseSummary">
    <FlexDiv>
      <H2>Ćwiczenie zakończone</H2>
      <p>Poziom: {summary.data.finishLevel}</p>
      <p>Punkty: {summary.data.results.score}</p>
      <Button
        large={true}
        intent={Intent.PRIMARY}
        icon="cross"
        text="Zamknij"

        onClick={onClose}
      />
    </FlexDiv>
  </div>
}