import {defaultTo} from "lodash";
import DefaultIcon from "../../../images/game-icons/default.svg";
import {Badge} from "react-bootstrap";
import React from "react";

import "./ExerciseTitle.scss";
import ExerciseFavouriteButton from "./ExerciseFavouriteButton";

export default function ExerciseTitle({
  name, icon, categories,
  selectedCategoryId, setCategory$=()=>{},
  favouritesPosition, includeFavouritesDescription, isFavourite, toggleIsFavourite,
}) {

  return (
    <div className="ExerciseTitle">
      <div className="exercise-heading">
        <div className="image">
          <img src={defaultTo(icon, DefaultIcon)} alt="Ikona ćwiczenia" />
        </div>
        <div className="title">
          <h4>{name}</h4>
        </div>
        {favouritesPosition === "top" &&
        <ExerciseFavouriteButton className="p-0" includeDescription={includeFavouritesDescription}
          isFavourite={isFavourite} toggleIsFavourite={toggleIsFavourite}
        />
        }
      </div>
      <div className="tags">
        { categories.map(({id: categoryId, name: categoryName}, index) => (
          <Badge key={index}
            pill={true}
            variant={categoryId === selectedCategoryId ? "primary" : "secondary"}

            onClick={setCategory$(categoryId)}
          >
            <div className="pill-circle bg-primary" /> {categoryName}
          </Badge>
        ))}
      </div>
    </div>
  )
}