export default class RemindersActions {
  static TYPES = {
    REQUEST_REMINDERS: "REQUEST_REMINDERS",
    REMINDERS_CHANGED: "REMINDERS_CHANGED",
    ADD_REMINDER: "ADD_REMINDER",
    DELETE_REMINDER: "DELETE_REMINDER",

    START_ADDING_REMINDER: "START_ADDING_REMINDER",
    START_EDITING_REMINDER: "START_EDITING_REMINDER",
    STOP_EDITING: "STOP_EDITING",

    CHANGE_CURRENT_REMINDER: "CHANGE_CURRENT_REMINDER",
    SAVE_CURRENT_REMINDER: "SAVE_CURRENT_REMINDER",
  };

  static requestReminders = () => ({
    type: TYPES.REQUEST_REMINDERS,
    payload: {}
  });

  static remindersChanged = (reminders) => ({
    type: TYPES.REMINDERS_CHANGED,
    payload: {
      reminders
    }
  });

  static addReminder = (data) => ({
    type: TYPES.ADD_REMINDER,
    payload: {
      data,
    },
  });

  static deleteReminder = (id) => ({
    type: TYPES.DELETE_REMINDER,
    payload: {
      id,
    }
  });

  static startAddingReminder = () => ({
    type: TYPES.START_ADDING_REMINDER,
    payload: {},
  });

  static startEditingReminder = (id) => ({
    type: TYPES.START_EDITING_REMINDER,
    payload: {
      id
    },
  });

  static stopEditing = () => ({
    type: TYPES.STOP_EDITING,
    payload: {},
  });


  static changeCurrentReminder = (data) => ({
    type: TYPES.CHANGE_CURRENT_REMINDER,
    payload: {data},
  });

  static saveCurrentReminder = () => ({
    type: TYPES.SAVE_CURRENT_REMINDER,
    payload: {},
  });
}

const TYPES = RemindersActions.TYPES;