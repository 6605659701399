import React, {useState} from 'react';
import {Button, FormGroup, Intent, Slider, Switch} from "@blueprintjs/core";
import CardHeading from "ui/text/CardHeading";
import ResponsiveCard from "ui/ResponsiveCard";

import "./Settings.scss";
import {defaultTo} from "lodash";
import {AppToaster} from "../../App";

const DEFAULT_SETTINGS = {
  volumeSound: 50,
  volumeMusic: 50,
  subtitles: true,
};
localStorage.getItem('volume-sound');

function getIntLocalStorage(key) {
  let value = localStorage.getItem(key);

  if (value !== undefined) {
    value = parseInt(value);
  }

  return value
}

function getBoolLocalStorage(key) {
  let value = localStorage.getItem(key);

  if (value !== undefined) {
    value = (value === "true")
  }

  return value;
}

export default function Settings(props) {
  const [volumeSound, setVolumeSound] = useState(
    defaultTo(getIntLocalStorage('volume-sound'), DEFAULT_SETTINGS.volumeSound)
  );
  const [volumeMusic, setVolumeMusic] = useState(
    defaultTo(getIntLocalStorage('volume-music'), DEFAULT_SETTINGS.volumeMusic)
  );
  const [showSubtitles, setShowSubtitles] = useState(
    defaultTo(getBoolLocalStorage('show-subtitles'), DEFAULT_SETTINGS.subtitles)
  );

  const save = () => {
    localStorage.setItem('volume-sound', volumeSound.toString());
    localStorage.setItem('volume-music', volumeMusic.toString());
    localStorage.setItem('show-subtitles', showSubtitles.toString());

    AppToaster.show({
      icon: "endorsed",
      intent: Intent.SUCCESS,
      message: "Dane zapisane.",
    });
  };

  return <ResponsiveCard
    className="Settings"
    size={ResponsiveCard.SIZES.md}
  >
    <CardHeading>
      Pozostałe ustawienia
    </CardHeading>
    <FormGroup
      label="Głośność dźwięków"
      labelFor="volume-sounds"
    >
      <Slider
        id="volume-sounds"

        value={volumeSound}
        onChange={setVolumeSound}

        min={0}
        max={100}

        stepSize={1}

        labelStepSize={25}
        labelRenderer={(number) => (`${number}%`)}
      />
    </FormGroup>
    <FormGroup
      label="Głośność muzyki"
      labelFor="volume-music"
    >
      <Slider
        id="volume-music"

        value={volumeMusic}
        onChange={setVolumeMusic}

        min={0}
        max={100}

        stepSize={1}

        labelStepSize={25}
        labelRenderer={(number) => (`${number}%`)}
      />
    </FormGroup>
    <FormGroup>
      <Switch
        large={true}
        innerLabelChecked="Tak"
        innerLabel="Nie"

        checked={showSubtitles}
        onChange={(e) => setShowSubtitles(e.target.checked)}

        label="Pokazywać napisy w filmach"
      />
    </FormGroup>

    <div className="save-button-container">
      <Button
        large={true}
        intent={Intent.PRIMARY}

        icon="confirm"
        text="Zapisz"

        onClick={save}
      />
    </div>
  </ResponsiveCard>
}