import {useState} from 'react';

export default function useToggle(defaultValue, needSetFunction = false) {
  const [value, setValue] = useState(defaultValue);
  const toggleValue = function() { setValue(!value) };

  if (needSetFunction) {
    return [value, setValue, toggleValue];
  } else {
    return [value, toggleValue];
  }
}

export function useToggleWithSet(defaultValue) {
  return useToggle(defaultValue, true)
}