import {LoremIpsum} from "lorem-ipsum";

const loremGenerator = new LoremIpsum({
  sentencesPerParagraph: {
    max: 5,
    min: 3
  },
  wordsPerSentence: {
    max: 8,
    min: 4
  }
});

export default loremGenerator;