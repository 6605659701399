export default class Actions {
  static empty = (type) => () => ({
    type: type,
    payload: {},
  });

  static error = (type) => (message) => ({
    type: type,
    payload: {
      message
    },
  });

  static errors = (type) => (errors) => ({
    type: type,
    payload: {
      errors
    },
  });
}