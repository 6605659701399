import {Nav, Navbar, NavItem, NavLink} from "react-bootstrap";
import NavbarCollapse from "react-bootstrap/NavbarCollapse";
import {LinkContainer} from "react-router-bootstrap";

import logotypeImg from "images/logo/logotype.png";
import Notifications from "../../../views/Notifications";
import SettingsButton from "../../../ui/buttons/SettingsButton";
import React from "react";

import "./PortalMenu.scss";

export default function PortalMenu({profile, settingsActions}) {
  return (
    <Navbar className="PortalMenu header-navbar navbar-with-menu navbar-fixed navbar-shadow">
      <div className="navbar-wrapper">
        <div className="navbar-container content">
          <NavbarCollapse>
            <Navbar.Brand>
              <LinkContainer to={"/"}>
                <a href="/">
                  <img src={logotypeImg} alt="Neuroforma" />
                </a>
              </LinkContainer>
            </Navbar.Brand>
            <Nav as="ul" className="nav main-navigation">
              <LinkContainer to={"/exercises/"}>
                <NavItem as="li">
                  <NavLink>Ćwicz</NavLink>
                </NavItem>
              </LinkContainer>
              <NavItem as="li" className="disabled">
                <NavLink disabled={true}>Społeczność</NavLink>
              </NavItem>
              <NavItem as="li" className="disabled">
                <NavLink disabled={true}>Osiągnięcia</NavLink>
              </NavItem>
              <NavItem as="li" className="disabled">
                <NavLink disabled={true}>Teleopieka</NavLink>
              </NavItem>
            </Nav>
            <Nav className="float-right align-items-center">
              <Notifications className="mr-1 d-none"

              />
              <SettingsButton
                profile={profile}
                actions={settingsActions}
              />
            </Nav>
          </NavbarCollapse>
        </div>
      </div>
    </Navbar>
  );
}