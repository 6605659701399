import ReportActions from "redux/actions/common/report-actions";

export const TASK_TYPES = {
  NEW_SESSION: "new-session",
  NOT_EXERCISING: "not-exercising",
  REPORT: "report",
};

const initialState = {
  data: undefined,
};

const TYPES = ReportActions.TYPES;

export default function reportReducer(state = initialState, action = {}) {
  const {type, payload} = action;

  switch(type) {
    case (TYPES.REPORT_CHANGED): {
      const {report} = payload;

      return {
        ...state,
        data: report,
      }
    }

    default:
      return state
  }
}