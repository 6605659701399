import React from "react";
import clsx from "clsx";
import { Badge } from "react-bootstrap";

export default function VAvatar({className, color, size, img, content, icon,
  badgeUp, badgeColor, badgeText,
  imgHeight, imgWidth,
  status,
  onClick, onHover
}) {
  return (
    <div
      className={clsx(
        `avatar ${color ? `bg-${color}` : undefined}  ${
          className
        }`,
        {
          "avatar-sm": size && size === "sm",
          "avatar-lg": size && size === "lg",
          "avatar-xl": size && size === "xl"
        }
      )}

      onClick={onClick}
      onMouseEnter={onHover}
    >
      {img === false || img === undefined ? (
        <span
          className={clsx("avatar-content", {
            "position-relative": badgeUp
          })}
        >
          {content ? content : null}

          {icon ? (
            <div className="avatar-icon">{icon}</div>
          ) : null}
          {badgeUp ? (
            <Badge
              variant={
                badgeColor ? badgeColor : "primary"
              }
              className="badge-sm badge-up"
              pill
            >
              {badgeText ? badgeText : "0"}
            </Badge>
          ) : null}
        </span>
      ) : (
        <img
          src={img}
          alt="avatarImg"
          height={
            imgHeight && !size
              ? imgHeight
              : 32
          }
          width={
            imgWidth && !size ? imgWidth : 32
          }
        />
      )}
      {status ? (
        <span className={`avatar-status-${status}`} />
      ) : null}
    </div>
  )
}
