import {PAYMENT_TYPES, SERVICE_TYPES} from "redux/reducers/common/services-reducer";

const CURRENT_YEAR = new Date().getFullYear();
const CURRENT_MONTH = new Date().getMonth();


const SERVICES_DATA = {
  SERVICES: {
    1: {
      id: 1,
      autoextend: true,
      type: SERVICE_TYPES.ACCESS,
      name: "Dostęp do ćwiczeń (1 miesiąc)",
      price: "80",
      from: new Date(CURRENT_YEAR, CURRENT_MONTH, 1),
      to: new Date(CURRENT_YEAR, CURRENT_MONTH, 31),
    },
    2: {
      id: 2,
      autoextend: false,
      type: SERVICE_TYPES.TELENF,
      name: "Opieka terapeuty (1 miesiąc)",
      price: "120",
      from: new Date(CURRENT_YEAR, CURRENT_MONTH, 1),
      to: new Date(CURRENT_YEAR, CURRENT_MONTH, 31),
    },
    3: {
      id: 3,
      name: "Dostęp do ćwiczeń (1 miesiąc)",
      price: "80",
      type: SERVICE_TYPES.ACCESS,
      from: new Date(CURRENT_YEAR, CURRENT_MONTH - 1, 1),
      to: new Date(CURRENT_YEAR, CURRENT_MONTH - 1, 28),
    },
    4: {
      id: 4,
      name: "Dostęp do ćwiczeń (3 miesiące)",
      price: "160",
      type: SERVICE_TYPES.ACCESS,
      from: new Date(CURRENT_YEAR, CURRENT_MONTH - 1, 1),
      to: new Date(CURRENT_YEAR, CURRENT_MONTH - 1, 28),
    },
  },
  PAYMENTS: {
    1: {
      id: 1,
      date: new Date(CURRENT_YEAR, CURRENT_MONTH + 1, 1),
      type: SERVICE_TYPES.ACCESS,
      price: 80,
      payment_type: PAYMENT_TYPES.CREDIT_CARD,
    }
  },
};

export default SERVICES_DATA;