import {AUTH_API} from "api/common";
import {put, all, takeEvery, select} from "redux-saga/effects";
import AuthActions from "redux/actions/common/auth-actions";
import ProfileActions from "../../actions/common/profile-actions";

class AuthSagas {
  static* tryLoggingIn(action) {
    const {email, password} = action.payload;

    const {loggedIn, errors} = yield AUTH_API.tryLoggingIn(email, password);

    if (loggedIn) {
      yield put(ProfileActions.requestProfile());
    } else {
      yield put(AuthActions.loginError(errors));
    }
  }

  static* watchTryLoggingIn() {
    yield takeEvery(AuthActions.TYPES.TRY_LOGGING_IN, AuthSagas.tryLoggingIn);
  }

  static* register(action) {
    const {data, therapist} = action.payload;

    const {profile, errors} = yield AUTH_API.register(data, therapist);
    if (profile) {
      yield put(AuthActions.registered());
      yield put(ProfileActions.profileChanged(profile));
      yield put(ProfileActions.loggedProfileChanged(profile));
    } else {
      yield put(AuthActions.registrationError(errors));
    }
  }

  static* watchRegister() {
    yield takeEvery(AuthActions.TYPES.REGISTER, AuthSagas.register)
  }

  static* changePassword(action) {
    const {oldPassword, newPassword} = action.payload;
    const email = yield select(state => state.auth.email);

    const wasPasswordChangedSuccessfully = yield AUTH_API.changePassword(email, oldPassword, newPassword);
    if (wasPasswordChangedSuccessfully) {
      yield put(AuthActions.passwordChanged());
    } else {
      yield put(AuthActions.passwordChangeError("Old password is not correct."));
    }
  }

  static* watchChangePassword() {
    yield takeEvery(AuthActions.TYPES.CHANGE_PASSWORD, AuthSagas.changePassword)
  }

  static* loginWithSocial(action) {
    const {accessToken, type} = action.payload;

    const loggedIn = yield AUTH_API.loginWithSocial(type, accessToken);

    if (loggedIn) {
      yield put(ProfileActions.requestProfile());
    } else {
      yield put(AuthActions.loginError({social: "Wystąpił błąd podczas logowania przez aplikację społeczną"}));
    }
  }

  static* watchLoginWithSocial() {
    yield takeEvery(AuthActions.TYPES.LOGIN_WITH_SOCIAL, AuthSagas.loginWithSocial);
  }

  static* logout() {
    yield AUTH_API.logout();
  }

  static* watchLogout() {
    yield takeEvery(AuthActions.TYPES.LOGOUT, AuthSagas.logout)
  }
}

export default function* authSaga() {
  yield all([
    AuthSagas.watchTryLoggingIn(),
    AuthSagas.watchRegister(),
    AuthSagas.watchChangePassword(),
    AuthSagas.watchLoginWithSocial(),
    AuthSagas.watchLogout(),
  ]);
}