class duplicateKeyError extends Error {
  constructor(key, ...params) {
    // Pass remaining arguments (including vendor specific ones) to parent constructor
    super(...params);
    this.name = 'duplicateKeyError';
    this.message = `Duplicate key: ${key}`
  }
}

export default function groupByKey(list, key, idFunction = id => id) {
  let dictionary = {};

  for (const element of list) {
    const id = idFunction(element[key]);

    if (dictionary[id]) {
      throw new duplicateKeyError(id);
    } else {
      dictionary[id] = element;
    }
  }

  return dictionary;
}