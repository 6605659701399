import groupByKey from "lib/groupByKey";
import EarningsActions from "redux/actions/therapist/earnings-actions";

const initialState = {
  paid: undefined,
  unpaid: undefined,
  byId: undefined,

  nextPayment: undefined,
};

const TYPES = EarningsActions.TYPES;

export const EARNINGS_TYPES = {
  NEW_SESSION: "NEW_SESSION",
  REPORT: "REPORT",
};

export default function earningsReducer(state = initialState, action = {}) {
  const {type, payload} = action;

  switch(type) {
    case TYPES.EARNINGS_CHANGED: {
      const {list, nextPayment} = payload;
      const byId = groupByKey(list, "id");
      const unpaid = [...list].filter(earning => !earning.paid);
      const paid = [...list].filter(earning => !!earning.paid);

      return {
        ...state,
        paid,
        unpaid,
        byId,

        nextPayment,
      };
    }

    default:
      return state
  }
}