import SESSIONS_DATA from "test/data/common/sessions-data";
import simulateRequestWithResolve from "../simulateRequestWithResolve";

const SESSIONS_MOCK_API = {
  async requestSessionList() {
    return new Promise(resolve => {
      simulateRequestWithResolve(
        resolve,
        Object.values(SESSIONS_DATA.SESSIONS),
      );
    });
  },

  async addSession(data) {
    return new Promise(resolve => {
      const newId = Object.entries(SESSIONS_DATA.SESSIONS);
      data["id"] = newId;
      data["editable"] = true;

      SESSIONS_DATA.SESSIONS[newId] = {
        ...data,
      };

      simulateRequestWithResolve(
        resolve,
        true
      );
    });
  },

  async updateSession(id, data) {
    return new Promise(resolve => {
      SESSIONS_DATA.SESSIONS[id] = {
        ...SESSIONS_DATA.SESSIONS[id],
        ...data,
      };

      simulateRequestWithResolve(
        resolve,
        true
      );
    });
  }
};

export default SESSIONS_MOCK_API;