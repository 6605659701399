import React, {useEffect} from 'react';
import {Button, ButtonGroup, FormGroup, Icon, InputGroup, Intent, TextArea} from "@blueprintjs/core";
import {useDispatch, useSelector, shallowEqual} from "react-redux";
import classNames from 'classnames';

import ResponsiveCard from "../../../ui/ResponsiveCard";
import CardHeading from "../../../ui/text/CardHeading";


import SessionList from "views/SessionList";
import FullScreenOverlay from "ui/FullScreenOverlay";
import CloseCardButton from "ui/CloseCardButton";
import Messages from "../../Messages";
import useToggle, {useToggleWithSet} from "../../../lib/useToggle";
import Reports from "../../Reports";

import {AppToaster} from "App";
import "./Patients.scss";
import PatientsActions from "../../../redux/actions/therapist/patients-actions";
import Loader from "ui/Loader";
import Avatar from "../../../ui/components/Avatar";
import useGoToWithHistory from "../../../lib/useGoToWithHistory";
import {withRouter} from "react-router-dom";

const ACTIVITY_TYPES = {
  EXERCISE: 'exercise',
  MESSAGE: 'message',
};
const ACTIVITY_TYPES_ICON = {
  [ACTIVITY_TYPES.EXERCISE]: "walk",
  [ACTIVITY_TYPES.MESSAGE]: "envelope",
};

function Patients({history}) {
  const dispatch = useDispatch();
  const goTo = useGoToWithHistory(history);
  const patientsList = useSelector(state => state.therapist.patients.patientsList, shallowEqual);
  const [areMessagesOpen, toggleAreMessagesOpen] = useToggle(false);
  const [isReportOpen, toggleIsReportOpen] = useToggle(false);
  const [areSessionsOpen, toggleAreSessionsOpen] = useToggle(false);
  const [isInvitationFormOpen, setIsInvitationFormOpen, toggleIsInvitationFormOpen] = useToggleWithSet(false);

  useEffect(() => {
    dispatch(PatientsActions.updatePatients());
  }, [dispatch]);

  const selectPatient$ = (patientId$) => () => {
    dispatch(PatientsActions.selectPatient(patientId$));
    goTo("/patient/");
  };

  function sendInvitation() {
    AppToaster.show({
      icon: "endorsed",
      intent: Intent.SUCCESS,
      message: "Zaproszenie wysłane!",
    });

    setIsInvitationFormOpen(false);
  }

  return <ResponsiveCard className="Patients"
    size={ResponsiveCard.SIZES.md}
  >
    <section className="actions-section">
      <div className="actions"><ButtonGroup>
        <Button
          large={true}
          intent={Intent.PRIMARY}

          icon="new-person"
          text="Zaproś nowego pacjenta"

          onClick={toggleIsInvitationFormOpen}
        />
      </ButtonGroup></div>
    </section>
    <section className="patients-section">
      <CardHeading>
        Twoi pacjenci
      </CardHeading>
      <div className="patients-list">
        {!patientsList &&
          <Loader
            text="Wczytuję listę pacjentów"
          />
        }
        {patientsList && Object.entries(patientsList).map(([id, patientInfo]) => (
          <Patient key={id}
            {...patientInfo}

            selectPatient={selectPatient$(id)}

            openSessions={toggleAreSessionsOpen}
            openReports={toggleIsReportOpen}
            openMessages={toggleAreMessagesOpen}
          />
        ))}
      </div>
    </section>
    <FullScreenOverlay
      isOpen={isInvitationFormOpen}
      onClose={toggleIsInvitationFormOpen}
    >
      <ResponsiveCard className="patient-details messages"
        size={ResponsiveCard.SIZES.sm}
      >
        <SendInvitationForm
          send={sendInvitation}
        />
      </ResponsiveCard>
    </FullScreenOverlay>
    <FullScreenOverlay
      isOpen={areMessagesOpen}
      onClose={toggleAreMessagesOpen}
    >
      <ResponsiveCard className="patient-details messages"
        size={ResponsiveCard.SIZES.md}
      >
        <CloseCardButton
          onClick={toggleAreMessagesOpen}
        />
        <Messages
          inline={true}
        />
        <ButtonGroup className="actions"
          large={true}
        >
          <Button
            intent={Intent.DANGER}
            icon="cross"
            text="Zamknij"

            onClick={toggleAreMessagesOpen}
          />
        </ButtonGroup>
      </ResponsiveCard>
    </FullScreenOverlay>
    <FullScreenOverlay
      isOpen={isReportOpen}
      onClose={toggleIsReportOpen}
    >
      <ResponsiveCard className="patient-details report"
        size={ResponsiveCard.SIZES.md}
      >
        <CloseCardButton
          onClick={toggleIsReportOpen}
        />
        <Reports
          inline={true}
        />
        <ButtonGroup className="actions"
          large={true}
        >
          <Button
            intent={Intent.DANGER}
            icon="cross"
            text="Zamknij"

            onClick={toggleIsReportOpen}
          />
        </ButtonGroup>
      </ResponsiveCard>
    </FullScreenOverlay>
    <FullScreenOverlay
      isOpen={areSessionsOpen}
      onClose={toggleAreSessionsOpen}
    >
      <ResponsiveCard className="patient-details sessions"
        size={ResponsiveCard.SIZES.md}
      >
        <CloseCardButton
          onClick={toggleAreSessionsOpen}
        />
        <SessionList
          inline={true}
          forTherapist={true}
        />
        <ButtonGroup className="actions"
          large={true}
        >
          <Button
            intent={Intent.DANGER}
            icon="cross"
            text="Zamknij"

            onClick={toggleAreSessionsOpen}
          />
        </ButtonGroup>
      </ResponsiveCard>
    </FullScreenOverlay>
  </ResponsiveCard>
}

function Patient({
  avatar, name, telenf, lastActivityDate, lastActivityType,
  selectPatient,
}) {
  return <div className="Patient">
    <Avatar
      size="64px"
      {...avatar}
    />
    <div className="name">
      {name}
    </div>
    <div className="info">
      <div className="last-activity">
        Ostatnia aktywność: {lastActivityDate.toLocaleString()} <Icon icon={ACTIVITY_TYPES_ICON[lastActivityType]} />
      </div>
      <div className={classNames("telenf", {"active": telenf})}>
        {telenf ? <strong>Teleopieka aktywna</strong> : "Teleopieka nieaktywna"}
      </div>
    </div>
    <div className="actions">
      <Button
        intent={Intent.PRIMARY}

        icon="confirm"
        text="Wybierz"

        onClick={selectPatient}
      />
    </div>
  </div>
}

function SendInvitationForm({send}) {
  return <div className="SendInvitationForm">
    <CardHeading>
      Zaproś pacjenta
    </CardHeading>
    <FormGroup
      label="Imię i nazwisko"
      labelFor="name"
    >
      <InputGroup id="name"

      />
    </FormGroup>
    <FormGroup
      label="Adres e-mail"
      labelFor="email"
    >
      <InputGroup id="email"

      />
    </FormGroup>
    <FormGroup
      label="Wiadomość"
      labelFor="email"
    >
      <TextArea />
    </FormGroup>
    <ButtonGroup className="actions"
      large={true}
    >
      <Button
        intent={Intent.PRIMARY}
        icon="envelope"
        text="Wyślij"

        onClick={send}
      />
    </ButtonGroup>
  </div>
}

export default withRouter(Patients);