import React from 'react';
import ReactDOM from 'react-dom';
import App from './App/App';
// import * as serviceWorker from './serviceWorker';

import store from "redux/store";
import {Provider} from "react-redux";
import {BrowserRouter} from "react-router-dom";

// Register videojs-wavesurfer plugin
import 'videojs-wavesurfer/dist/css/videojs.wavesurfer.css';
// eslint-disable-next-line no-unused-vars
import Wavesurfer from 'videojs-wavesurfer/dist/videojs.wavesurfer.js';

// register videojs-record plugin with this import
import 'videojs-record/dist/css/videojs.record.css';
// eslint-disable-next-line no-unused-vars
import Record from 'videojs-record/dist/videojs.record.js';
import 'videojs-record/dist/plugins/videojs.record.ts-ebml.js';

import "./common.scss";

// vuexy
import "./vuexy/scss/bootstrap.scss";
import "./vuexy/scss/bootstrap-extended.scss";
import "./vuexy/scss/colors.scss";
import "./vuexy/scss/components.scss";
import "./vuexy/vendors/css/vendors.min.css";

ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </Provider>,
  document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();