import {H2, H4} from "@blueprintjs/core";
import React from "react";

import "./CardHeading.scss";

export default function CardHeading({children}) {
  return <H2 className="CardHeading">
    { children }
  </H2>
}

export function CardSubheading({children}) {
  return <H4 className="CardSubheading">
    { children }
  </H4>
}