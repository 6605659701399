import EXERCISES_DATA from "../../data/common/exercises-data.js";
import simulateRequestWithResolve from "../simulateRequestWithResolve";

let NEXT_ID = Object.values(EXERCISES_DATA.EXERCISES).length;

const EXERCISES_MOCK_API = {
  async requestExerciseCategoriesList() {
    return new Promise(resolve => {
      setTimeout(() => {
        resolve(EXERCISES_DATA.CATEGORIES);
      }, Math.random() * 1000 + 500);
    });
  },

  async requestExercisesList() {
    return new Promise(resolve => {
      setTimeout(() => {
        resolve(Object.values(EXERCISES_DATA.EXERCISES));
      }, Math.random() * 1000 + 500);
    });
  },

  async updateExercise(id, data) {
    return new Promise(resolve => {
      EXERCISES_DATA.EXERCISES[id] = {
        ...EXERCISES_DATA.EXERCISES[id],
        ...data,
      };
      simulateRequestWithResolve(resolve, true);
    });
  },

  async createExercise(data) {
    return new Promise(resolve => {
      EXERCISES_DATA.EXERCISES[NEXT_ID] = {
        ...data,
        id: NEXT_ID,
        base: false,
        parameters: {
          level: 1,
          duration: 1,
          hand: []
        },
      };

      NEXT_ID++;
      simulateRequestWithResolve(resolve, true);
    });
  },
};

export default EXERCISES_MOCK_API;