import React, {useEffect, useState} from 'react';
import {DateRangePicker} from "@blueprintjs/datetime";
import {Button, Colors, H2, H3, H4, Icon, Intent} from "@blueprintjs/core";
import classNames from "classnames";
import {
  BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, AreaChart, Area,
  // ReferenceDot,
} from 'recharts';
import {defaultTo} from "lodash";
import MomentLocaleUtils from "react-day-picker/moment";
import 'moment/locale/pl';
import moment from "moment";

import createDatePickerPolishShortcuts from "lib/createDatePickerPolishShortcuts";

import ButterflyIcon from "images/game-icons/butterfly.svg";
import BoxingIcon from "images/game-icons/boxing.svg";
import RacingIcon from "images/game-icons/racing.svg";

import ResponsiveCard from "ui/ResponsiveCard";
import CardHeading from "ui/text/CardHeading";
import FullScreenOverlay from "ui/FullScreenOverlay";
import TopIconButton from "ui/buttons/TopIconButton";

import VideoIcon from "images/icons/video.svg";
import ReactPlayer from "react-player";
import BoxingFeedbackVideo from 'videos/boxing-feedback.webm';

import "./Reports.scss";
import cutTimeFromDateRange from "../../lib/cutTimeFromDateRange";
import {useDispatch, useSelector} from "react-redux";
import ReportActions from "../../redux/actions/common/report-actions";
import InlineableComponent from "../../ui/layout/InlineableComponent";
import Loader from "../../ui/Loader";
import groupByKey from "../../lib/groupByKey";

const DEFAULT_START_DATE = new Date();
DEFAULT_START_DATE.setMonth(DEFAULT_START_DATE.getMonth() - 1);

export default function Reports({inline=false}) {
  const dispatch = useDispatch();
  const report = useSelector(state => state.report.data);
  const isProcessing = useSelector(state => state.processing.isProcessing);

  const [processedReport, setProcessedReport] = useState({
    durations: [],
    exercises: {},
  });
  const [exerciseDays, setExerciseDays] = useState(0);
  const [totalDurationS, setTotalDurationS] = useState(0);
  const [averageDurationS, setAverageDurationS] = useState(0);

  const [isVideoShown, setIsVideoShown] = useState(false);
  const [datesRange, setDatesRange] = useState([DEFAULT_START_DATE, new Date()]);

  useEffect(() => {
    if (datesRange && datesRange[0] && datesRange[1]) {
      dispatch(ReportActions.requestReport(...datesRange));
    }
  }, [datesRange, dispatch]);

  useEffect(() => {
    if (report !== undefined && datesRange && datesRange[0] && datesRange[1]) {
      let newReport = {
        durations: [],
        exercises: {},
      };

      const currentLevel = {};

      if (report.durations.length > 0) {
        const durationsByDate = groupByKey(
          report.durations,
          "date",
          key => key.toLocaleDateString()
        );

        for (let [id, exerciseData] of Object.entries(report.exercises)) {
          let newExerciseData = {...exerciseData};
          newExerciseData.stats = exerciseData.stats.filter(
            exerciseInfo => (exerciseInfo["date"] >= datesRange[0]) && (exerciseInfo["date"] <= datesRange[1])
          );

          if (newExerciseData.stats.length > 0) {
            newReport.exercises[id] = newExerciseData;
            currentLevel[id] = 1;
            newExerciseData.statsByDate = groupByKey(
              newExerciseData.stats,
              "date",
              key => key.toLocaleDateString(),
            );
            newExerciseData.stats = [];
          }
        }

        const exerciseIds = Object.keys(newReport.exercises);

        let currentDate = new Date(datesRange[0]);
        let endDate = new Date(datesRange[1]);
        endDate.setDate(endDate.getDate() + 1);

        let currentDateLocale = currentDate.toLocaleDateString();
        const endDateLocale = endDate.toLocaleDateString();

        while (currentDateLocale !== endDateLocale) {
          let duration = 0;

          if (durationsByDate[currentDateLocale]) {
            duration = durationsByDate[currentDateLocale].duration;
          }

          for (let exerciseId of exerciseIds) {
            const currentDateStats = newReport.exercises[exerciseId].statsByDate[currentDateLocale];
            let video = false;

            if (currentDateStats) {
              currentLevel[exerciseId] = currentDateStats.level;
              video = defaultTo(currentDateStats.video, false);
            }

            newReport.exercises[exerciseId].stats.push({
              date: currentDateLocale,
              level: currentLevel[exerciseId],
              video,
            });
          }

          newReport.durations.push({
            date: currentDateLocale,
            duration: Math.round(duration / 60),
            durationS: duration,
          });

          currentDate.setDate(currentDate.getDate() + 1);
          currentDateLocale = currentDate.toLocaleDateString();
        }
      }

      setProcessedReport(newReport);
    }
  }, [datesRange, report]);

  useEffect(() => {
    if (processedReport !== undefined){
      setTotalDurationS(processedReport.durations.reduce(
        (accumulatedDuration, current) => accumulatedDuration + current.durationS,
        0
      ));

      const newExerciseDays = processedReport.durations.filter((info) => info.duration > 0).length;
      setExerciseDays(newExerciseDays);
    }
  }, [processedReport]);

  useEffect(() => {
    if (exerciseDays > 0) {
      setAverageDurationS(totalDurationS / exerciseDays);
    } else {
      setAverageDurationS(0);
    }
  }, [totalDurationS, exerciseDays]);

  function setDatesRangeWithoutTime(range) {
    setDatesRange(cutTimeFromDateRange(range));
  }

  function toggleIsVideoShown() {setIsVideoShown(!isVideoShown)}

  return <InlineableComponent className="Reports"
    inline={inline}
  >
    <Loader visible={processedReport === undefined}
      text="Wczytuję raport"
    >
      <CardHeading>
        Rezultaty ćwiczeń
      </CardHeading>
      <div className="date-chooser">
        <DateRangePicker
          locale="pl"
          localeUtils={MomentLocaleUtils}

          shortcuts={createDatePickerPolishShortcuts(
            true,
            false,
            false,
          )}

          value={datesRange}
          onChange={setDatesRangeWithoutTime}

          contiguousCalendarMonths={false}
        />
      </div>
      <div className={classNames("report-data", {"bp3-skeleton": isProcessing})}>
        <H2 className="report-header">
          Raport
          <span className="report-header-duration"> {datesRange[0] && `od ${datesRange[0].toLocaleDateString()}`} {datesRange[1] && `do ${datesRange[1].toLocaleDateString()}`}
        </span>
        </H2>
        <H3>Najważniejsze informacje</H3>
        <div className="important">
          <div className="important-info">
            <Icon icon="timeline-events" iconSize={32} /> Liczba dni ćwiczeń: <span className="important-value">
          {exerciseDays}
        </span>
          </div>
          <div className="important-info">
            <Icon icon="time" iconSize={32} /> Całkowity czas ćwiczeń: <span className="important-value">
          {moment.duration(totalDurationS, 'seconds').humanize()}
        </span>
          </div>
          <div className="important-info">
            <Icon icon="stopwatch" iconSize={32} /> Średni czas trwania ćwiczeń jednego dnia: <span className="important-value">
          {moment.duration(averageDurationS, 'seconds').humanize()}
        </span>
          </div>
        </div>
        <H3>Długość ćwiczeń w poszczególne dni</H3>
        <ResponsiveContainer className="chart" width="100%" height={500}>
          <BarChart
            data={processedReport.durations}
            margin={{
              top: 5, right: 30, left: 20, bottom: 5,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis
              dataKey="date"
            />
            <YAxis
              unit="m"
            />
            <Tooltip />
            <Legend />
            <Bar
              name="Czas trwania"
              unit="m"
              dataKey="duration"
              fill={Colors.BLUE3}
            />
          </BarChart>
        </ResponsiveContainer>
        <H3>Wyniki dla poszczególnych ćwiczeń</H3>
        <div className="exercise-shortcuts">
          <TopIconButton
            icon={ButterflyIcon}
            text="Motyl"
          />
          <TopIconButton
            icon={RacingIcon}
            text="Tor"
          />
          <TopIconButton
            icon={BoxingIcon}
            text="Boks"
          />
        </div>
        {
          Object.values(processedReport.exercises).map(exercise => (<div key={exercise.id}>
            <H3 className="exercise-title">{exercise.name}</H3>
            <H4>Osiągnięte poziomy</H4>
            <ResponsiveContainer className="chart" width="100%" height={500}>
              <AreaChart
                data={exercise.stats}
                margin={{
                  top: 10, right: 30, left: 0, bottom: 0,
                }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis
                  dataKey="date"
                />
                <YAxis
                  domain={[1, 30]}
                />
                <Tooltip
                  content={<LevelTooltip />}
                />
                <Area type="monotone" dataKey="level" stroke={Colors.BLUE2} fill={Colors.BLUE4} />
              </AreaChart>
            </ResponsiveContainer>
            <LevelLegend />
          </div>))
        }
      </div>

      {/*<H3 className="exercise-title">Motyl</H3>*/}
      {/*<H4>Osiągnięte poziomy</H4>*/}
      {/*<ResponsiveContainer className="chart" width="100%" height={500}>*/}
      {/*  <AreaChart*/}
      {/*    data={DATA_BUTTERFLY}*/}
      {/*    margin={{*/}
      {/*      top: 10, right: 30, left: 0, bottom: 0,*/}
      {/*    }}*/}
      {/*  >*/}
      {/*    <CartesianGrid strokeDasharray="3 3" />*/}
      {/*    <XAxis*/}
      {/*      dataKey="date"*/}
      {/*    />*/}
      {/*    <YAxis*/}
      {/*      domain={[1, 30]}*/}
      {/*    />*/}
      {/*    <Tooltip*/}
      {/*      content={<LevelTooltip />}*/}
      {/*    />*/}
      {/*    <Area type="monotone" dataKey="level" stroke={Colors.BLUE2} fill={Colors.BLUE4} />*/}
      {/*  </AreaChart>*/}
      {/*</ResponsiveContainer>*/}
      {/*<H3 className="exercise-title">Tor</H3>*/}
      {/*<H4>Osiągnięte poziomy</H4>*/}
      {/*<ResponsiveContainer className="chart" width="100%" height={500}>*/}
      {/*  <AreaChart*/}
      {/*    data={DATA_RACING}*/}
      {/*    margin={{*/}
      {/*      top: 10, right: 30, left: 0, bottom: 0,*/}
      {/*    }}*/}
      {/*  >*/}
      {/*    <CartesianGrid strokeDasharray="3 3" />*/}
      {/*    <XAxis*/}
      {/*      dataKey="date"*/}
      {/*    />*/}
      {/*    <YAxis*/}
      {/*      domain={[1, 30]}*/}
      {/*    />*/}
      {/*    <Tooltip*/}
      {/*      content={<LevelTooltip />}*/}
      {/*    />*/}
      {/*    <Area type="monotone" dataKey="level" stroke={Colors.BLUE2} fill={Colors.BLUE4} />*/}
      {/*  </AreaChart>*/}
      {/*</ResponsiveContainer>*/}
      {/*<H3 className="exercise-title">Boks</H3>*/}
      {/*<H4>Osiągnięte poziomy</H4>*/}
      {/*<ResponsiveContainer className="chart has-legend" width="100%" height={500}>*/}
      {/*  <AreaChart*/}
      {/*    data={DATA_BOX}*/}
      {/*    margin={{*/}
      {/*      top: 10, right: 30, left: 0, bottom: 0,*/}
      {/*    }}*/}

      {/*    onClick={(payload) => {*/}
      {/*      const isVideoAvailable = payload.activePayload[0].payload.video;*/}
      {/*      if (isVideoAvailable) {*/}
      {/*        setIsVideoShown(true);*/}
      {/*      }*/}
      {/*    }}*/}
      {/*  >*/}
      {/*    <CartesianGrid strokeDasharray="3 3" />*/}
      {/*    <XAxis*/}
      {/*      id="x-axis"*/}
      {/*      dataKey="date"*/}
      {/*    />*/}
      {/*    <YAxis*/}
      {/*      id="y-axis"*/}
      {/*      domain={[1, 30]}*/}
      {/*    />*/}
      {/*    <Tooltip*/}
      {/*      content={<LevelTooltip />}*/}
      {/*    />*/}
      {/*    <Area*/}
      {/*      type="monotone" dataKey="level" stroke={Colors.BLUE2} fill={Colors.BLUE4}*/}
      {/*    />*/}
      {/*    <ReferenceDot*/}
      {/*      x={DATA_BOX[5].date}*/}
      {/*      y={DATA_BOX[5].level}*/}
      {/*      r={4}*/}
      {/*      stroke={Colors.GREEN1}*/}
      {/*      fill={Colors.GREEN3}*/}
      {/*      isFront={true}*/}
      {/*    />*/}
      {/*  </AreaChart>*/}
      {/*</ResponsiveContainer>*/}
      {/*<LevelLegend />*/}
      <FullScreenOverlay
        isOpen={isVideoShown}
        onClose={toggleIsVideoShown}
      >
        <ReportVideo
          toggleVideo={toggleIsVideoShown}
        />
      </FullScreenOverlay>
    </Loader>
  </InlineableComponent>
}

const LevelTooltip = ({ active, payload, label }) => {
  if (active) {
    return (
      <div className="LevelTooltip">
        <p className="label">{`${label}: Maksymalny poziom ${payload[0].value}`}</p>
        {payload[0].payload.video && <>
          <p className="video-available"><Icon icon="video" />Dostępne wideo <span className="click">Kliknij na wykres, aby obejrzeć</span></p>
        </>}
      </div>
    );
  }

  return null;
};

function LevelLegend() {
  return <div className="LevelLegend">
    <span className="video-symbol" /> Dzień zawiera zapis wideo ćwiczeń - kliknij na wykres, aby go obejrzeć
  </div>
}

function ReportVideo({toggleVideo}) {
  return <ResponsiveCard className="ReportVideo"
    size={ResponsiveCard.SIZES.sm}
  >
    <CardHeading>
      Wideo z ćwiczenia
    </CardHeading>
    <ReactPlayer
      className="video"

      url={BoxingFeedbackVideo}

      controls={true}
      light={VideoIcon}
      playing={true}


      width="100%"
      height="100%"
    />
    <div className="close-container">
    <Button
      large={true}
      icon="cross"
      text="Zamknij"
      intent={Intent.DANGER}

      onClick={toggleVideo}
    />
    </div>
  </ResponsiveCard>
}