import React from "react";
import {Button} from "react-bootstrap";

import "./ExerciseInfo.scss"
import ExerciseTitle from "../subcomponents/ExerciseTitle";
import ExerciseFavouriteButton from "../subcomponents/ExerciseFavouriteButton";

export default function ExerciseInfo({
  exercise, startExercise,
  isFavourite, toggleIsFavourite,
}) {
  const {data} = exercise;
  const {icon, name, categories, short_description, long_description} = data;

  return <div className="ExerciseInfo">
    <section className="exercise-info-header">
      <ExerciseTitle
        name={name}
        icon={icon}
        categories={categories}
      />
      <ExerciseFavouriteButton className="p-0" includeDescription={true}
        isFavourite={isFavourite} toggleIsFavourite={toggleIsFavourite}
      />
    </section>
    <section className="start-exercise">
      <Button variant="primary btn-block"
        onClick={startExercise}
      >
        Rozpocznij
      </Button>
    </section>
    <section className="exercise-info-description">
      <p><strong>{short_description}</strong></p>
      <p>{long_description}</p>
    </section>
  </div>;
}