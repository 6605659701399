import {combineReducers} from "redux";

import authReducer from "./common/auth-reducer";
import exercises from "./common/exercises-reducer";
import profileReducer from "./common/profile-reducer";
import processingReducer from "./common/processing-reducer";
import sessionsReducer from "./common/sessions-reducer";
import messagesReducer from "./common/messages-reducer";
import reportReducer from "./common/report-reducer";
import servicesReducer from "./common/services-reducer";

import remindersReducer from "./patient/reminders-reducer";

import earningsReducer from "./therapist/earnings-reducer";
import patients from "./therapist/patients-reducer";
import tasksReducer from "./therapist/tasks-reducer";

export default combineReducers({
  auth: authReducer,
  exercises,
  sessions: sessionsReducer,
  profile: profileReducer,
  processing: processingReducer,
  messages: messagesReducer,
  report: reportReducer,
  services: servicesReducer,
  patient: combineReducers({
    reminders: remindersReducer,
  }),
  therapist: combineReducers({
    earnings: earningsReducer,
    patients,
    tasks: tasksReducer,
  })
});
