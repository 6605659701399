import MessagesActions from "redux/actions/common/messages-actions";
import groupByKey from "lib/groupByKey";

const initialState = {
  current: {
    id: undefined,
    data: {},
  },

  list: undefined,
  byId: undefined,
};

const TYPES = MessagesActions.TYPES;

export default function messagesReducer(state = initialState, action = {}) {
  const {type, payload} = action;

  switch(type) {
    case TYPES.MESSAGES_CHANGED: {
      const {messages} = payload;
      const byId = groupByKey(messages, "id");

      return {
        ...state,

        list: messages,
        byId,
      }
    }

    case TYPES.SET_CURRENT_MESSAGE_ID: {
      const {id} = payload;
      const message = state.byId[id];

      if (!message) {
        return {
          ...state,
        }
      } else {
        return {
          ...state,
          current: {
            ...state.current,
            id,
            data: message,
          }
        }
      }
    }

    default:
      return state
  }
}