import REMINDERS_MOCK_API from "test/mock-api/patient/reminders-mock-api";
import {put, all, takeEvery, select} from "redux-saga/effects";
import RemindersActions from "../../actions/patient/reminders-actions";
import ProcessingActions from "../../actions/common/processing-actions";

const PROCESSING_TYPES = {
  REQUESTING_REMINDERS: "REQUESTING_REMINDERS",
  MODIFYING_REMINDERS: "MODIFYING_REMINDERS",
};

class RemindersSagas {
  static* requestReminders() {
    yield put(ProcessingActions.startProcessing(PROCESSING_TYPES.REQUESTING_REMINDERS));
    const reminders = yield REMINDERS_MOCK_API.requestReminders();
    yield put(RemindersActions.remindersChanged(reminders));
    yield put(ProcessingActions.stopProcessing(PROCESSING_TYPES.REQUESTING_REMINDERS));
  }

  static* watchRequestReminders() {
    yield takeEvery(RemindersActions.TYPES.REQUEST_REMINDERS, RemindersSagas.requestReminders);
  }

  static* saveCurrentReminder() {
    yield put(ProcessingActions.startProcessing(PROCESSING_TYPES.MODIFYING_REMINDERS));
    const current = yield select(state => state.patient.reminders.current);
    if (current.id === undefined) {
      yield RemindersSagas.addReminder({
        ...current.data,
      })
    } else {
      yield RemindersSagas.editReminder({
        ...current.data,
      })
    }
    yield put(ProcessingActions.stopProcessing(PROCESSING_TYPES.MODIFYING_REMINDERS));
  }

  static* addReminder(data) {
    const success = yield REMINDERS_MOCK_API.addReminder(data);
    if (success) {
      yield RemindersSagas.requestReminders();
    }
  }

  static* editReminder(data) {
    const success = yield REMINDERS_MOCK_API.editReminder(data);
    if (success) {
      yield RemindersSagas.requestReminders();
    }
  }

  static* watchSaveCurrentReminder() {
    yield takeEvery(RemindersActions.TYPES.SAVE_CURRENT_REMINDER, RemindersSagas.saveCurrentReminder);
  }

  static* deleteReminder(action) {
    const {id} = action.payload;

    const success = yield REMINDERS_MOCK_API.deleteReminder(id);
    if (success) {
      yield RemindersSagas.requestReminders();
    }
  }

  static* watchDeleteReminder() {
    yield takeEvery(RemindersActions.TYPES.DELETE_REMINDER, RemindersSagas.deleteReminder);
  }
}

export default function* remindersSaga() {
  yield all([
    RemindersSagas.watchRequestReminders(),
    RemindersSagas.watchSaveCurrentReminder(),
    RemindersSagas.watchDeleteReminder(),
  ])
}