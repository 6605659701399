import React from "react";
import classNames from 'classnames';


import "./Loader.scss";

export default function Loader({visible=true, text, children, className}) {
  return <div className={classNames("Loader", className)}>
    {visible && <div className="loading-state">
      <div className="spinner-border" style={{width: "3rem", height: "3rem"}} />
      <h4 className="ml-1 mb-0">{text}</h4>
    </div>}
    {!visible && children}
  </div>
}