import {EARNINGS_TYPES} from "redux/reducers/therapist/earnings-reducer";

const CURRENT_YEAR = new Date().getFullYear();
const CURRENT_MONTH = new Date().getMonth();

const EARNINGS_DATA = {
  EARNINGS: {
    1: {
      id: 1,
      type: EARNINGS_TYPES.NEW_SESSION,
      amount: 80,
      date: new Date(CURRENT_YEAR, CURRENT_MONTH - 1, 18),
      paid: false,
    },
    2: {
      id: 2,
      type: EARNINGS_TYPES.REPORT,
      amount: 120,
      date: new Date(CURRENT_YEAR, CURRENT_MONTH - 1, 21),
      paid: false,
    },
    3: {
      id: 3,
      type: EARNINGS_TYPES.NEW_SESSION,
      amount: 80,
      date: new Date(CURRENT_YEAR, CURRENT_MONTH - 1, 25),
      paid: false,
    },
    4: {
      id: 4,
      type: EARNINGS_TYPES.NEW_SESSION,
      amount: 80,
      date: new Date(CURRENT_YEAR, CURRENT_MONTH - 2, 26),
      paid: new Date(CURRENT_YEAR, CURRENT_MONTH - 1, 1),
    },
    5: {
      id: 5,
      type: EARNINGS_TYPES.NEW_SESSION,
      amount: 80,
      date: new Date(CURRENT_YEAR, CURRENT_MONTH - 2, 28),
      paid: new Date(CURRENT_YEAR, CURRENT_MONTH - 1, 1),
    }
  },
  NEXT_PAY_DATE: new Date(CURRENT_YEAR, CURRENT_MONTH + 1, 1),
};

export default EARNINGS_DATA;