import {useCallback, useState} from 'react';

export default function useErrors(defaultValue, fields, othersField="other") {
  const getProcessedErrors = useCallback(function(newErrors) {
    let processedErrors = {};

    for (let [key, value] of Object.entries(newErrors)) {
      let errorKey = key;
      if (!fields.includes(errorKey)) {
        errorKey = othersField;
      }

      if (!(errorKey in processedErrors)) {
        processedErrors[errorKey] = [];
      }

      if (Array.isArray(value)) {
        processedErrors[errorKey].push(...value);
      } else {
        processedErrors[errorKey].push(value);
      }

    }

    return processedErrors;
  }, [fields, othersField]);

  const setProcessedErrors = useCallback(function(newErrors) {
    setErrors(getProcessedErrors(newErrors));
  }, [getProcessedErrors]);

  const [errors, setErrors] = useState(getProcessedErrors(defaultValue));

  return [errors, setProcessedErrors];
}