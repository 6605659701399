export default function cutTimeFromDateRange([startDate, endDate]) {
  let newStartDate = startDate ? new Date(startDate) : null;
  let newEndDate = endDate ? new Date(endDate) : null;

  if (newStartDate !== null) {
    newStartDate.setHours(0);
    newStartDate.setMinutes(0);
    newStartDate.setSeconds(0);
  }

  if (newEndDate !== null) {
    newEndDate.setHours(23);
    newEndDate.setMinutes(59);
    newEndDate.setSeconds(59);
  }

  return [newStartDate, newEndDate];
}