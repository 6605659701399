import ButterflyIcon from "images/game-icons/butterfly.svg";
import RacingIcon from "images/game-icons/racing.svg";
import BoxingIcon from "images/game-icons/boxing.svg";

import loremGenerator from "test/loremGenerator";

let SESSIONS_DATA = {
  SESSIONS: {
    1: {
      id: 1,
      name: "Wzmacniająca ręce",
      description: loremGenerator.generateParagraphs(3),
      editable: true,
      exercises: [
        {
          id: 1,
          icon: BoxingIcon,
          name: "Boks",
          hand: ["left"],
          duration: 4,
        },
        {
          id: 2,
          icon: ButterflyIcon,
          name: "Motyl",
          hand: ["left", "right"],
          duration: 3,
        },
      ]
    },
    2: {
      id: 2,
      name: "Wzmacniająca nogi\n",
      description: loremGenerator.generateParagraphs(3),
      editable: true,
      exercises: [
        {
          id: 1,
          icon: BoxingIcon,
          name: "Boks",
          hand: ["left"],
          duration: 4,
        },
        {
          id: 2,
          icon: RacingIcon,
          name: "Tor",
          hand: ["left"],
          duration: 3,
        },
        {
          id: 3,
          icon: ButterflyIcon,
          name: "Motyl",
          hand: ["right"],
          duration: 3,
        },
      ]
    },
    3: {
      id: 3,
      name: "Ogólnorozwojowa",
      description: loremGenerator.generateParagraphs(3),
      editable: false,
      exercises: [
        {
          id: 1,
          icon: RacingIcon,
          name: "Tor",
          hand: ["right"],
          duration: 4,
        },
        {
          id: 2,
          icon: ButterflyIcon,
          name: "Motyl",
          hand: ["right"],
          duration: 3,
        },
      ]
    },
  }
};

export default SESSIONS_DATA;