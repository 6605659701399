import React, {useEffect} from 'react';
import {Callout, H3, HTMLTable, Icon, Intent} from "@blueprintjs/core";

import "./AccountServices.scss";
import InlineableComponent from "../../ui/layout/InlineableComponent";
import {useDispatch, useSelector} from "react-redux";
import ServicesActions from "../../redux/actions/common/services-actions";
import Loader from "../../ui/Loader";
import {PAYMENT_TYPES, SERVICE_TYPES} from "../../redux/reducers/common/services-reducer";

const SERVICE_NAMES = {
  [SERVICE_TYPES.ACCESS]: "Dostęp do Neuroformy",
  [SERVICE_TYPES.TELENF]: "Teleopieka",
};

const PAYMENT_NAMES = {
  [PAYMENT_TYPES.CREDIT_CARD]: "Karta kredytowa",
};

export default function AccountServices({
  showNextPayment=true,
  inline=false,
}) {
  const dispatch = useDispatch();
  const active = useSelector(state => state.services.active);
  const inactive = useSelector(state => state.services.inactive);
  const payments = useSelector(state => state.services.payments);

  useEffect(() => {
    if (active === undefined || inactive === undefined) {
      dispatch(ServicesActions.requestServices());
      dispatch(ServicesActions.requestPayments());
    }
  }, [active, inactive, dispatch]);

  if (active === undefined && inactive === undefined) {
    return <InlineableComponent className="AccountServices"
      inline={inline}
    >
      <Loader
        text="Wczytuję usługi"
      />
    </InlineableComponent>
  }

  let nextPayment;

  if (payments !== undefined) {
    if (payments.length === 0) {
      nextPayment = false;
    } else {
      nextPayment = payments[0];
    }
  }

  return <InlineableComponent className="AccountServices"
    inline={inline}
  >
    {showNextPayment && nextPayment && <>
      <section>
        <Callout className="next-payment"
          title="Następna płatność"
        >
          <p>
            <Icon icon="calendar" /> Data: <strong>{nextPayment["date"].toLocaleDateString()}</strong> za <strong>{SERVICE_NAMES[nextPayment["type"]]}</strong>
          </p>
          <p>
            <Icon icon="bank-account" /> Kwota: <strong>{nextPayment["price"]}zł</strong>
          </p>
          <p>
            <Icon icon="credit-card" /> Sposób: <strong>{PAYMENT_NAMES[nextPayment["payment_type"]]}</strong>
          </p>
        </Callout>
      </section>
    </>
    }
    <section>
      <H3>Aktualne usługi</H3>
      <HTMLTable
        striped={true}
      >
        <thead>
        <tr>
          <th>Nazwa</th>
          <th>Cena</th>
          <th>Aktywna od</th>
          <th>Aktywna do</th>
          <th>Auto-przedłużenie?</th>
        </tr>
        </thead>
        <tbody>
        {active.map(({autoextend, type, price, from, to}, index) => (
          <tr key={index}>
            <td>{SERVICE_NAMES[type]}</td>
            <td className="price">{price} zł</td>
            <td className="date">{from.toLocaleDateString()}</td>
            <td className="date">{to.toLocaleDateString()}</td>
            <td className="is-active">
              <Icon
                icon={autoextend ? "tick" : "cross"}
                intent={autoextend ? Intent.SUCCESS : Intent.DANGER}
              />
            </td>
          </tr>
        ))}
        </tbody>
      </HTMLTable>
    </section>
    <section>
      <H3>Lista poprzednich usług i płatności</H3>
      <HTMLTable
        striped={true}
      >
        <thead>
          <tr>
            <th>Nazwa</th>
            <th>Cena</th>
            <th>Aktywna od</th>
            <th>Aktywna do</th>
          </tr>
        </thead>
        <tbody>
        {inactive.map(({type, price, from, to}, index) => (
          <tr key={index}>
            <td>{SERVICE_NAMES[type]}</td>
            <td className="price">{price} zł</td>
            <td className="date">{from.toLocaleDateString()}</td>
            <td className="date">{to.toLocaleDateString()}</td>
          </tr>
        ))}
        </tbody>
      </HTMLTable>
    </section>
  </InlineableComponent>}