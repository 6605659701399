import React from "react";
import clsx from "clsx";

export default function VContent({children, className, ...other}) {
  return <div className={clsx("app-content content", className)} {...other}>
    <div className="content-overlay" />
    <div className="header-navbar-shadow" />
    <div className="content-wrapper">
      {children}
    </div>
  </div>
}

VContent.Header = ({children}) => {
  return <div className="content-header row">
    {children}
  </div>
};

VContent.Content = ({children}) => {
  return <div className="content-body">
    {children}
  </div>
};