import CardHeading from "../../../ui/text/CardHeading";
import {HTMLTable} from "@blueprintjs/core";
import React from "react";

import {MessageRow} from ".";

export default function MessagesList({
  messages,

  openMessageThread$,
  deleteMessageThread$,
}) {
  return <div className="MessagesList">
    <CardHeading>
      Wiadomości
    </CardHeading>
    <HTMLTable
      interactive={true}
      striped={true}
    >
      <thead>
      <tr>
        <th/>
        <th/>
        <th>Nadawca</th>
        <th>Temat</th>
        <th>Data</th>
        <th>Akcje</th>
      </tr>
      </thead>
      <tbody>
      {messages.map((messageInfo, index) => {
        return <MessageRow key={index}
          index={index}

          openMessageThread$={openMessageThread$}
          deleteMessageThread$={deleteMessageThread$}

          {...messageInfo}
        />
      })}
      </tbody>
    </HTMLTable>
  </div>
}
