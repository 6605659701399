import SERVICES_DATA from "test/data/common/services-data";
import simulateRequestWithResolve from "test/mock-api/simulateRequestWithResolve";

const SERVICES_MOCK_API = {
  async requestServices() {
    return new Promise((resolve) => {
      simulateRequestWithResolve(
        resolve,
        Object.values(SERVICES_DATA.SERVICES),
      );
    });
  },

  async requestPayments() {
    return new Promise((resolve) => {
      simulateRequestWithResolve(
        resolve,
        Object.values(SERVICES_DATA.PAYMENTS),
      );
    });
  },
};

export default SERVICES_MOCK_API;