import authSaga from "./common/auth-sagas";
import exercisesSaga from "./common/exercises-sagas";
import sessionsSaga from "./common/sessions-sagas";
import profileSagas from "./common/profile-sagas";
import messagesSagas from "./common/messages-sagas";
import reportSaga from "./common/report-sagas";
import servicesSagas from "./common/services-sagas";
import remindersSaga from "./patient/reminders-sagas";
import earningsSaga from "./therapist/earnings-sagas";
import patientsSaga from "./therapist/patient-sagas";
import tasksSagas from "./therapist/tasks-sagas";

import {all} from "redux-saga/effects";

export default function* rootSaga() {
  yield all([
    authSaga(),
    exercisesSaga(),
    sessionsSaga(),
    profileSagas(),
    messagesSagas(),
    reportSaga(),
    servicesSagas(),

    remindersSaga(),

    earningsSaga(),
    patientsSaga(),
    tasksSagas(),
  ])
}