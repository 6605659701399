import serverRequest from "lib/serverRequest";

const EXERCISES_API = {
  async requestExerciseCategoriesList() {
    return new Promise(resolve => {
      serverRequest(
        "api/categories",
        "GET",
        {},
        (result) => {
          resolve(result);
        },
        (result) => {
          resolve(false);
        }
      );
    });
  },

  async requestExercisesList() {
    return new Promise(resolve => {
      serverRequest(
        "api/exercises",
        "GET",
        {},
        (result) => {
          resolve(result);
        },
        (result) => {
          resolve(false);
        }
      );
    });
  },

  async updateExerciseSettings(patientId, exerciseId, data) {
    return new Promise(resolve => {
      serverRequest(
        `api/patients/${patientId}`,
        "PATCH",
        {
          "extra_data": {
            "exercise": {
              [exerciseId]: data.patient_settings
            }
          },
        },
        result => {
          resolve(result);
        },
        () => {
          resolve(false);
        }
      );
    });
  },

  async updateExercise(id, data) {
    return new Promise(resolve => {
      serverRequest(
        `api/exercises/${id}`,
        "PUT",
        data,
        result => {
          resolve(result);
        },
        () => {
          resolve(false);
        }
      );
    });
  },

  // async createExercise(data) {
  //   return new Promise(resolve => {
  //     EXERCISES_DATA.EXERCISES[NEXT_ID] = {
  //       ...data,
  //       id: NEXT_ID,
  //       base: false,
  //       parameters: {
  //         level: 1,
  //         duration: 1,
  //         hand: []
  //       },
  //     };
  //
  //     NEXT_ID++;
  //     simulateRequestWithResolve(resolve, true);
  //   });
  // },
};

export default EXERCISES_API;