import React, {useEffect, useState} from "react";
import {MultiSelect, Select} from "@blueprintjs/select";
import {
  Button,
  ButtonGroup,
  FormGroup,

  InputGroup,
  Intent, MenuItem,
  NonIdealState,
  Spinner,
  TextArea
} from "@blueprintjs/core";

import FullScreenOverlay from "ui/FullScreenOverlay";
import ResponsiveCard from "ui/ResponsiveCard";
import {useToggleWithSet} from "lib/useToggle";

import ExerciseParameters from "./subviews/ExerciseParameters";
import SendVideoPopup from "./subviews/SendVideoPopup";
import ExerciseFinished from "./subviews/ExerciseFinished";

import "./ExerciseList.scss";
import CardHeading from "../../ui/text/CardHeading";
import useToggle from "../../lib/useToggle";
import Loader from "../../ui/Loader";
import {TimePicker, TimePrecision} from "@blueprintjs/datetime";
import {useDispatch, useSelector} from "react-redux";
import ExercisesActions from "../../redux/actions/common/exercises-actions";
import MultimediaRecorder from "../../ui/components/MultimediaRecorder";
import {defaultTo, findIndex, pullAll} from "lodash";
import ExerciseSummary from "./subviews/ExerciseSummary";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import Exercise from "./subviews/Exercise";
import {Col, Row} from "react-bootstrap";
import ExerciseInfo from "./subviews/ExerciseInfo";
import createPersistedState from 'use-persisted-state';

const usePersistedFavourites = createPersistedState('favourites ');

const SORT_TYPES = {
  NAME: "name",
  ACTIVITY_DATE: "activity_date",
};

const SORT_FNS = {
  [SORT_TYPES.NAME]: (a, b) => (a.name > b.name ? 1 : -1),
  [SORT_TYPES.ACTIVITY_DATE]: (a, b) => (a.activity_date > b.activity_date ? 1 : -1)
};

// const SORT_NAMES = {
//   [SORT_TYPES.NAME]: "nazwie",
//   [SORT_TYPES.ACTIVITY_DATE]: "dacie ostatniej aktywności"
// };

export default function ExerciseList({forTherapist, mainAction, canSetParameters=true}) {
  const dispatch = useDispatch();
  const exercises = useSelector(state => state.exercises.exerciseList);
  const categories = useSelector(state => state.exercises.categories);
  const currentExercise = useSelector(state => state.exercises.current);
  const currentExerciseId = useSelector(state => state.exercises.current.id);
  const summary = useSelector(state => state.exercises.summary);

  const [showingParameters, setShowingParameters, toggleExerciseParameters] = useToggleWithSet(false);
  const [sendVideo, setSendVideo] = useState(false);
  const [filteredExercises, setFilteredExercises] = useState([]);
  const [filteredCategory, setFilteredCategory] = useState(undefined);
  const [searchString, setSearchString] = useState("");
  const [sortedBy, setSortedBy] = useState(undefined);
  const [isExerciseFinished, setIsExerciseFinished, toggleIsExerciseFinished] = useToggleWithSet(false);
  const [isExerciseStarting, setIsExerciseStarting, toggleIsExerciseStarting] = useToggleWithSet(false);
  const [addingExercise, toggleAddingExercise] = useToggle(false);
  const [isSummaryShown, setIsSummaryShown, toggleIsSummaryShown] = useToggleWithSet(false);

  const [isExerciseInfoOpen, setIsExerciseInfoOpen, toggleIsExerciseInfoOpen] = useToggleWithSet(false);

  const [favourites, setFavourites] = usePersistedFavourites([]);
  const [favouriteExercises, setFavouriteExercises] = useState([]);

  useEffect(() => {
    setIsSummaryShown(summary.available);
  }, [setIsSummaryShown, summary]);

  useEffect(() => {
    dispatch(ExercisesActions.requestExercises());
    dispatch(ExercisesActions.updateExerciseCategories());
    // FIXME: Lines below just so there won't be warning
    setSearchString("");
    setSortedBy(undefined);
  }, [dispatch]);

  const setCurrentExerciseId = (exerciseId) => {
    dispatch(ExercisesActions.setCurrentId(exerciseId));
  };

  const beginExercise = () => {
    setIsExerciseInfoOpen(false);
    setShowingParameters(true);
  };

  const toggleSendVideoOverlay = () => {
    setSendVideo(!sendVideo);
  };

  const setCategory$ = (category$) => () => {
    setFilteredCategory(category$);
  };

  function startExercise() {
    setShowingParameters(false);
    setSendVideo(false);
    dispatch(ExercisesActions.startExercise());
  }

  function finishExercise() {
    setIsExerciseStarting(false);
    setIsExerciseFinished(true);
  }

  useEffect(processFilters, [filteredCategory, searchString, sortedBy, exercises]);

  // const setSortedBy$ = (sortBy$) => () => setSortedBy(sortBy$);

  let exerciseActions = [
    {
      name: "Informacje",
      primary: false,
      action: toggleIsExerciseInfoOpen,
    },
    {
      name: "Uruchom",
      primary: true,
      action: beginExercise,
    },
  ];

  if (mainAction) {
    exerciseActions[0] = mainAction;
  }

  if (!canSetParameters) {
    exerciseActions.splice(1, 1);
  }

  function processFilters() {
    if (exercises !== undefined) {
      let newFilteredExercises = [...exercises];

      if (filteredCategory) {
        newFilteredExercises = newFilteredExercises.filter((exercise) => {
          // eslint-disable-next-line eqeqeq
          return exercise.categories.filter(category => category.id == filteredCategory).length > 0
        });
      }

      if (searchString.length > 1) {
        const lowerCaseSearchString = searchString.toLowerCase();
        newFilteredExercises = newFilteredExercises.filter((exercise) => {
          return exercise.name.toLowerCase().includes(lowerCaseSearchString);
        });
      }

      if (sortedBy) {
        newFilteredExercises.sort(SORT_FNS[sortedBy]);
      }

      setFilteredExercises(newFilteredExercises);
    }
  }

  const isExerciseFavourite = (id) =>
    favourites.includes(id);

  const toggleIsExerciseFavourite$ = (id$) => () => {
    let newFavourites = [...favourites];

    if (newFavourites.includes(id$)) {
      pullAll(newFavourites, [id$]);
    } else {
      newFavourites.push(id$);
    }

    setFavourites(newFavourites);
  }

  useEffect(() => {
    let newFavouriteExercises = [];

    for (let exercise of filteredExercises) {
      if (favourites.includes(exercise.id)) {
        newFavouriteExercises.push(exercise);
      }
    }

    setFavouriteExercises(newFavouriteExercises);
  }, [favourites, filteredExercises]);

  if (exercises === undefined || categories === undefined) {
    return <Loader
      text="Wczytuję ćwiczenia"
    />
  }

  return <div className="ExerciseList">
    <h3 className="mb-2">Ćwiczenia</h3>
    <Tabs id="exercise-tabs"
      defaultActiveKey="exercises"
    >
      <Tab eventKey="exercises" title="Ćwiczenia">
        <Row>
        { filteredExercises && filteredExercises.map((exercise, index) => (
          <Col key={index} lg={4}>
            <Exercise
              {...exercise}
              parameters={exercise["patient_settings"]}
              categoriesInfo={categories}
              actions={exerciseActions}

              setCurrentExerciseId={setCurrentExerciseId}

              selectedCategory={filteredCategory}
              setCategory$={setCategory$}

              isFavourite={isExerciseFavourite(exercise.id)}
              toggleIsFavourite={toggleIsExerciseFavourite$(exercise.id)}
            />
          </Col>
        ))}
        </Row>
      </Tab>
      <Tab eventKey="favourites" title={<div>Ulubione</div>}>
        <Row>
          { favouriteExercises && favouriteExercises.map((exercise, index) => (
            <Col key={index} lg={4}>
              <Exercise
                {...exercise}
                parameters={exercise["patient_settings"]}
                categoriesInfo={categories}
                actions={exerciseActions}

                setCurrentExerciseId={setCurrentExerciseId}

                selectedCategory={filteredCategory}
                setCategory$={setCategory$}

                isFavourite={isExerciseFavourite(exercise.id)}
                toggleIsFavourite={toggleIsExerciseFavourite$(exercise.id)}
              />
            </Col>
          ))}
        </Row>
      </Tab>
    </Tabs>
    {/*{forTherapist && <>*/}
    {/*  <div className="actions">*/}
    {/*    <ButtonGroup*/}
    {/*      large={true}*/}
    {/*    >*/}
    {/*      <Button*/}
    {/*        intent={Intent.PRIMARY}*/}

    {/*        icon="add"*/}
    {/*        text="Utwórz ćwiczenie"*/}

    {/*        onClick={toggleAddingExercise}*/}
    {/*      />*/}
    {/*    </ButtonGroup>*/}
    {/*  </div>*/}
    {/*</>}*/}
    {/*<div className="categories">*/}
    {/*  <FormGroup*/}
    {/*    label="Filtruj po kategorii"*/}
    {/*  >*/}
    {/*    <ExerciseCategories*/}
    {/*      categories={categories}*/}
    {/*      setCategory$={setCategory$}*/}
    {/*    />*/}
    {/*  </FormGroup>*/}
    {/*</div>*/}
    {/*<div className="sort-search">*/}
    {/*  <Popover>*/}
    {/*    <Button*/}
    {/*      minimal={true}*/}
    {/*      text={`Sortuj po ${sortedBy ? SORT_NAMES[sortedBy] : "..."}`}*/}
    {/*      rightIcon="chevron-down"*/}
    {/*    />*/}
    {/*    <Filters*/}
    {/*      currentSort={sortedBy}*/}
    {/*      setSortType$={setSortedBy$}*/}
    {/*    />*/}
    {/*  </Popover>*/}
    {/*  <FormGroup*/}
    {/*    inline={true}*/}
    {/*    label="Szukaj po nazwie"*/}
    {/*    labelFor="search"*/}
    {/*  >*/}
    {/*    <InputGroup id="search"*/}
    {/*      leftIcon="search"*/}
    {/*      placeholder="Wyszukaj po nazwie"*/}
    {/*      rightElement={<Button*/}
    {/*        minimal={true}*/}
    {/*        icon="cross"*/}

    {/*        onClick={e => setSearchString("")}*/}
    {/*      />}*/}

    {/*      value={searchString}*/}
    {/*      onChange={e => setSearchString(e.target.value)}*/}
    {/*    />*/}
    {/*  </FormGroup>*/}
    {/*</div>*/}
    <FullScreenOverlay isOpen={isExerciseInfoOpen} onClose={toggleIsExerciseInfoOpen}>
      <ExerciseInfo
        exercise={currentExercise}
        startExercise={beginExercise}

        isFavourite={isExerciseFavourite(currentExerciseId)}
        toggleIsFavourite={toggleIsExerciseFavourite$(currentExerciseId)}
      />
    </FullScreenOverlay>
    <FullScreenOverlay isOpen={showingParameters} onClose={toggleExerciseParameters}>
      <ExerciseParameters
        exercise={currentExercise}
        currentExerciseId={currentExerciseId}

        startExercise={startExercise}
        cancel={toggleExerciseParameters}
      />
    </FullScreenOverlay>
    <FullScreenOverlay isOpen={sendVideo} onClose={toggleSendVideoOverlay}>
      <SendVideoPopup
        acceptRecording={startExercise}
        rejectRecording={startExercise}
      />
    </FullScreenOverlay>
    <FullScreenOverlay
      isOpen={isExerciseStarting} onClose={toggleIsExerciseStarting}
      canBeClosed={false}
    >
      <ResponsiveCard
        size={ResponsiveCard.SIZES.sm}
      >
        <ExerciseLoading
          onLoaded={finishExercise}
          close={toggleIsExerciseStarting}
        />
      </ResponsiveCard>
    </FullScreenOverlay>
    <FullScreenOverlay isOpen={isExerciseFinished} onClose={toggleIsExerciseFinished}>
      <ResponsiveCard
        size={ResponsiveCard.SIZES.sm}
      >
        <ExerciseFinished
          close={toggleIsExerciseFinished}
        />
      </ResponsiveCard>
    </FullScreenOverlay>
    <FullScreenOverlay
      isOpen={addingExercise}
      onClose={toggleAddingExercise}
    >
      <ResponsiveCard
        size={ResponsiveCard.SIZES.sm}
      >
        <AddExercise
          onSave={toggleAddingExercise}
        />
      </ResponsiveCard>
    </FullScreenOverlay>
    <FullScreenOverlay
      isOpen={isSummaryShown}
      onClose={toggleIsSummaryShown}
    >
      <ResponsiveCard
        size={ResponsiveCard.SIZES.sm}
      >
        <ExerciseSummary
          onClose={toggleIsSummaryShown}
        />
      </ResponsiveCard>
    </FullScreenOverlay>
  </div>;
}

// function ExerciseCategories({categories, setCategory$}) {
//   return <div className="ExerciseCategories">
//     <Category
//       name="Wszystkie ćwiczenia"
//       icon="asterisk"
//
//       filterCategory={setCategory$()}
//     />
//     {Object.entries(categories).map(([id, {name, icon}]) => (
//       <Category key={id}
//         name={name}
//         icon={icon}
//
//         filterCategory={setCategory$(id)}
//       />
//     ))}
//   </div>
// }

// const Category = ({
//   name, icon,
//
//   filterCategory,
// }) => (
//   <Button className="Category"
//     large={true}
//     minimal={true}
//
//     text={upperFirst(name)}
//     icon={icon}
//
//     onClick={filterCategory}
//   />
// );

let EXERCISE_LOADING;
function ExerciseLoading({onLoaded, close}) {

  clearTimeout(EXERCISE_LOADING);
  EXERCISE_LOADING = setTimeout(onLoaded, 3000);

  function innerClose() {
    clearTimeout(EXERCISE_LOADING);
    close()
  }

  return <NonIdealState className="ExerciseLoading"
    icon={<Spinner
      size={80}
    />}
    title="Ładuję ćwiczenie"
    action={<Button
      icon="cross"
      text="Zamknij"
      intent={Intent.DANGER}

      onClick={innerClose}
    />}
  />
}

// function Filters({currentSort, setSortType$}) {
//   return <div className="Filters">
//     <Button
//       minimal={true}
//
//       icon={currentSort === SORT_TYPES.NAME ? "tick-circle" : "circle"}
//       text="sortuj po nazwie"
//
//       onClick={setSortType$(SORT_TYPES.NAME)}
//     />
//     <Button
//       minimal={true}
//
//       icon={currentSort === SORT_TYPES.ACTIVITY_DATE ? "tick-circle" : "circle"}
//       text="sortuj po czasie ostatniej aktywności"
//
//       onClick={setSortType$(SORT_TYPES.ACTIVITY_DATE)}
//     />
//   </div>
// }

const EMPTY_EXERCISE = {
  name: "",
  description: "",
  objective: "",
  categories: [],
};

const exerciseRenderer = ({id, name, icon}, { handleClick, modifiers, query }) => {
  return <MenuItem key={id} className="base-exercise-choice"
    icon={<img alt="Exercise icon" src={icon} />}
    text={name}

    onClick={handleClick}
  />
};

const categoryRenderer = ({id, name, icon}, {handleClick, modifiers, query}) => (
  <MenuItem key={id} className="category-choice"
    icon={icon}
    text={name}

    onClick={handleClick}
  />
);

function AddExercise({onSave}) {
  const dispatch = useDispatch();
  const baseExercises = useSelector(state => state.exercises.exerciseList).filter(e => e.base === null);
  const categories = Object.values(useSelector(state => state.exercises.categories));

  const [exercise, setExercise] = useState({...EMPTY_EXERCISE});
  const [baseExercise, setBaseExercise] = useState(baseExercises[0]);
  const [duration, setDuration] = useState([3, 0]);
  const [recordedVideo, setRecordedVideo] = useState(undefined);
  const [trim, setTrim] = useState(undefined);

  const updateRecordedVideo = (newRecordedVideo, newTrim) => {
    setRecordedVideo(newRecordedVideo);
    setTrim(newTrim);
  };
  
  const setExerciseValue = (key, value) => {
    let newExercise = {...exercise};
    newExercise[key] = value;

    setExercise(newExercise);
  };

  const addExerciseCategory = (category) => {
    setExercise({
      ...exercise,
      categories: [
        ...exercise.categories,
        category,
      ]
    })
  };

  const removeExerciseCategory = (name, index) => {
    const newExercise = {...exercise};

    newExercise.categories.splice(index, 1);
    setExercise(newExercise);
  };

  const toggleExerciseCategory = (category, index) => {
    const existingIndex = findIndex(exercise.categories, c => c.id === category.id);

    if (existingIndex >= 0) {
      removeExerciseCategory(category.name, existingIndex);
    } else {
      addExerciseCategory(category);
    }
  };

  const createExercise = () => {
    const newExercise = {
      ...exercise,
      base: baseExercise.id,
    };

    if (recordedVideo) {
      newExercise.video = {
        content: URL.createObjectURL(recordedVideo),
        type: recordedVideo.type,
        trim,
      }
    }

    dispatch(ExercisesActions.createExerciseType(newExercise));

    onSave();
  };

  return <div className="AddExercise">
    <CardHeading>
      Tworzenie ćwiczenia
    </CardHeading>
    <form>
      <FormGroup
        label="Nazwa ćwiczenia"
        labelFor="name"
      >
        <InputGroup id="name"
          large={true}

          value={defaultTo(exercise['name'], "")}
          onChange={(e) => setExerciseValue("name", e.target.value)}
        />
      </FormGroup>
      <FormGroup
        label="Opis ćwiczenia"
        labelFor="send-message-content"
      >
        <TextArea id="short_description"
          large={true}
          fill={true}

          growVertically={true}

          value={exercise['short_description']}
          onChange={(e) => setExerciseValue("short_description", e.target.value)}
        />
      </FormGroup>
      <FormGroup
        label="Szczegóły ćwiczenia"
        labelFor="send-message-content"
      >
        <TextArea
          id="description"
          large={true}
          fill={true}

          growVertically={true}

          value={exercise['description']}
          onChange={(e) => setExerciseValue("description", e.target.value)}
        />
      </FormGroup>
      <FormGroup
        label="Kategorie"
        labelFor="categories"
      >
        <MultiSelect className="select-base-exercise" id="categories"
          items={categories}
          itemRenderer={categoryRenderer}
          selectedItems={exercise.categories}

          onItemSelect={toggleExerciseCategory}

          tagInputProps={{onRemove: removeExerciseCategory}}
          tagRenderer={category => category.name}

          itemPredicate={(query, item) => {
            return item.name.toLowerCase().includes(query.toLowerCase());
          }}

          placeholder="Wybierz kategorie"
          popoverProps={{"className": "select-base-exercise-popover"}}
        >
          <Button
            icon={baseExercise.icon ? <img alt="Exercise icon" src={baseExercise.icon}/> : ""}
            text={baseExercise.name}
            rightIcon="double-caret-vertical"
          />
        </MultiSelect>
      </FormGroup>
      <FormGroup
        label="Ćwiczenie bazowe"
        labelFor="base-exercise"
      >
        <Select className="select-base-exercise"
          items={baseExercises}
          itemRenderer={exerciseRenderer}

          onItemSelect={setBaseExercise}

          itemPredicate={(query, item) => {
            return item.name.toLowerCase().includes(query.toLowerCase());
          }}

          popoverProps={{"className": "select-base-exercise-popover"}}
        >
          <Button
            icon={baseExercise.icon ? <img alt="Exercise icon" src={baseExercise.icon}/> : ""}
            text={baseExercise.name}
            rightIcon="double-caret-vertical"
          />
        </Select>
      </FormGroup>
      <FormGroup
        label="Czas trwania rundy"
        labelFor="duration"
      >
        <TimePicker
          value={new Date(0, 0, 0, 0, duration[0], duration[1])}
          onChange={(date) => setDuration([date.getMinutes(), date.getSeconds()])}

          showArrowButtons={true}
          precision={TimePrecision.SECOND}
        />
      </FormGroup>
      <MultimediaRecorder
        video={true}

        onRecordingChanged={updateRecordedVideo}
      />
      <div className="actions">
        <ButtonGroup
          large={true}
          fill={true}
        >
          <Button
            intent={Intent.PRIMARY}

            icon="confirm"
            text="Zapisz"

            onClick={createExercise}
          />
        </ButtonGroup>
      </div>
    </form>
  </div>
}