import RemindersActions from "redux/actions/patient/reminders-actions";
import groupByKey from "lib/groupByKey";

const initialState = {
  current: {
    id: undefined,
    data: {},
    editing: false,
  },

  list: undefined,
  byId: undefined,
};

const TYPES = RemindersActions.TYPES;

const DEFAULT_REMINDER_TIME = new Date();
DEFAULT_REMINDER_TIME.setHours(12);
DEFAULT_REMINDER_TIME.setMinutes(0);

const EMPTY_REMINDER = {
  days: [
    false, false, false, false, false, false, false,
  ],
  time: DEFAULT_REMINDER_TIME,
  email: true,
  push: false,
  description: "",
};

export default function remindersReducer(state = initialState, action = {}) {
  const {type, payload} = action;

  switch(type) {
    case TYPES.REMINDERS_CHANGED: {
      const {reminders} = payload;
      const byId = groupByKey(reminders, "id");

      return {
        ...state,
        list: reminders,
        byId,
      };
    }

    case TYPES.START_ADDING_REMINDER: {
      return {
        ...state,
        current: {
          ...state.current,
          id: undefined,
          data: {
            ...EMPTY_REMINDER
          },
          editing: true,
        }
      }
    }

    case TYPES.START_EDITING_REMINDER: {
      const {id} = payload;

      return {
        ...state,
        current: {
          ...state.current,
          id,
          data: {
            ...state.byId[id]
          },
          editing: true,
        }
      }
    }

    case TYPES.SAVE_CURRENT_REMINDER:
    case TYPES.STOP_EDITING: {
      return {
        ...state,
        current: {
          ...state.current,
          editing: false,
        }
      }
    }

    case TYPES.CHANGE_CURRENT_REMINDER: {
      const {data} = payload;

      return {
        ...state,
        current: {
          ...state.current,
          data: {
            ...state.current.data,
            ...data,
          }
        }
      }
    }

    default:
      return state
  }
}