import TasksActions from "../../actions/therapist/tasks-actions";
import groupByKey from "../../../lib/groupByKey";

export const TASK_TYPES = {
  NEW_SESSION: "new-session",
  NOT_EXERCISING: "not-exercising",
  REPORT: "report",
};

const initialState = {
  list: undefined,
  byId: undefined,

  current: {
    id: undefined,
    data: undefined,
  },
};

const TYPES = TasksActions.TYPES;

export default function tasksReducer(state = initialState, action = {}) {
  const {type, payload} = action;

  switch(type) {
    case TYPES.TASKS_CHANGED: {
      let {tasks} = payload;
      const byId = groupByKey(tasks, "id");

      let currentTaskData;
      if (state.current.id !== undefined) {
        currentTaskData = byId[state.current.id];
      }

      return {
        ...state,
        list: tasks,
        byId,
        current: {
          ...state.current,
          data: currentTaskData,
        }
      }
    }

    case TYPES.SELECT_PATIENT: {
      const {id} = payload;
      const currentPatient = state.byId[id];

      return {
        ...state,
        current:{
          ...state.current,
          id,
          data: currentPatient,
        },
      };
    }

    default:
      return state
  }
}