import ProfileActions from "redux/actions/common/profile-actions";

const initialState = {
  current: undefined,
  loggedIn: undefined,
  isProcessing: false,
  processingErrorMessage: undefined,
};

const TYPES = ProfileActions.TYPES;

export default function profileReducer(state = initialState, action = {}) {
  const {type, payload} = action;

  switch(type) {
    case TYPES.REQUEST_PROFILE:
    case TYPES.SAVE_PROFILE: {
      return {
        ...state,
        isProcessing: true,
      }
    }

    case TYPES.LOGGED_PROFILE_CHANGED: {
      const {profile} = payload;

      return {
        ...state,
        isProcessing: false,
        loggedIn: profile,
      };
    }

    case TYPES.PROFILE_CHANGED: {
      const {profile} = payload;

      return {
        ...state,
        isProcessing: false,
        current: profile,
      };
    }

    case TYPES.CHANGE_PROFILE_FIELD: {
      const {field, value} = payload;

      return {
        ...state,
        current: {
          ...state.current,
          [field]: value,
        }
      };
    }

    default:
      return state
  }
}