function _extends() { _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }

function _objectWithoutProperties(source, excluded) { if (source == null) return {}; var target = _objectWithoutPropertiesLoose(source, excluded); var key, i; if (Object.getOwnPropertySymbols) { var sourceSymbolKeys = Object.getOwnPropertySymbols(source); for (i = 0; i < sourceSymbolKeys.length; i++) { key = sourceSymbolKeys[i]; if (excluded.indexOf(key) >= 0) continue; if (!Object.prototype.propertyIsEnumerable.call(source, key)) continue; target[key] = source[key]; } } return target; }

function _objectWithoutPropertiesLoose(source, excluded) { if (source == null) return {}; var target = {}; var sourceKeys = Object.keys(source); var key, i; for (i = 0; i < sourceKeys.length; i++) { key = sourceKeys[i]; if (excluded.indexOf(key) >= 0) continue; target[key] = source[key]; } return target; }

import React from "react";

var SvgButterfly = function SvgButterfly(_ref) {
  var svgRef = _ref.svgRef,
      title = _ref.title,
      props = _objectWithoutProperties(_ref, ["svgRef", "title"]);

  return React.createElement("svg", _extends({
    width: "100%",
    height: "100%",
    viewBox: "0 0 369 252",
    xmlSpace: "preserve",
    style: {
      fillRule: "evenodd",
      clipRule: "evenodd",
      strokeLinejoin: "round",
      strokeMiterlimit: 2
    },
    ref: svgRef
  }, props), title ? React.createElement("title", null, title) : null, React.createElement("g", {
    transform: "matrix(1,0,0,1,-61.4397,-119.876)"
  }, React.createElement("path", {
    d: "M231.004,215.502C231.319,219.725 234.998,222.894 239.221,222.579C243.444,222.265 246.613,218.586 246.298,214.362C245.313,201.484 242.472,188.995 237.828,178.328C233.313,167.955 227.061,159.25 219.095,153.424C215.683,150.923 210.889,151.662 208.389,155.073C205.888,158.485 206.626,163.278 210.038,165.779C215.721,169.935 220.325,176.478 223.794,184.445C227.723,193.471 230.142,204.243 231.004,215.502L231.004,215.502Z",
    style: {
      fill: "rgb(66,66,66)",
      fillRule: "nonzero"
    }
  }), React.createElement("path", {
    d: "M244.562,214.362C244.247,218.585 247.416,222.264 251.639,222.579C255.862,222.893 259.541,219.725 259.856,215.502C260.718,204.243 263.137,193.471 267.066,184.445C270.535,176.477 275.139,169.935 280.822,165.779C284.234,163.278 284.972,158.485 282.471,155.073C279.971,151.661 275.177,150.923 271.765,153.424C263.8,159.249 257.547,167.955 253.032,178.327C248.388,188.995 245.547,201.484 244.562,214.362L244.562,214.362Z",
    style: {
      fill: "rgb(66,66,66)",
      fillRule: "nonzero"
    }
  }), React.createElement("path", {
    d: "M223.279,219.801C230.885,214.085 238.549,210.784 246.287,210.551C254.42,210.305 262.149,213.365 269.387,220.431L259.932,310.817L269.387,341.652C262.149,348.718 254.42,351.778 246.287,351.532C238.55,351.299 230.885,347.998 223.279,342.282L231.343,310.583L223.279,219.801L223.279,219.801Z",
    style: {
      fill: "rgb(66,66,66)",
      fillRule: "nonzero"
    }
  }), React.createElement("path", {
    d: "M428.378,156.662C432.168,141.501 430.007,130.361 418.06,126.666C414.039,124.163 409.61,122.383 404.856,121.271C384.989,116.625 359.143,123.886 334.307,138.564C310.139,152.845 286.584,174.264 270.459,198.451C267.033,203.59 263.935,208.865 261.227,214.236C256.917,222.785 253.596,231.577 251.52,240.453L251.34,241.983L249.662,297.294C249.391,306.219 250.559,315.629 253.38,325.471C255.404,332.531 258.274,339.834 262.069,347.361C263.252,349.705 264.523,352.07 265.887,354.457L267.116,356.077C284.023,372.984 302.381,374.807 318.549,367.797C325.948,364.59 332.773,359.482 338.664,353.123C344.414,346.917 349.297,339.5 352.96,331.503C361.534,312.78 363.556,290.632 354.671,272.754C355.837,272.491 356.985,272.17 358.112,271.789C364.107,269.758 369.356,266.093 373.621,260.278L374.011,259.678C376.547,255.45 379.263,251.042 382.245,246.201C398.49,219.83 422.214,181.318 428.378,156.662L428.378,156.662ZM324.732,254.079L324.732,255.75C324.732,262.109 319.574,267.267 313.215,267.267C306.856,267.267 301.698,262.109 301.698,255.75L301.698,251.902L301.84,251.902C303.034,230.949 313.668,207.716 325.821,191.334C340.461,171.598 362.168,154.519 387.805,154.519L387.812,154.519C394.171,154.519 399.329,159.677 399.329,166.036C399.329,172.396 394.171,177.553 387.812,177.553L387.805,177.553C369.912,177.553 354.338,191.473 344.284,205.026C334.635,218.033 325.326,237.395 324.732,254.079L324.732,254.079ZM268.038,288.081L267.805,288.081L267.805,284.238C267.805,277.879 272.963,272.72 279.322,272.72C285.682,272.72 290.84,277.878 290.84,284.238L290.84,285.226C291.21,291.435 294.945,298.801 298.485,303.574C301.764,307.993 307.317,313.521 313.245,313.521L313.248,313.521C319.608,313.521 324.766,318.679 324.766,325.038C324.766,331.397 319.608,336.555 313.248,336.555L313.245,336.555C299.518,336.555 287.91,327.899 280.022,317.266C274.111,309.297 269.09,298.332 268.038,288.081L268.038,288.081Z",
    style: {
      fill: "rgb(66,66,66)"
    }
  }), React.createElement("path", {
    d: "M221.06,198.451C204.935,174.264 181.381,152.846 157.213,138.564C132.376,123.886 106.531,116.625 86.664,121.271C81.91,122.383 77.48,124.163 73.459,126.666C61.513,130.361 59.352,141.501 63.142,156.662C69.306,181.318 93.03,219.831 109.274,246.201C112.257,251.042 114.972,255.451 117.509,259.678L117.899,260.278C122.164,266.094 127.413,269.758 133.408,271.789C134.535,272.171 135.683,272.492 136.849,272.755C127.963,290.633 129.986,312.78 138.56,331.503C142.222,339.5 147.106,346.917 152.855,353.123C158.747,359.482 165.572,364.59 172.971,367.798C189.14,374.807 207.497,372.984 224.403,356.077L225.633,354.457C227.147,351.808 228.547,349.185 229.837,346.588C233.441,339.335 236.185,332.29 238.14,325.472C240.961,315.63 242.128,306.219 241.858,297.295L240.18,241.983L240,240.454C238.41,233.656 236.09,226.907 233.154,220.279C232.476,218.747 231.765,217.222 231.022,215.704C230.912,215.479 230.801,215.254 230.689,215.029C227.889,209.384 224.655,203.843 221.06,198.452L221.06,198.451ZM216.043,288.081C214.991,298.332 209.97,309.297 204.059,317.265C196.171,327.899 184.563,336.555 170.837,336.555L170.833,336.555C164.474,336.555 159.316,331.397 159.316,325.038C159.316,318.679 164.474,313.521 170.833,313.521L170.837,313.521C176.765,313.521 182.318,307.993 185.596,303.574C189.136,298.801 192.871,291.435 193.242,285.226L193.242,284.238C193.242,277.878 198.4,272.72 204.759,272.72C211.118,272.72 216.276,277.879 216.276,284.238L216.276,288.081L216.043,288.081L216.043,288.081ZM195.832,251.902L195.974,251.902L195.974,255.75C195.974,262.109 190.816,267.267 184.457,267.267C178.097,267.267 172.94,262.109 172.94,255.75L172.94,254.079C172.346,237.395 163.037,218.033 153.388,205.026C143.334,191.473 127.759,177.553 109.867,177.553L109.86,177.553C103.501,177.553 98.343,172.396 98.343,166.036C98.343,159.677 103.5,154.519 109.86,154.519L109.867,154.519C135.504,154.519 157.211,171.598 171.851,191.334C184.004,207.716 194.638,230.949 195.832,251.902L195.832,251.902Z",
    style: {
      fill: "rgb(66,66,66)"
    }
  }), React.createElement("path", {
    d: "M192.104,255.742C192.102,237.268 183.188,213.06 168.767,193.622C153.965,173.667 133.214,158.359 109.867,158.359L109.86,158.359C105.62,158.359 102.183,161.796 102.183,166.036C102.183,170.276 105.62,173.713 109.86,173.713L109.867,173.713C127.746,173.713 144.288,186.313 156.472,202.738C169.039,219.679 176.808,240.325 176.81,255.742L176.78,255.742L176.78,255.75C176.78,259.989 180.217,263.427 184.457,263.427C188.697,263.427 192.134,259.989 192.134,255.75L192.134,255.742L192.104,255.742L192.104,255.742Z",
    style: {
      fill: "rgb(0,127,211)"
    }
  }), React.createElement("path", {
    d: "M328.905,193.622C314.485,213.06 305.571,237.268 305.569,255.742L305.539,255.742L305.539,255.75C305.539,259.99 308.976,263.427 313.216,263.427C317.456,263.427 320.893,259.99 320.893,255.75L320.893,255.742L320.863,255.742C320.865,240.325 328.634,219.679 341.201,202.739C353.385,186.313 369.927,173.713 387.805,173.713L387.812,173.713C392.052,173.713 395.49,170.276 395.49,166.036C395.49,161.796 392.052,158.359 387.812,158.359L387.805,158.359C364.459,158.359 343.708,173.667 328.905,193.622L328.905,193.622Z",
    style: {
      fill: "rgb(0,127,211)"
    }
  }), React.createElement("path", {
    d: "M313.245,317.361C306.605,317.361 300.229,312.369 295.402,305.861C290.191,298.838 286.971,290.419 286.97,284.241L287,284.241L287,284.237C287,279.998 283.563,276.56 279.323,276.56C275.083,276.56 271.646,279.998 271.646,284.237L271.646,284.241L271.676,284.241C271.677,293.476 276.043,305.456 283.106,314.978C290.552,325.015 301.137,332.715 313.245,332.715L313.248,332.715C317.489,332.715 320.926,329.278 320.926,325.038C320.926,320.798 317.489,317.361 313.248,317.361L313.245,317.361L313.245,317.361Z",
    style: {
      fill: "rgb(0,127,211)"
    }
  }), React.createElement("path", {
    d: "M200.976,314.978C208.039,305.456 212.405,293.476 212.406,284.241L212.437,284.241L212.437,284.237C212.437,279.998 208.999,276.56 204.759,276.56C200.52,276.56 197.082,279.998 197.082,284.237L197.082,284.241L197.112,284.241C197.111,290.419 193.89,298.838 188.681,305.861C183.853,312.369 177.478,317.361 170.837,317.361L170.834,317.361C166.594,317.361 163.157,320.798 163.157,325.038C163.157,329.278 166.594,332.715 170.834,332.715L170.837,332.715C182.945,332.715 193.53,325.015 200.976,314.978L200.976,314.978Z",
    style: {
      fill: "rgb(0,127,211)"
    }
  })));
};

var ForwardRef = React.forwardRef(function (props, ref) {
  return React.createElement(SvgButterfly, _extends({
    svgRef: ref
  }, props));
});
export default __webpack_public_path__ + "static/media/butterfly.07d5fb0f.svg";
export { ForwardRef as ReactComponent };