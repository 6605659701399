function _extends() { _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }

function _objectWithoutProperties(source, excluded) { if (source == null) return {}; var target = _objectWithoutPropertiesLoose(source, excluded); var key, i; if (Object.getOwnPropertySymbols) { var sourceSymbolKeys = Object.getOwnPropertySymbols(source); for (i = 0; i < sourceSymbolKeys.length; i++) { key = sourceSymbolKeys[i]; if (excluded.indexOf(key) >= 0) continue; if (!Object.prototype.propertyIsEnumerable.call(source, key)) continue; target[key] = source[key]; } } return target; }

function _objectWithoutPropertiesLoose(source, excluded) { if (source == null) return {}; var target = {}; var sourceKeys = Object.keys(source); var key, i; for (i = 0; i < sourceKeys.length; i++) { key = sourceKeys[i]; if (excluded.indexOf(key) >= 0) continue; target[key] = source[key]; } return target; }

import React from "react";

var SvgLeftHand = function SvgLeftHand(_ref) {
  var svgRef = _ref.svgRef,
      title = _ref.title,
      props = _objectWithoutProperties(_ref, ["svgRef", "title"]);

  return React.createElement("svg", _extends({
    width: "100%",
    height: "100%",
    viewBox: "0 0 1370 1294",
    xmlSpace: "preserve",
    style: {
      fillRule: "evenodd",
      clipRule: "evenodd",
      strokeLinejoin: "round",
      strokeMiterlimit: 2
    },
    ref: svgRef
  }, props), title ? React.createElement("title", null, title) : null, React.createElement("g", null, React.createElement("g", null, React.createElement("path", {
    id: "_311155712",
    d: "M1020.89,887.531c-1.305,3.735 -2.72,7.456 -4.237,11.175c-10.061,24.732 -23.759,46.664 -38.005,69.099c-7.278,11.46 -14.099,25.634 -20.224,41.265c-1.246,7.713 -2.479,16.322 -3.659,25.29c-3.151,23.928 -5.811,50.18 -7.327,68.633l-0.039,10.914c-5.476,-2.484 -11.539,-3.868 -17.9,-3.874l-492.452,-0.376c-5.766,-0.007 -11.284,1.128 -16.346,3.184c-1.43,-51.56 -13.054,-120.879 -31.502,-161.433c-10.682,-23.503 -20.791,-46.334 -27.668,-71.323c-8.736,-31.726 -12.351,-63.805 -10.657,-96.614l26.588,-514.675c4.642,-89.893 142.266,-118.666 139.069,-7.768l-7.863,272.747c-0.137,4.765 3.613,8.747 8.381,8.88c4.767,0.142 8.742,-3.612 8.88,-8.377l9.083,-400.234c18.256,-72.964 139.471,-83.342 136.762,10.59l-9.005,397.547c-0.138,4.766 3.616,8.741 8.382,8.879c4.767,0.137 8.743,-3.617 8.88,-8.381l11.621,-488.293c2.454,-63.596 136.37,-81.863 133.936,2.541l-10.498,449.338c-0.137,4.766 3.615,8.742 8.384,8.881c4.765,0.136 8.742,-3.617 8.879,-8.383c5.637,-195.554 3.855,-134.312 9.492,-329.864c3.082,-106.843 162.373,-102.108 153.786,4.433c-12.12,150.399 -0.274,239.695 5.876,292.355c7.135,61.087 11.974,74.556 47.749,103.454c13.209,-10.677 27.247,-29.515 42.171,-49.548c21.833,-29.317 45.166,-60.624 76.62,-81.725c9.169,-6.146 18.782,-11.754 28.609,-16.53c25.871,-12.571 53.911,-19.851 80.719,-17.251c29.194,2.834 55.974,16.706 76.13,46.759c3.537,5.269 6.807,10.951 9.777,17.064c23.006,47.344 -17.355,73.782 -56.851,99.651c-15.048,9.85 -29.894,19.575 -34.691,27.019c-51.047,79.166 -95.385,123.692 -134.27,162.748c-20.437,20.54 -39.296,39.475 -56.35,61.017c-10.694,13.525 -22.755,24.91 -35.723,37.148c-7.921,7.484 -16.229,15.322 -24.507,24.038Z",
    style: {
      fill: "#424242"
    }
  }), React.createElement("path", {
    id: "_311154824",
    d: "M945.657,1153.91c0.007,-8.935 -7.242,-16.194 -16.176,-16.201l-492.452,-0.374c-8.935,-0.006 -16.194,7.242 -16.201,16.177l-0.094,123.467c-0.007,8.935 7.242,16.194 16.176,16.201l492.453,0.374c8.934,0.007 16.194,-7.242 16.2,-16.176l0.094,-123.468Z",
    style: {
      fill: "#0086d6"
    }
  }))));
};

var ForwardRef = React.forwardRef(function (props, ref) {
  return React.createElement(SvgLeftHand, _extends({
    svgRef: ref
  }, props));
});
export default __webpack_public_path__ + "static/media/left-hand.5b28d8ac.svg";
export { ForwardRef as ReactComponent };