import ButterflyIcon from "images/game-icons/butterfly.svg";
import RacingIcon from "images/game-icons/racing.svg";
import BoxingIcon from "images/game-icons/boxing.svg";

import loremGenerator from "test/loremGenerator";

const CATEGORY_IDS = {
  "custom": 0,
  "hand": 1,
  "strength": 2,
  "endurance": 3,
};

const EXERCISES_DATA = {
  CATEGORIES: {
    [CATEGORY_IDS.custom]: {
      id: CATEGORY_IDS.custom,
      name: "Twoje ćwiczenia",
      icon: "person",
    },
    [CATEGORY_IDS.hand]: {
      id: CATEGORY_IDS.hand,
      name: "Na ręce",
      icon: "hand",
    },
    [CATEGORY_IDS.strength]: {
      id: CATEGORY_IDS.strength,
      name: "Siłowe",
      icon: "build",
    },
    [CATEGORY_IDS.endurance]: {
      id: CATEGORY_IDS.endurance,
      name: "Wzmacniające",
      icon: "export",
    },
  }
};

EXERCISES_DATA.EXERCISES = {
  1: {
    id: 1,
    name: "Motyl",
    short_description: loremGenerator.generateParagraphs(1),
    description: loremGenerator.generateParagraphs(4),
    categories: [
      EXERCISES_DATA.CATEGORIES[CATEGORY_IDS.strength],
      EXERCISES_DATA.CATEGORIES[CATEGORY_IDS.endurance],
    ],
    activity_date: new Date(2020, 1, 8, 12, 13),
    parameters: {
      level: 4,
      hand: ["left"],
      duration: 4,
    },
    icon: ButterflyIcon,
    base: null,
  },
  2: {
    id: 2,
    name: "Tor",
    short_description: loremGenerator.generateParagraphs(1),
    description: loremGenerator.generateParagraphs(4),
    categories: [
      EXERCISES_DATA.CATEGORIES[CATEGORY_IDS.strength],
      EXERCISES_DATA.CATEGORIES[CATEGORY_IDS.hand],
    ],
    activity_date: new Date(2020, 1, 10, 15, 23),
    parameters: {
      level: 3,
      hand: ["left"],
      duration: 3,
    },
    icon: RacingIcon,
    base: null,
  },
  3: {
    id: 3,
    name: "Boks",
    short_description: loremGenerator.generateParagraphs(1),
    description: loremGenerator.generateParagraphs(4),
    categories: [
      EXERCISES_DATA.CATEGORIES[CATEGORY_IDS.endurance],
      EXERCISES_DATA.CATEGORIES[CATEGORY_IDS.hand],
    ],
    activity_date: new Date(2020, 1, 15, 13, 54),
    parameters: {
      level: 5,
      hand: ["right"],
      duration: 3,
    },
    icon: BoxingIcon,
    base: null,
  },
  4: {
    id: 4,
    name: "Motyl",
    short_description: loremGenerator.generateParagraphs(1),
    description: loremGenerator.generateParagraphs(4),
    categories: [
      EXERCISES_DATA.CATEGORIES[CATEGORY_IDS.strength],
      EXERCISES_DATA.CATEGORIES[CATEGORY_IDS.endurance],
    ],
    activity_date: new Date(2020, 1, 12, 14, 23),
    parameters: {
      level: 5,
      hand: ["left", "right"],
      duration: 4,
    },
    icon: ButterflyIcon,
    base: 1,
  },
  5: {
    id: 5,
    name: "Tor",
    short_description: loremGenerator.generateParagraphs(1),
    description: loremGenerator.generateParagraphs(4),
    categories: [
      EXERCISES_DATA.CATEGORIES[CATEGORY_IDS.strength],
      EXERCISES_DATA.CATEGORIES[CATEGORY_IDS.hand],
    ],
    activity_date: new Date(2020, 1, 7, 9, 34),
    parameters: {
      level: 2,
      hand: ["right"],
      duration: 3,
    },
    icon: RacingIcon,
    base: 2,
  },
  6: {
    id: 6,
    name: "Boks",
    short_description: loremGenerator.generateParagraphs(1),
    description: loremGenerator.generateParagraphs(4),
    categories: [
      EXERCISES_DATA.CATEGORIES[CATEGORY_IDS.endurance],
      EXERCISES_DATA.CATEGORIES[CATEGORY_IDS.hand],
    ],
    activity_date: new Date(2020, 1, 5, 12, 37),
    parameters: {
      level: 8,
      hand: ["right"],
      duration: 7,
    },
    icon: BoxingIcon,
    base: 3,
  },
};

export default EXERCISES_DATA;