import React, {useEffect, useState} from 'react';
import {
  Button,
  ButtonGroup,
  FormGroup,
  H3,
  Icon,
  InputGroup,
  Intent,
  NonIdealState,
  NumericInput, TextArea
} from "@blueprintjs/core";
import classNames from 'classnames';
import {DragDropContext, Draggable, Droppable} from "react-beautiful-dnd";

import {ReactComponent as LeftHandComponent} from "images/icons/left-hand.svg";
import {ReactComponent as RightHandComponent} from "images/icons/right-hand.svg";

import useToggle from "lib/useToggle";
import CardHeading from "ui/text/CardHeading";
import FullScreenOverlay from "ui/FullScreenOverlay";
import ExerciseList from "views/ExerciseList";
import ResponsiveCard from "ui/ResponsiveCard";
import CloseCardButton from "ui/CloseCardButton";
import InlineableComponent from "ui/layout/InlineableComponent";
import {useDispatch, useSelector} from "react-redux";

import "./SessionEditor.scss";
import Loader from "../../../ui/Loader";
import {defaultTo, without} from "lodash";
import SessionsActions from "../../../redux/actions/common/sessions-actions";

let NEXT_TEMP_ID = -1;

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

export default function SessionEditor({save}) {
  const dispatch = useDispatch();
  const exercisesById = useSelector(state => state.exercises.byId);
  const currentSession = useSelector(state => state.sessions.current.data);

  const [isExerciseBeingAdded, toggleIsExerciseBeingAdded] = useToggle(false);
  const [exerciseBasket, setExerciseBasket] = useState([]);
  const [movingIndexes, setMovingIndexes] = useState({
    active: false,
  });

  const addToExerciseBasket = (exerciseId) => {
    let newExercises = [...exerciseBasket];
    const exerciseToBasket = exercisesById[exerciseId];
    newExercises.push({
      id: NEXT_TEMP_ID--,
      name: exerciseToBasket.name,
      icon: exerciseToBasket.icon,
      ...exerciseToBasket.parameters,
    });

    setExerciseBasket(newExercises);
  };

  const removeFromExerciseBasketByIndex$ = (exerciseIndex$) => () => {
    let newExercises = [...exerciseBasket];
    newExercises.splice(exerciseIndex$, 1);

    setExerciseBasket(newExercises);
  };

  const addExercisesFromBasket = () => {
    let exercises = [...currentSession.exercises];
    exercises.push(...exerciseBasket);

    dispatch(SessionsActions.updateCurrentSession({
      exercises,
    }));
    setExerciseBasket([]);
    toggleIsExerciseBeingAdded();
  };

  const moveExercise = (index, change) => {
    const newExercises = [...currentSession.exercises];
    const element = currentSession.exercises[index];
    newExercises.splice(index, 1);
    newExercises.splice(index + change, 0, element);

    setMovingIndexes({
      active: true,
      up: change < 0 ? index : index + 1,
      down: change > 0 ? index : index - 1,
    });
    setTimeout(() => setCurrentSessionValue("exercises", newExercises), 300);
  };

  useEffect(() => {
    setMovingIndexes({
      active: false,
    })
  }, [currentSession.exercises]);

  const moveExerciseUp$ = (index$) => () => {
    moveExercise(index$, -1);
  };

  const moveExerciseDown$ = (index$) => () => {
    moveExercise(index$, 1);
  };

  const onDragStart = () => {
    setMovingIndexes({
      active: true
    });
  };

  const onDragEnd = (result) => {
    setMovingIndexes({
      active: false
    });

    if (!result.destination) {
      return;
    }

    const newExercises = reorder(
      currentSession.exercises,
      result.source.index,
      result.destination.index
    );

    setCurrentSessionValue("exercises", newExercises);
  };

  const setCurrentSessionValue = (key, value) => {
    dispatch(SessionsActions.updateCurrentSession({
      [key]: value,
    }))
  };

  const setCurrentSessionExerciseValue$ = (exerciseIndex$) => (key, value) => {
    let exercises = [...currentSession.exercises];
    exercises[exerciseIndex$][key] = value;

    dispatch(SessionsActions.updateCurrentSession({
      exercises,
    }))
  };

  const removeCurrentSessionExercise$ = (exerciseIndex$) => () => {
    const exercises = [...currentSession.exercises];
    exercises.splice(exerciseIndex$, 1);

    dispatch(SessionsActions.updateCurrentSession({
      exercises,
    }))
  };

  if (!currentSession) {
    return <Loader
      text="Wczytuję sesję"
    />
  }

  return <div className="SessionEditor">
    <CardHeading>
      Edycja sesji
    </CardHeading>
    <FormGroup
      className="name-form"

      label="Nazwa sesji"
      labelFor="name"
    >
      <InputGroup id="name"
        fill={true}

        large={true}
        value={defaultTo(currentSession.name,`Sesja ${new Date().toLocaleDateString("pl", {dateStyle:"short", timeStyle: "short"})}`)}
        onChange={e => setCurrentSessionValue("name", e.target.value)}
      />
    </FormGroup>
    <FormGroup
      className="name-form"

      label="Opis"
      labelFor="description"
    >
      <TextArea id="description"
        fill={true}
        large={true}

        rows={3}

        value={defaultTo(currentSession.description,"")}
        onChange={e => setCurrentSessionValue("description", e.target.value)}
      />
    </FormGroup>
    <div className="duration">
      <Icon icon="time" iconSize={Icon.SIZE_LARGE} /> <p>Czas trwania: <strong>{currentSession.exercises.reduce((current, exercise) => current + exercise.duration, 0)
      }m.</strong></p>
    </div>
    <div className="editor">
      <DragDropContext onDragStart={onDragStart} onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable">
          {(provided) => (
            <div className="exercise-list"
              {...provided.droppableProps}
              ref={provided.innerRef}
            >
              {currentSession.exercises.map(({id, icon, name, hand, duration}, index) => (
                <Draggable key={id} draggableId={id.toString()} index={index}>
                  {(innerProvided) => {
                    console.log(innerProvided);
                    return <div className="draggable-exercise-in-session"
                      ref={innerProvided.innerRef}
                      {...innerProvided.draggableProps}
                    >
                      <ExerciseInSession key={index}
                        className={classNames({
                          "moving-up": index === movingIndexes.up,
                          "moving-down": index === movingIndexes.down,
                        })}
                        dragHandleProps={innerProvided.dragHandleProps}

                        icon={icon}
                        name={name}
                        hand={hand}
                        duration={duration}

                        index={index}
                        exerciseLength={currentSession.exercises.length}

                        canMoveUp={!movingIndexes.active && index !== 0}
                        moveUp={moveExerciseUp$(index)}

                        canMoveDown={!movingIndexes.active && index !== (currentSession.exercises.length - 1)}
                        moveDown={moveExerciseDown$(index)}

                        changeValue={setCurrentSessionExerciseValue$(index)}
                        removeExercise={removeCurrentSessionExercise$(index)}
                      />
                    </div>
                  }}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
      <Button className="add-exercise-button"
        large={true}
        fill={true}

        intent={Intent.PRIMARY}
        icon="add"
        text="Dodaj nowe ćwiczenie"

        onClick={toggleIsExerciseBeingAdded}
      />
    </div>
    <div className="actions">
      <Button
        large={true}

        intent={Intent.SUCCESS}
        text="Zapisz"
        icon="confirm"

        onClick={save}
      />
    </div>
    <FullScreenOverlay
      className="add-exercises-window"

      isOpen={isExerciseBeingAdded}
      onClose={toggleIsExerciseBeingAdded}
    >
      <SessionExercisesBasket
        exerciseBasket={exerciseBasket}
        addExercisesFromBasket={addExercisesFromBasket}
        removeByIndex$={removeFromExerciseBasketByIndex$}
      />
      <ResponsiveCard className="exercise-list-card"
        size={ResponsiveCard.SIZES.md}
      >
        <CloseCardButton
          onClick={toggleIsExerciseBeingAdded}
        />
        <CardHeading>
          Dodawanie ćwiczenia
        </CardHeading>
        <ExerciseList
          inline={true}

          mainAction={{
            name: "Dodaj ćwiczenie",
            intent: Intent.PRIMARY,
            icon: "add",
            action: exerciseId => addToExerciseBasket(exerciseId),
          }}
          canSetParameters={false}
        />
      </ResponsiveCard>
    </FullScreenOverlay>
  </div>
}

function ExerciseInSession({
  className,
  name, hand, duration, icon,

  canMoveUp, canMoveDown,
  moveUp, moveDown,

  changeValue, removeExercise,

  dragHandleProps,
}) {
  const toggleHand$ = (changedHand$) => () => {
    let currentHands = [...hand];

    if (hand.includes(changedHand$)) {
      currentHands = without(currentHands, changedHand$);
    } else {
      currentHands.push(changedHand$);
    }

    changeValue("hand", currentHands);
  };

  return <div className={classNames("ExerciseInSession", className)}>
    <div className="change-sequence">
      <Icon
        icon="drag-handle-vertical"
        {...dragHandleProps}
      />
      <ButtonGroup
        vertical={true}
      >
        <Button
          minimal={true}
          icon="arrow-up"
          disabled={!canMoveUp}

          onClick={moveUp}
        />
        <Button
          minimal={true}
          icon="arrow-down"
          disabled={!canMoveDown}

          onClick={moveDown}
        />
      </ButtonGroup>
    </div>
    <div className="description">
      <div className="icon">
        <img src={icon} alt="Ikona ćwiczenia" />
      </div>
      <span className="name">{name}</span>
    </div>
    <div className="hands">
      <ButtonGroup>
        <Button className={classNames("hand-button", {"selected": hand.includes("left")})}
          minimal={true}
          icon={<LeftHandComponent />}

          onClick={toggleHand$("left")}
        />
        <Button className={classNames("hand-button", {"selected": hand.includes("right")})}
          minimal={true}
          icon={<RightHandComponent />}

          onClick={toggleHand$("right")}
        />
      </ButtonGroup>
    </div>
    <div className="time">
      <NumericInput
        large={true}

        value={duration}
        onValueChange={v => changeValue("duration", v)}

        min={1}
        max={30}

        leftIcon="time"
        rightElement={<div className="time-unit">min.</div>}
      />
    </div>
    <div className="actions">
      <ButtonGroup
        large={true}
        vertical={true}
      >
        <Button
          intent={Intent.DANGER}

          icon="cross"
          text="Usuń"

          onClick={removeExercise}
        />
      </ButtonGroup>
    </div>
  </div>
}

function SessionExercisesBasket({
  inline, exerciseBasket,

  addExercisesFromBasket, removeByIndex$
}) {
  return <InlineableComponent className="SessionExercisesBasket"
    inline={inline}
  >
    <H3>Wybrane ćwiczenia</H3>
    <div className="exercise-list">
    {exerciseBasket.length === 0 &&
      <NonIdealState
        title="Nie wybrano żadnych ćwiczeń"
        icon="cross"
      />
    }
    {exerciseBasket.map((exercise, index) => (
      <SessionExerciseToAdd key={index}
        index={index}
        remove={removeByIndex$(index)}

        {...exercise}
      />
    ))
    }
    </div>
    <ButtonGroup
      large={true}
      fill={true}
    >
      <Button
        intent={Intent.PRIMARY}
        icon="confirm"
        text="Dodaj do sesji"

        onClick={addExercisesFromBasket}
      />
    </ButtonGroup>
  </InlineableComponent>
}

function SessionExerciseToAdd({index, name, icon, remove}) {
  return <div className="SessionExerciseToAdd">
    <div className="icon">
      <img src={icon} alt="Ikona ćwiczenia" />
    </div>
    <div className="index">
      {index + 1}.
    </div>
    <div className="name">
      {name}
    </div>
    <Button
      minimal={true}
      intent={Intent.DANGER}

      icon="delete"

      onClick={remove}
    />
  </div>
}