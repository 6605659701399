export default class MessagesActions {
  static TYPES = {
    REQUEST_MESSAGES: "REQUEST_MESSAGES",
    MESSAGES_CHANGED: "MESSAGES_CHANGED",
    SET_CURRENT_MESSAGE_ID: "SET_CURRENT_MESSAGE_ID",
    DELETE_MESSAGE: "DELETE_MESSAGE",
    SEND_MESSAGE: "SEND_MESSAGE",
  };

  static requestMessages = () => ({
    type: TYPES.REQUEST_MESSAGES,
    payload: {}
  });

  static messagesChanged = (messages) => ({
    type: TYPES.MESSAGES_CHANGED,
    payload: {
      messages
    }
  });

  static setCurrentMessageId = (messageId) => ({
    type: TYPES.SET_CURRENT_MESSAGE_ID,
    payload: {
      id: messageId,
    },
  });

  static deleteMessage = (id) => ({
    type: TYPES.DELETE_MESSAGE,
    payload: {
      id,
    }
  });

  static sendMessage = (message) => ({
    type: TYPES.SEND_MESSAGE,
    payload: {
      message,
    }
  });
}

const TYPES = MessagesActions.TYPES;