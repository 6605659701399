import React, {useEffect} from 'react';

import "./Notifications.scss"
import {Icon, Intent} from "@blueprintjs/core";
import {withRouter} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import TasksActions from "../../redux/actions/therapist/tasks-actions";
import {TASK_MESSAGES, TASK_ICONS, TASK_ACTION_URL} from "../therapist/Tasks/Tasks";
import PatientsActions from "../../redux/actions/therapist/patients-actions";
import Button from "react-bootstrap/Button";
import DropdownToggle from "react-bootstrap/DropdownToggle";
import DropdownMenu from "react-bootstrap/DropdownMenu";
import {Dropdown} from "react-bootstrap";
import clsx from "clsx";

export default function Notifications(props) {
  const dispatch = useDispatch();
  const tasks = useSelector(state => state.therapist.tasks.list);
  // const [areNotificationsOpen, setAreNotificationsOpen, toggleAreNotificationsOpen] = useToggleWithSet(false);

  useEffect(() => {
    if (tasks === undefined) {
      dispatch(TasksActions.requestTasks());
    }
  }, [tasks, dispatch]);

  // const closeNotifications = () => setAreNotificationsOpen(false);

  return <Dropdown className={clsx("NotificationsMenu", props.className)}>
    <DropdownToggle as={NotificationsButton} id="user-menu-toggle"
      tasks={tasks}
    />
    <DropdownMenu alignRight={true}>
      <NotificationsList list={tasks} close={() => {}} />
    </DropdownMenu>
  </Dropdown>
  // return <Popover className="NotificationsButton"
  //   isOpen={areNotificationsOpen}
  //   onClose={closeNotifications}
  //   // onInteraction={toggleAreNotificationsOpen}
  // >
  //
  //   <div>
  //     <NotificationsList
  //       list={tasks.slice(0, 3)}
  //       close={closeNotifications}
  //     />
  //   </div>
  //   }
  // </Popover>
}

const NotificationsList = withRouter(({
  list, close,

  history,
}) => {
  const dispatch = useDispatch();

  const goTo$ = (link$) => () => {
    close();
    history.push(link$);
  };

  const changePatientAndGo$ = (patientId$, link$) => () => {
    close();
    history.push(link$);
    dispatch(PatientsActions.selectPatient(patientId$));
  };

  return <div className="Notifications">
    {list.map((info, index) => (
      <Notification key={index}
        close={close}
        action={changePatientAndGo$(info.patient.id, TASK_ACTION_URL[info.type])}
        {...info}
      />
    ))}
    <Button
      onClick={goTo$("/tasks/")}
    >Zobacz wszystkie</Button>
  </div>
});

const NotificationsButton = React.forwardRef(({onClick, tasks}, ref) => {
  return <Button
    variant="flat-dark btn-icon"
    onClick={onClick}
  >
    <i className="feather icon-inbox mr-0" />
    {tasks && tasks.length > 0 &&
    <span className="notifications-count badge badge-pill badge-primary badge-up">
          {tasks.length}
        </span>
    }
  </Button>
});

const Notification = withRouter(({
  type, dueDate, patient,

  action,
}) => {
  const overdue = dueDate < new Date();

  return <div className="Notification"
    onClick={action}
  >
    <div className="icon">
      <Icon
        icon={TASK_ICONS[type]}
        intent={overdue ? Intent.DANGER : Intent.PRIMARY}
      />
    </div>
    <div className="content">
      <div className="title">{TASK_MESSAGES[type]}</div>
      <div className="description">dla: {patient.name}</div>
    </div>
  </div>
});