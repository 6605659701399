import React, {useEffect, useState} from 'react';
import {Callout, Card, H3, HTMLTable, Icon} from "@blueprintjs/core";

import {EARNINGS_TYPES} from "redux/reducers/therapist/earnings-reducer";

import "./Earnings.scss";
import {useDispatch, useSelector} from "react-redux";
import EarningsActions from "../../../redux/actions/therapist/earnings-actions";
import ResponsiveCard from "../../../ui/ResponsiveCard";
import Loader from "../../../ui/Loader";


const TYPE_ICONS = {
  [EARNINGS_TYPES.NEW_SESSION]: "properties",
  [EARNINGS_TYPES.REPORT]: "saved",
};

const TYPE_NAMES = {
  [EARNINGS_TYPES.NEW_SESSION]: "Stworzenie sesji",
  [EARNINGS_TYPES.REPORT]: "Raport teleopieki",
};

export default function Earnings(props) {
  const dispatch = useDispatch();
  const paid = useSelector(state => state.therapist.earnings.paid);
  const unpaid = useSelector(state => state.therapist.earnings.unpaid);
  const nextPayment = useSelector(state => state.therapist.earnings.nextPayment);

  const [nextPaymentAmount, setNextPaymentAmount] = useState(0);

  useEffect(() => {
    if (paid === undefined || unpaid === undefined || nextPayment === undefined) {
      dispatch(EarningsActions.requestEarnings());
    }
  }, [dispatch, paid, unpaid, nextPayment]);

  useEffect(() => {
    if (unpaid !== undefined) {
      let newNextPaymentAmount = unpaid.reduce((total, next) => total + next.amount, 0)
      setNextPaymentAmount(newNextPaymentAmount)
    }
  }, [unpaid]);

  if (paid === undefined || unpaid === undefined || nextPayment === undefined) {
    return <ResponsiveCard
      size={ResponsiveCard.SIZES.md}
    >
      <Loader
        text="Wczytuję płatności"
      />
    </ResponsiveCard>
  }

  return <Card className="Earnings">
    <section>
      <Callout className="next-payment"
        title="Następna płatność"
      >
        <p>
          <Icon icon="calendar" /> Data: <strong>{nextPayment.toLocaleDateString()}</strong>
        </p>
        <p>
          <Icon icon="bank-account" /> Kwota: <strong>{nextPaymentAmount} zł</strong>
        </p>
        <p>
          <Icon icon="credit-card" /> Konto do przelewu: <strong>12 1234 **** **** **** **** 7890</strong>
        </p>
      </Callout>
    </section>
    <section>
      <H3>Wykonane usługi</H3>
      <HTMLTable
        striped={true}
      >
        <thead>
        <tr>
          <th />
          <th>Nazwa</th>
          <th>Cena</th>
          <th>Data usługi</th>
        </tr>
        </thead>
        <tbody>
        {unpaid.map(({type, amount, date}, index) => (
          <tr key={index}>
            <td><Icon
              icon={TYPE_ICONS[type]}
            /></td>
            <td className="name">{TYPE_NAMES[type]}</td>
            <td className="price">{amount} zł</td>
            <td className="date">{date.toLocaleDateString()}</td>
          </tr>
        ))}
        </tbody>
      </HTMLTable>
    </section>
    <section>
      <H3>Archiwum usług</H3>
      <HTMLTable
        striped={true}
      >
        <thead>
        <tr>
          <th />
          <th>Nazwa</th>
          <th>Cena</th>
          <th>Data usługi</th>
          <th>Opłacona</th>
        </tr>
        </thead>
        <tbody>
        {paid.map(({type, amount, date, paid}, index) => (
          <tr key={index}>
            <td><Icon
              icon={TYPE_ICONS[type]}
            /></td>
            <td className="name">{TYPE_NAMES[type]}</td>
            <td className="price">{amount} zł</td>
            <td className="date">{date.toLocaleDateString()}</td>
            <td className="date">{paid.toLocaleDateString()}</td>
          </tr>
        ))}
        </tbody>
      </HTMLTable>
    </section>
  </Card>
}