import {Button} from "react-bootstrap";
import clsx from "clsx";
import {Star} from "react-feather";
import React from "react";

import "./ExerciseFavouriteButton.scss"

export default function ExerciseFavouriteButton({
  className, includeDescription = false,
  isFavourite, toggleIsFavourite,
}) {
  return <Button variant="flat-primary" className={clsx(
    "ExerciseFavouriteButton", className, {"is-favourite": isFavourite, "with-description": includeDescription}
    )}
    onClick={toggleIsFavourite}
  >
    <Star /> <span className="description">{includeDescription ? (isFavourite ? "Usuń z ulubionych" : "Zapisz w ulubionych") : ""}</span>
  </Button>
}