import AuthActions from "redux/actions/common/auth-actions";

export const loginFacebookWithDipsatch$ = (dispatch$) => (response) => {
  let facebookToken = response.accessToken;
  dispatch$(AuthActions.loginWithSocial("facebook", facebookToken));
};

export const loginGoogleWithDipsatch$ = (dispatch$) => (response) => {
  let googleToken = response.accessToken;
  dispatch$(AuthActions.loginWithSocial("google", googleToken));
};