import React from "react";
import {Button, Icon, Intent} from "@blueprintjs/core";
import classNames from 'classnames';

import "./TopIconButton.scss";

export default function TopIconButton({
  className,
  icon=undefined,
  text="",

  onClick,

  ...otherProps
}) {
  let iconComponent;
  if (typeof icon === "string" && icon.includes(".svg")) {
    iconComponent = <img src={icon} alt="icon" />
  } else {
    iconComponent = <Icon
      icon={icon}
    />
  }

  return <Button
    className={classNames("TopIconButton", className)}
    intent={Intent.PRIMARY}
    minimal={true}

    onClick={onClick}

    {...otherProps}
  >
    <div className="icon">
      {iconComponent}
    </div>
    <p className="description">{text}</p>
  </Button>
}