export default class EarningsActions {
  static TYPES = {
    REQUEST_EARNINGS: "REQUEST_EARNINGS",
    EARNINGS_CHANGED: "EARNINGS_CHANGED",
  };

  static requestEarnings = () => ({
    type: TYPES.REQUEST_EARNINGS,
    payload: {}
  });

  static earningsChanged = (list, nextPayment) => ({
    type: TYPES.EARNINGS_CHANGED,
    payload: {
      list,
      nextPayment,
    }
  });
}

const TYPES = EarningsActions.TYPES;