export default class ExercisesActions {
  static TYPES = {
    REQUEST_EXERCISES: "REQUEST_EXERCISES",
    EXERCISES_CHANGED: "EXERCISES_CHANGED",
    UPDATE_EXERCISE_CATEGORIES: "UPDATE_CATEGORIES",
    EXERCISE_CATEGORIES_CHANGED: "EXERCISE_CATEGORIES_CHANGED",
    SET_CURRENT_EXERCISE_ID: "SET_CURRENT_EXERCISE_ID",
    UPDATE_CURRENT_EXERCISE: "UPDATE_CURRENT_EXERCISE",
    SAVE_CURRENT_EXERCISE: "SAVE_CURRENT_EXERCISE",
    CREATE_EXERCISE_TYPE: "CREATE_EXERCISE_TYPE",

    START_EXERCISE: "START_EXERCISE",
    EXERCISE_FINISHED: "EXERCISE_FINISHED",
  };

  static requestExercises = () => ({
    type: TYPES.REQUEST_EXERCISES,
    payload: {}
  });

  static exercisesChanged = (exerciseList) => ({
    type: TYPES.EXERCISES_CHANGED,
    payload: {
      exerciseList
    }
  });

  static updateExerciseCategories = () => ({
    type: TYPES.UPDATE_EXERCISE_CATEGORIES,
    payload: {}
  });

  static exerciseCategoriesChanged = (categories) => ({
    type: TYPES.EXERCISE_CATEGORIES_CHANGED,
    payload: {
      categories
    }
  });

  static setCurrentId = (currentId) => ({
    type: TYPES.SET_CURRENT_EXERCISE_ID,
    payload: {
      currentId,
    }
  });

  static updateCurrentExercise = (newData) => ({
    type: TYPES.UPDATE_CURRENT_EXERCISE,
    payload: {
      newData
    }
  });

  static saveCurrentExercise = (settingsOnly=false) => ({
    type: ExercisesActions.TYPES.SAVE_CURRENT_EXERCISE,
    payload: {
      settingsOnly
    }
  });

  static createExerciseType = (data) => ({
    type: TYPES.CREATE_EXERCISE_TYPE,
    payload: {
      data,
    }
  });

  static startExercise = () => ({
    type: TYPES.START_EXERCISE,
    payload: {}
  });

  static exerciseFinished = (results, level) => ({
    type: TYPES.EXERCISE_FINISHED,
    payload: {
      results,
      level,
    }
  });
}

const TYPES = ExercisesActions.TYPES;