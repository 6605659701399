import React, {useEffect, useState} from 'react';
import {
  Alert,
  Intent,
} from "@blueprintjs/core";

import "./Messages.scss";
import FullScreenOverlay from "../../ui/FullScreenOverlay";

import CardHeading from "../../ui/text/CardHeading";
import ResponsiveCard from "../../ui/ResponsiveCard";
import TopIconButton from "../../ui/buttons/TopIconButton";
import useToggle from "../../lib/useToggle";
import {useDispatch, useSelector} from "react-redux";
import MessagesActions from "../../redux/actions/common/messages-actions";
import InlineableComponent from "../../ui/layout/InlineableComponent";
import Loader from "../../ui/Loader";

import {SendTextMessageForm, MessagesList, SendMultimediaMessageForm, MessageCard} from "./subcomponents";

export const TYPES = {
  MESSAGE: 'message',
  VOICE: 'voice',
  VIDEO: 'video',
  REPORT_SENT: 'sent',
  NEW_SESSION: 'new_session',
};

export const TYPE_ICONS = {
  [TYPES.MESSAGE]: "envelope",
  [TYPES.VOICE]: "headset",
  [TYPES.VIDEO]: "mobile-video",
  [TYPES.REPORT_SENT]: "document-share",
  [TYPES.NEW_SESSION]: "saved",
};

export const MESSAGE_TYPES = {
  TEXT: "TEXT",
  VOICE: "VOICE",
  VIDEO: "VIDEO",
};

export default function Messages({inline=false}) {
  const dispatch = useDispatch();
  const messages = useSelector(state => state.messages.list);
  const currentMessage = useSelector(state => state.messages.current.data);

  const [isMessageThreadOpen, setIsMessageThreadOpen] = useState(false);
  const [isThreadDeletionAlertOpen, setIsThreadDeletionAlertOpen] = useState(false);
  const [deletedThreadInfo, setDeletedThreadInfo] = useState({});
  const [messageType, setMessageType] = useState(MESSAGE_TYPES.TEXT);
  const [isMessageSendingOpen, toggleIsMessageSendingOpen] = useToggle(false);

  useEffect(() => {
    dispatch(MessagesActions.requestMessages());
  }, [dispatch]);

  const toggleMessageThread = () => {
    setIsMessageThreadOpen(!isMessageThreadOpen);
  };

  const toggleThreadDeletionAlert = () => {
    setIsThreadDeletionAlertOpen(!isThreadDeletionAlertOpen);
  };

  const openMessage$ = (messageId$) => () => {
    dispatch(MessagesActions.setCurrentMessageId(messageId$));
    toggleMessageThread();
  };

  const prepareMessageToDelete$ = (messageIndex$) => () => {
    setDeletedThreadInfo({
      id: messageIndex$,
      subject: messages[messageIndex$].subject,
      delete: deleteMessageThread$(messageIndex$),
    });

    toggleThreadDeletionAlert();
  };

  const deleteMessageThread$ = (messageId$) => () => {
    const messageId = messages[messageId$].id;
    dispatch(MessagesActions.deleteMessage(messageId));
  };

  const sendMessage = (type, content, trim, duration) => {
    let message = {
      recipient: 1,
    };

    switch (type) {
      case TYPES.VIDEO:
      case TYPES.VOICE: {
        if (trim === undefined) {
          trim = [
            0,
            duration,
          ]
        }

        message = {
          ...message,
          subject: `Wiadomość ${type === TYPES.VOICE ? "głosowa" : "wideo"} od pacjenta`,
          content: "",
          recording: {
            file: content,
            offset: trim[0],
            duration: trim[1] - trim[0],
          },
        };
        break;
      }

      default: {
        message = {
          ...message,
          subject: "Wiadomość od pacjenta",
          content
        };
        break;
      }
    }

    // let message = {
    //   type,
    //   content,
    //   trim,
    // };

    dispatch(MessagesActions.sendMessage(message));
    toggleIsMessageSendingOpen();
  };

  const startSendingMessage$ = (messageType$) => () => {
    setMessageType(messageType$);
    toggleIsMessageSendingOpen();
  };

  if (!messages) {
    return <InlineableComponent
      inline={inline}
    >
      <Loader
        text="Wczytuję wiadomości"
      />
    </InlineableComponent>
  }

  return <InlineableComponent className="Messages"
    inline={inline}
  >
    <section className="contact-section">
      <CardHeading>
        Wyślij wiadomość
      </CardHeading>
      <div className="send-actions">
        <TopIconButton
          icon="label"
          text="Wiadomość tekstowa"

          onClick={startSendingMessage$(MESSAGE_TYPES.TEXT)}
        />
        <TopIconButton
          icon="volume-down"
          text="Wiadomość głosowa"

          onClick={startSendingMessage$(MESSAGE_TYPES.VOICE)}
        />
        <TopIconButton
          icon="video"
          text="Wiadomość wideo"

          onClick={startSendingMessage$(MESSAGE_TYPES.VIDEO)}
        />
      </div>
    </section>

    <MessagesList
      messages={messages}

      openMessageThread$={openMessage$}
      deleteMessageThread$={prepareMessageToDelete$}
    />
    <Alert
      isOpen={isThreadDeletionAlertOpen}
      canEscapeKeyCancel={true}
      canOutsideClickCancel={true}

      intent={Intent.DANGER}
      icon={"trash"}

      cancelButtonText="Nie usuwaj"
      confirmButtonText="Usuń"

      onCancel={toggleThreadDeletionAlert}
      onConfirm={deletedThreadInfo.delete}
      onClose={toggleThreadDeletionAlert}
    >
      Czy na pewno chcesz usunąć wątek: "<strong>{deletedThreadInfo.subject}</strong>"?
    </Alert>
    <FullScreenOverlay
      isOpen={isMessageThreadOpen}
      onClose={toggleMessageThread}
    >
      <MessageCard
        message={currentMessage}
        close={toggleMessageThread}
      />
    </FullScreenOverlay>
    <FullScreenOverlay
      isOpen={isMessageSendingOpen}
      onClose={toggleIsMessageSendingOpen}
    >
      <ResponsiveCard
        size={ResponsiveCard.SIZES.md}
      >
        <CardHeading>Wysyłanie wiadomości</CardHeading>
        {messageType === MESSAGE_TYPES.TEXT &&
        <SendTextMessageForm
          sendMessage={sendMessage}
          close={toggleIsMessageSendingOpen}
        />
        }
        {messageType === MESSAGE_TYPES.VOICE &&
        <SendMultimediaMessageForm
          sendMessage={sendMessage}
          close={toggleIsMessageSendingOpen}
          video={false}
        />}
        {messageType === MESSAGE_TYPES.VIDEO &&
        <SendMultimediaMessageForm
          sendMessage={sendMessage}
          close={toggleIsMessageSendingOpen}
          video={true}
        />}
      </ResponsiveCard>
    </FullScreenOverlay>
  </InlineableComponent>
}
