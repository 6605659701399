function clone(d) {
  return new Date(d.getTime());
}

function createShortcut(label, dateRange) {
  return { dateRange, label, date: dateRange[0] };
}

export default function createDatePickerPolishShortcuts(
  allowSingleDayRange = true,
  hasTimePrecision = false,
  useSingleDateShortcuts = true,
  includeTomorrow = false,
) {
  const today = new Date();
  const makeDate = (action) => {
    const returnVal = clone(today);
    action(returnVal);
    returnVal.setDate(returnVal.getDate() + 1);
    return returnVal;
  };

  const dayAfterTomorrow = makeDate(d => d.setDate(d.getDate()));
  const tomorrow = makeDate(() => null);
  const yesterday = makeDate(d => d.setDate(d.getDate() - 2));
  const oneWeekAgo = makeDate(d => d.setDate(d.getDate() - 7));
  const oneMonthAgo = makeDate(d => d.setMonth(d.getMonth() - 1));
  const threeMonthsAgo = makeDate(d => d.setMonth(d.getMonth() - 3));
  const sixMonthsAgo = makeDate(d => d.setMonth(d.getMonth() - 6));
  const oneYearAgo = makeDate(d => d.setFullYear(d.getFullYear() - 1));
  const twoYearsAgo = makeDate(d => d.setFullYear(d.getFullYear() - 2));

  const singleDayShortcuts =
    allowSingleDayRange || useSingleDateShortcuts
      ?
      includeTomorrow ? [
          createShortcut("Jutro", [tomorrow, hasTimePrecision ? dayAfterTomorrow : tomorrow]),
          createShortcut("Dzisiaj", [today, hasTimePrecision ? tomorrow : today]),
          createShortcut("Wczoraj", [yesterday, hasTimePrecision ? today : yesterday]),
        ]
        : [
          createShortcut("Dzisiaj", [today, hasTimePrecision ? tomorrow : today]),
          createShortcut("Wczoraj", [yesterday, hasTimePrecision ? today : yesterday]),
        ]
      : [];

  return [
    ...singleDayShortcuts,
    createShortcut(useSingleDateShortcuts ? "Tydzień temu" : "Poprzedni tydzień", [oneWeekAgo, today]),
    createShortcut(useSingleDateShortcuts ? "Miesiąc temu" : "Poprzedni miesiąc", [oneMonthAgo, today]),
    createShortcut(useSingleDateShortcuts ? "3 miesiące temu" : "Poprzednie 3 miesiące", [threeMonthsAgo, today]),
    // Don't include a couple of these for the single date shortcut
    ...(useSingleDateShortcuts ? [] : [createShortcut("Poprzednie 6 miesięcy", [sixMonthsAgo, today])]),
    createShortcut(useSingleDateShortcuts ? "Rok temu" : "Poprzedni rok", [oneYearAgo, today]),
    ...(useSingleDateShortcuts ? [] : [createShortcut("Poprzednie 2 lata", [twoYearsAgo, today])]),
  ];
}