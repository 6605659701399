import React, {useEffect} from 'react';

import "./Session.scss"
import {useParams, withRouter} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import SessionsActions from "../../redux/actions/common/sessions-actions";
import ResponsiveCard from "../../ui/ResponsiveCard";
import Loader from "../../ui/Loader";

function Session() {
  const dispatch = useDispatch();
  const session = useSelector(state => state.sessions.current.data);
  const sessions = useSelector(state => state.sessions.list);
  const {sessionId} = useParams();

  useEffect(() => {
    if (sessions === undefined) {
      dispatch(SessionsActions.requestSessions());
    } else {
      dispatch(SessionsActions.setCurrentSessionId(sessionId));
    }
  }, [dispatch, sessionId, sessions]);

  if (!session.id) {
    return <Loader
      inline={false}
      text="Wczytuję informację o sesji"
    />
  }

  return <ResponsiveCard size={ResponsiveCard.SIZES.md}>
    <p>
      {session.name}
    </p>
  </ResponsiveCard>;
}

export default withRouter(Session);