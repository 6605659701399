import {Button, NumericInput} from "@blueprintjs/core";
import React, {useCallback, useEffect} from "react";
import classNames from "classnames";
import {defaultTo, difference} from "lodash"

import LeftHandIcon from "images/icons/left-hand.svg";
import RightHandIcon from "images/icons/right-hand.svg";

import TopIconButton from "../../../ui/buttons/TopIconButton";
import {useDispatch, useSelector} from "react-redux";
import ExercisesActions from "../../../redux/actions/common/exercises-actions";

import "./ExerciseParameters.scss";
import ExerciseTitle from "../subcomponents/ExerciseTitle";

export default function ExerciseParameters({
  exercise,

  startExercise,
  cancel
}) {
  const dispatch = useDispatch();
  const exerciseParameters = useSelector(state => state.exercises.current.data.patient_settings);

  const setCurrentExerciseParametersValueByKey = useCallback((key, value) => dispatch(ExercisesActions.updateCurrentExercise({
    patient_settings: {
      ...exerciseParameters,
      [key]: value,
    }
  })), [exerciseParameters, dispatch]);

  useEffect(() => {
    if (exerciseParameters["duration"] === undefined) {
      setCurrentExerciseParametersValueByKey("duration", 3);
    }
  }, [setCurrentExerciseParametersValueByKey, exerciseParameters]);

  const toggleHand = (hand) => {
    let handParameters = defaultTo(exerciseParameters["hand"], []);
    if (handParameters.includes(hand)) {
      handParameters = difference(handParameters, [hand]);
    } else {
      handParameters.push(hand);
    }

    setCurrentExerciseParametersValueByKey("hand", handParameters);
  };

  const toggleLeftHand = () => toggleHand("left");
  const toggleRightHand = () => toggleHand("right");

  return <div className="ExerciseParameters">
    <section className="exercise-info-header">
      <ExerciseTitle
        name={exercise.data.name}
        icon={exercise.data.icon}
        categories={exercise.data.categories}
      />
    </section>
    <section className="parameters">
      <div className="hand">
        <TopIconButton className={classNames({
          "selected": defaultTo(exerciseParameters["hand"], []).includes("left")
        })}
          text="Lewą ręką"
          icon={LeftHandIcon}

          onClick={toggleLeftHand}
        />
        <TopIconButton className={classNames({
          "selected": defaultTo(exerciseParameters["hand"], []).includes("right")
        })}
          text="Prawą ręką"
          icon={RightHandIcon}

          onClick={toggleRightHand}
        />
      </div>
      <div className="time">
        <NumericInput
          large={true}

          min={1}
          max={15}
          value={defaultTo(exerciseParameters["duration"], 3)}
          onValueChange={value => setCurrentExerciseParametersValueByKey("duration", value)}
        />
      </div>
    </section>
    <section className="checkboxes">

    </section>
    <section className="start-exercise">
      <Button variant="secondary btn-block"
        onClick={cancel}
      >
        Anuluj
      </Button>
      <Button variant="primary btn-block"
        onClick={startExercise}
      >
        Rozpocznij
      </Button>
    </section>
  </div>
}
