import SessionsActions from "redux/actions/common/sessions-actions";
import groupByKey from "lib/groupByKey";

const initialState = {
  current: {
    id: undefined,
    data: {},
  },

  list: undefined,
  byId: undefined,
};

const TYPES = SessionsActions.TYPES;

export default function sessionsReducer(state = initialState, action = {}) {
  const {type, payload} = action;

  switch(type) {
    case TYPES.SESSIONS_CHANGED: {
      const {sessionsList} = payload;
      const byId = groupByKey(sessionsList, "id");

      return {
        ...state,
        list: sessionsList,
        byId,
      };
    }

    case TYPES.CREATE_EMPTY_CURRENT_SESSION: {
      return {
        ...state,
        current: {
          ...state.current,
          id: undefined,
          data: {
            exercises: []
          },
        }
      };
    }

    case TYPES.SET_CURRENT_SESSION_ID: {
      const {sessionId} = payload;
      const currentSession = state.byId[sessionId];

      if (!currentSession) {
        return {
          ...state,
        }
      } else {
        return {
          ...state,
          current: {
            id: sessionId,
            data: {...currentSession},
          }
        }
      }
    }

    case TYPES.UPDATE_CURRENT_SESSION: {
      const {newData} = payload;

      return {
        ...state,
        current: {
          ...state.current,
          data: {
            ...state.current.data,
            ...newData
          }
        }
      }
    }

    default:
      return state
  }
}