import PROFILE_DATA from "test/data/common/profile-data";
import simulateRequestWithResolve from "../simulateRequestWithResolve";

const PROFILE_MOCK_API = {
  async requestProfileInfo(email) {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve(PROFILE_DATA[email]);
      }, Math.random() * 1000 + 500);
    });
  },

  async saveProfileInfo(data) {
    return new Promise((resolve) => {
      PROFILE_DATA[data['email']] = data;
      simulateRequestWithResolve(resolve, true);
    });
  }
};

export default PROFILE_MOCK_API;