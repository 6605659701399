export default class ServicesActions {
  static TYPES = {
    REQUEST_SERVICES: "REQUEST_SERVICES",
    SERVICES_CHANGED: "SERVICES_CHANGED",

    REQUEST_PAYMENTS: "REQUEST_PAYMENTS",
    PAYMENTS_CHANGED: "PAYMENTS_CHANGED",
  };

  static requestServices = () => ({
    type: TYPES.REQUEST_SERVICES,
    payload: {}
  });

  static servicesChanged = (list) => ({
    type: TYPES.SERVICES_CHANGED,
    payload: {
      list
    }
  });

  static requestPayments = () => ({
    type: TYPES.REQUEST_PAYMENTS,
    payload: {}
  });

  static paymentsChanged = (list) => ({
    type: TYPES.PAYMENTS_CHANGED,
    payload: {
      list
    }
  });
}

const TYPES = ServicesActions.TYPES;