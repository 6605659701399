import React, {useState} from "react";
import {Button, ButtonGroup, FormGroup, Intent, TextArea} from "@blueprintjs/core";

import {TYPES} from "../Messages";

export default function SendTextMessageForm({sendMessage, close}) {
  const [messageContent, setMessageContent] = useState("");

  const innerSendMessage = () => {
    sendMessage(
      TYPES.MESSAGE,
      messageContent
    );
    setMessageContent("");
  };

  return <div className="SendTextMessageForm">
    <FormGroup
      label="Treść wiadomości"
      labelFor="send-message-content"
    >
      <TextArea
        id="send-message-content"
        large={true}

        growVertically={true}

        value={messageContent}
        onChange={(e) => setMessageContent(e.target.value)}
      />
    </FormGroup>
    <div className="send-button-container">
      <ButtonGroup>
        <Button
          text="Zamknij"
          large={true}
          icon="cross"

          onClick={close}
        />
        <Button
          disabled={!messageContent}
          text="Wyślij"
          large={true}
          intent={Intent.PRIMARY}
          icon="envelope"

          onClick={innerSendMessage}
        />
      </ButtonGroup>
    </div>
  </div>
}