export default class SessionsActions {
  static TYPES = {
    REQUEST_SESSIONS: "REQUEST_SESSIONS",
    SESSIONS_CHANGED: "SESSIONS_CHANGED",
    SET_CURRENT_SESSION_ID: "SET_CURRENT_SESSION_ID",
    CREATE_EMPTY_CURRENT_SESSION: "CREATE_EMPTY_CURRENT_SESSION",
    ADD_CURRENT_SESSION: "ADD_CURRENT_SESSION",
    UPDATE_CURRENT_SESSION: "UPDATE_CURRENT_SESSION",
    SAVE_CURRENT_SESSION: "SAVE_CURRENT_SESSION",
  };

  static requestSessions = () => ({
    type: TYPES.REQUEST_SESSIONS,
    payload: {}
  });

  static sessionsChanged = (sessionsList) => ({
    type: TYPES.SESSIONS_CHANGED,
    payload: {
      sessionsList
    }
  });

  static setCurrentSessionId = (sessionId) => ({
    type: TYPES.SET_CURRENT_SESSION_ID,
    payload: {
      sessionId,
    }
  });

  static createEmptyCurrentSession = () => ({
    type: TYPES.CREATE_EMPTY_CURRENT_SESSION,
    payload: {}
  });

  static updateCurrentSession = (newData) => ({
    type: TYPES.UPDATE_CURRENT_SESSION,
    payload: {
      newData,
    }
  });

  static addCurrentSession = () => ({
    type: TYPES.ADD_CURRENT_SESSION,
    payload: {}
  });

  static saveCurrentSession = () => ({
    type: TYPES.SAVE_CURRENT_SESSION,
    payload: {}
  });
}

const TYPES = SessionsActions.TYPES;