export default class ProcessingActions {
  static TYPES = {
    START_PROCESSING: "START_PROCESSING",
    STOP_PROCESSING: "STOP_PROCESSING",
  };

  static startProcessing = (processingType) => ({
    type: TYPES.START_PROCESSING,
    payload: {
      processingType,
    }
  });

  static stopProcessing = (processingType, message, exitId) => ({
    type: TYPES.STOP_PROCESSING,
    payload: {
      processingType,
      message,
      exitId,
    }
  });
}

const TYPES = ProcessingActions.TYPES;