import REPORT_DATA from "test/data/common/report-data";
import simulateRequestWithResolve from "test/mock-api/simulateRequestWithResolve";

const REPORT_MOCK_API = {
  async requestReport(startDate, endDate) {
    return new Promise((resolve) => {
      let data;
      if (startDate !== undefined && endDate !== undefined) {
        data = {
          durations: [],
          exercises: {},
        };

        for (let duration of REPORT_DATA.REPORT.durations) {
          if (startDate < duration.date && duration.date < endDate) {
            data.durations.push(duration);
          }
        }

        for (let [exerciseId, exerciseInfo] of Object.entries(REPORT_DATA.REPORT.exercises)) {
          let initialized = false;
          for (let stat of exerciseInfo.stats) {
            if (startDate < stat.date && stat.date < endDate) {
              if (!initialized) {
                data.exercises[exerciseId] = {
                  ...exerciseInfo
                };

                data.exercises[exerciseId]['stats'] = [];
                initialized = true;
              }

              data.exercises[exerciseId]['stats'].push(stat);
            }
          }
        }
      } else {
        data = REPORT_DATA.REPORT;
      }

      simulateRequestWithResolve(resolve, data);
    });
  }
};

export default REPORT_MOCK_API;