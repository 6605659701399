import {Colors} from "@blueprintjs/core";
import loremGenerator from "../../loremGenerator";
import {ACTIVITY_TYPES} from "common";

const PATIENTS_DATA = {
  PATIENTS_LIST: {
    1: {
      id: 1,
      name: "Bożena Stokowa",
      avatar: {
        image: false,
        color: Colors.BLUE3,
        letters: "BS",
      },
      telenf: true,
      lastActivityDate: new Date(2020, 1, 8, 9, 13),
      lastActivityType: ACTIVITY_TYPES.EXERCISE,
      description: loremGenerator.generateParagraphs(2),
    },
    2: {
      id: 2,
      name: "Jadwiga Szymczak",
      avatar: {
        image: false,
        color: Colors.COBALT3,
        letters: "JS",
      },
      telenf: true,
      lastActivityDate: new Date(2020, 1, 8, 9, 13),
      lastActivityType: ACTIVITY_TYPES.EXERCISE,
      description: loremGenerator.generateParagraphs(2),

    },
    3: {
      id: 3,
      name: "Radosław Brodawski",
      avatar: {
        image: false,
        color: Colors.RED3,
        letters: "RB",
      },
      telenf: false,
      lastActivityDate: new Date(2020, 1, 8, 9, 13),
      lastActivityType: ACTIVITY_TYPES.MESSAGE,
      description: loremGenerator.generateParagraphs(2),

    },
    4: {
      id: 4,
      name: "Jerzy Kulkowski",
      avatar: {
        image: false,
        color: Colors.GREEN3,
        letters: "JK",
      },
      telenf: false,
      lastActivityDate: new Date(2020, 1, 8, 9, 13),
      lastActivityType: ACTIVITY_TYPES.MESSAGE,
      description: loremGenerator.generateParagraphs(2),
    },
  }
};

export default PATIENTS_DATA;