import React from 'react';
import ResponsiveCard from "../ResponsiveCard";

export default function InlineableComponent({className, inline, children}) {
  if (inline) {
    return <div className={className}>
      {children}
    </div>
  } else {
    return <ResponsiveCard className={className}
      size={ResponsiveCard.SIZES.md}
    >
      {children}
    </ResponsiveCard>
  }
}