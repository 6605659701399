import EXERCISES_MOCK_API from "test/mock-api/common/exercises-mock-api";
import { EXERCISE_API } from "api/common";
import {put, all, takeEvery, select} from "redux-saga/effects";
import ExercisesActions from "redux/actions/common/exercises-actions";
import ProcessingActions from "../../actions/common/processing-actions";

class ExercisesSagas {
  static* updateExercises() {
    const exercisesList = yield EXERCISE_API.requestExercisesList();
    yield put(ExercisesActions.exercisesChanged(exercisesList));
  }

  static* watchUpdateExercises() {
    yield takeEvery(ExercisesActions.TYPES.REQUEST_EXERCISES, ExercisesSagas.updateExercises);
  }

  static* updateExerciseCategories() {
    const exerciseCategoriesList = yield EXERCISE_API.requestExerciseCategoriesList();
    yield put(ExercisesActions.exerciseCategoriesChanged(exerciseCategoriesList));
  }

  static* watchUpdateExerciseCategories() {
    yield takeEvery(ExercisesActions.TYPES.UPDATE_EXERCISE_CATEGORIES, ExercisesSagas.updateExerciseCategories);
  }

  static* saveCurrentExercise(action) {
    const {settingsOnly} = action;

    yield put(ProcessingActions.startProcessing("SAVE_EXERCISE"));
    const userId = yield select(state => state.profile.current.id);
    const currentId = yield select(state => state.exercises.current.id);
    const currentData = yield select(state => state.exercises.current.data);

    let wasExerciseUpdated;
    if (settingsOnly) {
      wasExerciseUpdated = yield EXERCISE_API.updateExercise(currentId, currentData);
    } else {
      wasExerciseUpdated = yield EXERCISE_API.updateExerciseSettings(userId, currentId, currentData);
    }

    yield put(ProcessingActions.stopProcessing("SAVE_EXERCISE"));
    if (wasExerciseUpdated) {
      const exerciseCategoriesList = yield EXERCISE_API.requestExercisesList();
      yield put(ExercisesActions.exercisesChanged(exerciseCategoriesList));
    }
  }

  static* watchSaveCurrentExercise() {
    yield takeEvery(ExercisesActions.TYPES.SAVE_CURRENT_EXERCISE, ExercisesSagas.saveCurrentExercise);
  }

  static* createExerciseType(action) {
    yield put(ProcessingActions.startProcessing("CREATE_EXERCISE"));
    const {payload} = action;
    const {data} = payload;

    const wasExerciseCreated = yield EXERCISES_MOCK_API.createExercise(data);
    yield put(ProcessingActions.stopProcessing("CREATE_EXERCISE"));
    if (wasExerciseCreated) {
      yield ExercisesSagas.updateExercises();
    }
  }

  static* watchCreateExerciseType() {
    yield takeEvery(ExercisesActions.TYPES.CREATE_EXERCISE_TYPE, ExercisesSagas.createExerciseType);
  }

  static* exerciseFinished(action) {
    const {payload} = action;
    const {level} = payload;
    // const {results, level} = payload;

    const patientId = yield select(state => state.profile.current.id);
    const exerciseId = yield select(state => state.exercises.current.id);
    let parameters = {};
    parameters["patient_settings"] = yield select(state => state.exercises.current.data.patient_settings);
    parameters["patient_settings"]["level"] = level;

    let wasExerciseUpdated = yield EXERCISE_API.updateExerciseSettings(
      patientId,
      exerciseId,
      parameters
    );
    // TODO: Update statistics

    if (wasExerciseUpdated) {
      const exerciseList = yield EXERCISE_API.requestExercisesList();
      yield put(ExercisesActions.exercisesChanged(exerciseList));
    }
  }

  static* watchExerciseFinished() {
    yield takeEvery(ExercisesActions.TYPES.EXERCISE_FINISHED, ExercisesSagas.exerciseFinished);
  }
}

export default function* exercisesSaga() {
  yield all([
    ExercisesSagas.watchUpdateExercises(),
    ExercisesSagas.watchUpdateExerciseCategories(),
    ExercisesSagas.watchSaveCurrentExercise(),
    ExercisesSagas.watchCreateExerciseType(),
    ExercisesSagas.watchExerciseFinished(),
  ])
}