import React, {useEffect, useRef} from "react";
import 'video.js/dist/video-js.css';
import videojs from 'video.js';

import {TYPES} from "../Messages";

const messageVideoJsOptions = {
  controls: true,
  fluid: true,
  inactivityTimeout: 100,
  audioEngine: "opus-recorder",
  plugins: {}
};

let messagePlayer;

export default function Message({
  avatar,
  date,
  name,
  content,
  video,
  type,

  close,
}) {
  const videoRef = useRef(null);
  const isMultimedia = (type === TYPES.VOICE) || (type === TYPES.VIDEO);
  let multimediaContent, multimediaType;

  if (isMultimedia) {
    multimediaContent = URL.createObjectURL(content);
    multimediaType = content.type;
  }

  useEffect(() => {
    if (isMultimedia) {
      messageVideoJsOptions["sources"] = [
        {
          src: multimediaContent,
          type: multimediaType,
        }
      ];

      messagePlayer = videojs(videoRef.current, messageVideoJsOptions);

      // error handling
      messagePlayer.on('error', (element, error) => {
        console.warn(error);
      });

      messagePlayer.on('deviceError', () => {
        console.error('device error:', messagePlayer.deviceErrorCode);
      });
    }

    // destroy player on unmount
    return () => {
      if (messagePlayer) {
        messagePlayer.dispose();
      }
    }
  }, [isMultimedia, multimediaContent, multimediaType]);

  return <div className="Message">
    <div className="message-content">
      <div className="user-info">
        <img alt={name} src={avatar} />
        <p>{name}</p>
      </div>
      <div className="content">
        <p className="date">Wysłana dnia: {date}</p>
        {!isMultimedia &&
        <p className="message-content">{content}</p>
        }
        {isMultimedia &&
        <div data-vjs-player>
          <video id="myVideo" ref={videoRef} className="video-js vjs-default-skin" playsInline={true}/>
        </div>
        }
      </div>
    </div>
  </div>
}