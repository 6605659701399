import {useDispatch, useSelector} from "react-redux";
import React, {useEffect, useState} from "react";
import AuthActions from "redux/actions/common/auth-actions";
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
import GoogleLogin from "react-google-login";
import {FACEBOOK_APP_ID, GOOGLE_CLIENT_ID} from ".";
import {Card, Form, FormControl, FormGroup, FormLabel, Button} from "react-bootstrap";
import validate from "validate.js";
import useErrors from "lib/hooks/useErrors";
import FormError from "./subcomponents/FormError";
import {Link, Redirect} from "react-router-dom";
import AuthBase from ".";
import {loginFacebookWithDipsatch$, loginGoogleWithDipsatch$} from "./helpers/social";

import fbLogo from "./images/facebook.png";
import gLogo from "./images/google.png";

const CONSTRAINTS = {
  "email": {
    presence: {
      allowEmpty: false,
      message: "Podaj swój adres e-mail"
    },
    email: {
      message: "Podaj prawidłowy adres e-mail"
    },
  },
  "password": {
    presence: {
      allowEmpty: false,
      message: "Wpisz swoje hasło",
    },
  },
}

export default function Login({isTherapist}) {
  const dispatch = useDispatch();
  const isTryingToLogIn = useSelector(state => state.auth.isProcessing);
  const errors = useSelector(state => state.auth.errors);
  const isUserLoggedIn = useSelector(state => state.auth.isUserLoggedIn);
  const [data, setData] = useState({
    email: "",
    password: "",
  })
  const [processedErrors, setProcessedErrors] = useErrors({}, ['email', 'password', 'social']);

  const responseFacebook = loginFacebookWithDipsatch$(dispatch);
  const responseGoogle = loginGoogleWithDipsatch$(dispatch);

  const failureGoogle = (error) => {
    console.error("Google login error");
  };

  const setDataForKey = (key, value) => {
    setData({
      ...data,
      [key]: value,
    });
  }

  useEffect(() => {
    setProcessedErrors(errors);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errors]);

  const login = () => {
    const validationErrors = validate(data, CONSTRAINTS, {fullMessages: false});
    if (validationErrors === undefined) {
      setProcessedErrors({});
      dispatch(AuthActions.tryLoggingIn(
        data["email"],
        data["password"],
      ));
    } else {
      setProcessedErrors(validationErrors);
    }
  };

  if (isUserLoggedIn) {
    return <Redirect to="/" />
  }

  return <AuthBase>
    <Card className="mb-0 px-2">
      <Card.Header className="pb-1">
        <Card.Title className="w-100">
          <h1 className="mb-0 text-center text-primary">Zaloguj się</h1>
        </Card.Title>
      </Card.Header>
      <p className="px-2">Witamy z powrotem, zaloguj się do swojego konta.</p>
      <div className="card-content">
        <div className="card-body">
          <Form className="form-vertical">
            <div className="form-body">
              <FormGroup >
                <FormLabel htmlFor="email-id-vertical">E-mail</FormLabel>
                <FormControl
                  type="email"
                  name="email"

                  value={data["email"]}
                  onChange={e => setDataForKey("email", e.target.value)}
                  isInvalid={processedErrors["email"] !== undefined}
                />
                <FormError errors={processedErrors} errorKey={"email"} />
              </FormGroup>
              <div className="form-group">
                <label htmlFor="password-vertical">Hasło</label>
                <FormControl
                  type="password"
                  name="password"

                  value={data["password"]}
                  onChange={e => setDataForKey("password", e.target.value)}
                  isInvalid={processedErrors["password"] !== undefined}
                />
                <FormError errors={processedErrors} errorKey={"password"} />
              </div>
              <div className="d-flex justify-content-between align-items-center mb-1">
                <div className="d-inline-block">
                <FormGroup className="mb-0">
                  <fieldset className="checkbox">
                    <div className="vs-checkbox-con vs-checkbox-primary">
                      <input type="checkbox"/>
                      <span className="vs-checkbox">
                          <span className="vs-checkbox--check">
                            {/*<Check className="vs-icon" />*/}
                            <i className="vs-icon feather icon-check" />
                          </span>
                      </span>
                      <span>Zapamiętaj mnie</span>
                    </div>
                  </fieldset>
                </FormGroup>
                </div>
                <div className="d-inline-block">
                  <a href="/test">Zapomniałeś hasła?</a>
                </div>
              </div>
                <FormError errors={processedErrors} errorKey={"other"} />
                <Button
                  className="btn-block"
                  onClick={(e) => {
                    e.preventDefault();
                    login();
                  }}
                  disabled={isTryingToLogIn}

                  type="submit"
                >
                  {isTryingToLogIn ? <><div className="spinner-border spinner-border-sm mr-1" /> Trwa logowanie</> : <>Zaloguj</>}
                </Button>

                <p className="align-items-center text-muted text-center mt-1">
                  Logując się, akceptujesz <a href="/usage">Warunki użytkowania</a> i <a href="/privacy">Politykę prywatności</a>.
                </p>
              </div>
          </Form>
          <hr className="mt-3 mb-3" />
          <FormError errors={processedErrors} errorKey={"social"} />
          <div className="d-flex justify-content-between align-items-center">
            <GoogleLogin
              clientId={GOOGLE_CLIENT_ID}
              onSuccess={responseGoogle}
              onFailure={failureGoogle}
              cookiePolicy={'single_host_origin'}

              render={renderProps => (
                <button className="btn btn-block btn-outline-primary mr-1 social-login google" onClick={renderProps.onClick} disabled={renderProps.disabled}>
                  <img src={gLogo} alt="Google" /> Zaloguj z Google
                </button>
              )}
            />
            <FacebookLogin
              appId={FACEBOOK_APP_ID}
              fields="name,email,picture"
              callback={responseFacebook}

              render={renderProps => (
                <button className="btn btn-block btn-primary mt-0 social-login facebook" onClick={renderProps.onClick} disabled={renderProps.disabled}>
                  <img src={fbLogo} alt="Facebook" /> Zaloguj z Facebook
                </button>
              )}
            />
          </div>
          <p className="text-center mt-1">
            Jeśli nie posiadasz jeszcze konta, <Link to={`/register/`}>zarejestruj się</Link>.
          </p>
        </div>
      </div>
    </Card>
  </AuthBase>;
}