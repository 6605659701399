import REPORT_MOCK_API from "test/mock-api/common/report-mock-api";
import {put, all, takeEvery} from "redux-saga/effects";
import ReportActions from "redux/actions/common/report-actions";
import ProcessingActions from "../../actions/common/processing-actions";

const PROCESSING_TYPES = {
  REQUESTING_REPORT: "REQUESTING_REPORT",
};

class ReportSagas {
  static* requestReport(action) {
    const {startDate, endDate} = action.payload;

    yield put(ProcessingActions.startProcessing(PROCESSING_TYPES.REQUESTING_REPORT));
    const report = yield REPORT_MOCK_API.requestReport(startDate, endDate);
    yield put(ReportActions.reportChanged(report));
    yield put(ProcessingActions.stopProcessing(PROCESSING_TYPES.REQUESTING_REPORT));
  }

  static* watchRequestReport() {
    yield takeEvery(ReportActions.TYPES.REQUEST_REPORT, ReportSagas.requestReport);
  }
}

export default function* reportSaga() {
  yield all([
    ReportSagas.watchRequestReport(),
  ])
}