function _extends() { _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }

function _objectWithoutProperties(source, excluded) { if (source == null) return {}; var target = _objectWithoutPropertiesLoose(source, excluded); var key, i; if (Object.getOwnPropertySymbols) { var sourceSymbolKeys = Object.getOwnPropertySymbols(source); for (i = 0; i < sourceSymbolKeys.length; i++) { key = sourceSymbolKeys[i]; if (excluded.indexOf(key) >= 0) continue; if (!Object.prototype.propertyIsEnumerable.call(source, key)) continue; target[key] = source[key]; } } return target; }

function _objectWithoutPropertiesLoose(source, excluded) { if (source == null) return {}; var target = {}; var sourceKeys = Object.keys(source); var key, i; for (i = 0; i < sourceKeys.length; i++) { key = sourceKeys[i]; if (excluded.indexOf(key) >= 0) continue; target[key] = source[key]; } return target; }

import React from "react";

var SvgBoxing = function SvgBoxing(_ref) {
  var svgRef = _ref.svgRef,
      title = _ref.title,
      props = _objectWithoutProperties(_ref, ["svgRef", "title"]);

  return React.createElement("svg", _extends({
    width: "100%",
    height: "100%",
    viewBox: "0 0 492 492",
    xmlSpace: "preserve",
    style: {
      fillRule: "evenodd",
      clipRule: "evenodd",
      strokeLinejoin: "round",
      strokeMiterlimit: 2
    },
    ref: svgRef
  }, props), title ? React.createElement("title", null, title) : null, React.createElement("path", {
    d: "M275.505,61.44C317.145,61.44 327.353,80.747 327.353,112.038C327.353,123.84 325.717,137.35 323.909,152.269C322.161,166.697 320.249,182.474 319.57,198.698C324.875,199.576 333.8,193.158 342.924,186.596C348.79,182.377 354.721,178.111 360.773,175.019C375.51,167.488 389.258,166.782 399.447,186.724C402.408,192.519 403.992,198.6 404.397,204.865C405.98,229.333 389.348,256.564 367.513,280.014C346.362,302.728 320.059,322.227 301.296,331.815C296.609,334.21 292.272,336.038 288.449,337.213L288.449,399.364C288.449,403.699 284.935,407.213 280.6,407.213L141.264,407.213C136.929,407.213 133.414,403.699 133.414,399.364L133.414,338.901C118.273,326.693 107.37,311.527 99.914,292.607C92.166,272.945 88.214,249.255 87.122,220.582C87.053,218.756 87.018,217.388 87.018,216.464C87.018,173.004 113.014,134.829 149.055,107.235C184.528,80.076 230.005,63.088 269.745,61.576C272.123,61.486 274.046,61.44 275.505,61.44L275.505,61.44Z",
    style: {
      fill: "rgb(66,66,66)",
      fillRule: "nonzero"
    }
  }), React.createElement("path", {
    d: "M138.609,414.381C134.274,414.381 130.76,417.895 130.76,422.23C130.76,426.565 134.274,430.08 138.609,430.08L283.254,430.08C287.589,430.08 291.103,426.565 291.103,422.23C291.103,417.895 287.589,414.381 283.254,414.381L138.609,414.381Z",
    style: {
      fill: "rgb(0,134,214)",
      fillRule: "nonzero"
    }
  }), React.createElement("rect", {
    x: 0,
    y: 0,
    width: 491.52,
    height: 491.52,
    style: {
      fill: "none"
    }
  }), React.createElement("rect", {
    x: 61.44,
    y: 61.44,
    width: 368.64,
    height: 368.64,
    style: {
      fill: "none"
    }
  }));
};

var ForwardRef = React.forwardRef(function (props, ref) {
  return React.createElement(SvgBoxing, _extends({
    svgRef: ref
  }, props));
});
export default __webpack_public_path__ + "static/media/boxing.7b56bbab.svg";
export { ForwardRef as ReactComponent };