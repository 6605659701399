import React from 'react';
import {Redirect, Route} from "react-router-dom";

import Portal from "../Portal";
import ExerciseList from "views/ExerciseList";
import SessionList from "views/SessionList";
import Messages from "views/Messages";
import EditProfile from "views/EditProfile";
import AccountServices from "views/AccountServices";
import Settings from "views/Settings";
import Reminders from "views/patient/Reminders";
import Reports from "views/Reports";
import Session from "views/Session/Session";

import './PatientPortal.scss';

function PatientPortal() {
  const routes = <>
    <Route exact path="/">
      <Redirect to="/exercises/" />
    </Route>
    <Route exact path="/exercises">
      <Redirect to="/exercises/list" />
    </Route>
    <Route path="/exercises/list">
      <ExerciseList />
    </Route>
    <Route path="/sessions/">
      <SessionList />
    </Route>
    <Route path="/tele-nf/">
      <Messages />
    </Route>
    <Route path="/reminders/">
      <Reminders />
    </Route>
    <Route path="/reports/">
      <Reports />
    </Route>
    <Route path="/edit-profile/">
      <EditProfile />
    </Route>
    <Route path="/account-services/">
      <AccountServices />
    </Route>
    <Route path="/other-settings/">
      <Settings />
    </Route>
    <Route path="/session/:sessionId">
      <Session />
    </Route>
  </>;

  const menuItems = [
    {
      link: "/exercises/",
      text: "Ćwiczenia",
    },
    {
      link: "/sessions/",
      text: "Sesje",
    },
    {
      link: "/tele-nf/",
      text: "Teleopieka",
    },
    {
      link: "/reminders/",
      text: "Przypomnienia",
    },
    {
      link: "/reports/",
      text: "Rezultaty",
    },
  ];

  const settingsActions = [
    {
      icon: "user",
      text: "Edycja profilu",
      link: "/edit-profile/",
    },
    {
      icon: "bank-account",
      text: "Usługi i płatności",
      link: "/account-services/",
    },
    {
      icon: "settings",
      text: "Pozostałe ustawienia",
      link: "/other-settings/",
    },
    {
      separator: true,
    },
    {
      icon: "log-out",
      text: "Wyloguj",
      link: "/logout/",
    },
  ];

  return <Portal className="PatientPortal"
    isTherapist={false}

    subtitle="Strefa pacjenta"
    routeComponents={routes}

    menuItems={menuItems}
    settingsActions={settingsActions}
  />;
}

export default PatientPortal;
