import PATIENTS_DATA from "../../data/therapist/patients-data";

const PATIENTS_MOCK_API = {
  async requestPatientList() {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve(Object.values(PATIENTS_DATA.PATIENTS_LIST));
      }, Math.random() * 1000 + 500);
    });
  }
};

export default PATIENTS_MOCK_API;