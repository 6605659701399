export default class TasksActions {
  static TYPES = {
    REQUEST_TASKS: "REQUEST_TASKS",
    TASKS_CHANGED: "TASKS_CHANGED",
    SELECT_TASK: "SELECT_TASK",
  };

  static requestTasks = () => ({
    type: TYPES.REQUEST_TASKS,
    payload: {},
  });

  static tasksChanged = (tasks) => ({
    type: TYPES.TASKS_CHANGED,
    payload: {
      tasks,
    }
  });

  static selectTask = (id) => ({
    type: TYPES.SELECT_TASK,
    payload: {
      id,
    }
  });
}

const TYPES = TasksActions.TYPES;