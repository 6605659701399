const URLS = {
  exercises: {
    name: "Ćwicz",
    urls: {
      list: {
        name: "Ćwiczenia",
        urls: {},
      }
    }
  }
}

export default URLS;