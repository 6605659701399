import {TASK_TYPES} from "redux/reducers/therapist/tasks-reducer";



const d1 = new Date();
d1.setDate(d1.getDate() - 8);
const d2 = new Date();
d2.setDate(d2.getDate() - 3);
const d3 = new Date();
d3.setDate(d3.getDate() + 1);
const d4 = new Date();
d4.setDate(d4.getDate() + 4);
const d5 = new Date();
d5.setDate(d5.getDate() + 6);

const TASKS_DATA = {
  TASKS: {
    1: {
      id: 1,
      type: TASK_TYPES.NEW_SESSION,
      name: "Utwórz nową sesję dla klienta Bożena Stokowa",
      dueDate: d1,
      patient: {
        id: 1,
        name: "Bożena Stokowa",
      },
    },
    2: {
      id: 2,
      type: TASK_TYPES.NOT_EXERCISING,
      name: "Zmotywuj klienta Jadwiga Szymczak do ćwiczeń",
      dueDate: d2,
      patient: {
        id: 2,
        name: "Jadwiga Szymczak",
      },
    },
    3: {
      id: 3,
      type: TASK_TYPES.REPORT,
      name: "Napisz raport z ćwiczeń użytkownika Bożena Stokowa",
      dueDate: d3,
      patient: {
        id: 1,
        name: "Bożena Stokowa",
      },
    },
    4: {
      id: 4,
      type: TASK_TYPES.REPORT,
      name: "Napisz raport z ćwiczeń użytkownika Radosław Brodawski",
      dueDate: d4,
      patient: {
        id: 3,
        name: "Radosław Brodawski",
      },
    },
    5: {
      id: 5,
      type: TASK_TYPES.NEW_SESSION,
      name: "Utwórz nową sesję dla klienta Jadwiga Szymczak",
      dueDate: d5,
      patient: {
        id: 2,
        name: "Jadwiga Szymczak",
      },
    },
  }
};

export default TASKS_DATA;