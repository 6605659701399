import React from 'react';
import {BrowserRouter, Route, Switch, withRouter} from "react-router-dom";

import PatientPortal from "./subApps/patient/PatientPortal";
import TopIconButton from "../ui/buttons/TopIconButton";
import TherapistPortal from "./subApps/therapist/TherapistPortal";
import {H1} from "@blueprintjs/core";

import './App.scss';
import {Login, Logout, Register} from "../views/Auth";

function App({
  history,
}) {
  function goTo(link) {
    history.push(link);
  }

  return <div className="App">
    <Switch>
      <Route exact path="/">
        <div className="portal-type">
          <H1>TeleNeuroforma</H1>
          <p>Wybierz serwis, do którego chcesz przejść</p>
          <div className="buttons">
            <TopIconButton
              text="Jestem pacjentem"
              icon="person"

              onClick={() => goTo('patient')}
            />
            <TopIconButton
              text="Jestem terapeutą"
              icon="pulse"

              onClick={() => goTo('therapist')}
            />
          </div>
        </div>
      </Route>
      <Route path="/patient/">
        <BrowserRouter basename="patient">
          <Switch>
          <Route exact path="/login/">
            <Login />
          </Route>
          <Route exact path="/register/">
            <Register />
          </Route>
          <Route exact path="/logout/">
            <Logout
              redirectUri="/patient/"
            />
          </Route>
          <PatientPortal/>
          </Switch>
        </BrowserRouter>
      </Route>
      <Route path="/therapist/">
        <BrowserRouter basename="therapist">
          <Route exact path="/login/">
            <Login />
          </Route>
          <Route exact path="/register/">
            <Register />
          </Route>
          <Route exact path="/logout/">
            <Logout
              redirectUri="/patient/"
            />
          </Route>
          <TherapistPortal/>
        </BrowserRouter>
      </Route>
    </Switch>
  </div>
}

export default withRouter(App);
