import React, {useEffect} from 'react';
import {Button, ButtonGroup, Checkbox, FormGroup, Icon, Intent, NonIdealState, TextArea} from "@blueprintjs/core";
import classNames from 'classnames';

import CardHeading from "ui/text/CardHeading";
import ResponsiveCard from "ui/ResponsiveCard";
import FlexDiv from "ui/layout/FlexDiv";

import "./Reminders.scss";
import {TimePicker} from "@blueprintjs/datetime";
import FullScreenOverlay from "ui/FullScreenOverlay";
import {useDispatch, useSelector} from "react-redux";
import RemindersActions from "redux/actions/patient/reminders-actions";
import Loader from "ui/Loader";
import {initPushSupport} from "../../../lib/pushNotifications";


const DAYS_SHORT = [
  "Pn",
  "Wt",
  "Śr",
  "Cz",
  "Pt",
  "Sb",
  "Nd",
];

export default function Reminders(props) {
  const dispatch = useDispatch();
  const reminders = useSelector(state => state.patient.reminders.list);
  const currentReminder = useSelector(state => state.patient.reminders.current);
  const isProcessing = useSelector(state => state.processing.isProcessing);

  useEffect(() => {
    dispatch(RemindersActions.requestReminders());
  }, [dispatch]);

  const addReminder = () => {
    dispatch(RemindersActions.startAddingReminder());
  };

  const editReminder$ = (id$) => () => {
    dispatch(RemindersActions.startEditingReminder(id$));
  };

  const deleteReminder$ = (id$) => () => {
    dispatch(RemindersActions.deleteReminder(id$));
  };

  const stopEditingReminder = () => {
    dispatch(RemindersActions.stopEditing());
  };

  const saveCurrentReminder = () => {
    dispatch(RemindersActions.saveCurrentReminder());
    if (currentReminder.data.push) {
      initPushSupport();
    }
  };

  const changeCurrentReminder$ = (key$) => (value) => {
    dispatch(RemindersActions.changeCurrentReminder({
      [key$]: value,
    }))
  };

  if (reminders === undefined) {
    return <ResponsiveCard
      size={ResponsiveCard.SIZES.md}
    >
      <Loader
        text="Wczytuję powiadomienia"
      />
    </ResponsiveCard>
  }

  return <ResponsiveCard
    className="Reminders"
    size={ResponsiveCard.SIZES.md}
  >
    <CardHeading>Twoje przypomnienia</CardHeading>
    <div className={classNames("reminders-list", {"bp3-skeleton": isProcessing})}>
    {reminders.length === 0 &&
      <NonIdealState
        icon="inbox"
        title="Brak ustawionych przypomnień"
        description="Kliknij przycisk poniżej, aby dodać przypomnienie"

      />
    }
    {reminders.map((info, index) => (
      <ReminderInfo key={index}
        edit={editReminder$(info.id)}
        remove={deleteReminder$(info.id)}
        {...info}
      />
    ))}
    </div>
    <FlexDiv>
      <Button
        large={true}
        icon="add"
        text="Dodaj nowe przypomnienie"
        intent={Intent.PRIMARY}

        onClick={addReminder}
      />
    </FlexDiv>
    <FullScreenOverlay
      isOpen={currentReminder.editing}
      onClose={stopEditingReminder}
    >
      <ResponsiveCard className="reminder-editor"
        size={ResponsiveCard.SIZES.md}
      >
        <Reminder
          days={currentReminder.data.days}
          time={currentReminder.data.time}
          email={currentReminder.data.email}
          push={currentReminder.data.push}

          changeReminder$={changeCurrentReminder$}

          save={saveCurrentReminder}
        />
      </ResponsiveCard>
    </FullScreenOverlay>
  </ResponsiveCard>
}

function Reminder({days, time, email, push, description, changeReminder$, save}) {
  return <div className="Reminder">
    <CardHeading>Dodaj przypomnienie</CardHeading>
    <div className="reminder-info">
      <FormGroup
        label="Dni przypomnienia"
      >
      <ButtonGroup className="days">
        {days.map((active, index) => (
          <Button key={index}
            large={true}
            text={DAYS_SHORT[index]}
            intent={active ? Intent.SUCCESS : Intent.DANGER}

            onClick={() => {
              let newDays = [...days];
              newDays[index] = !days[index];
              changeReminder$('days')(newDays);
            }}
          />
        ))}
      </ButtonGroup>
      </FormGroup>
      <FormGroup
        label="Godzina przypomnienia"
      >
        <TimePicker
          showArrowButtons={true}

          value={time}
          onChange={changeReminder$("time")}
        />
      </FormGroup>
      <FormGroup
        className="reminder-types"
        label="Sposób przypomnienia"
      >
        <Checkbox
          checked={email}
          label="E-mail"

          onChange={e => changeReminder$("email")(e.target.checked)}
        />
        <Checkbox
          checked={push}
          label="Push"

          onChange={e => changeReminder$("push")(e.target.checked)}
        />
      </FormGroup>
    </div>
    <div className="description">
      <FormGroup
        label="Opis"
        labelFor="description"
        helperText="Opis wyświetli się na przypomnieniu"
      >
        <TextArea id="description"
          value={description}
          fill={true}

          onChange={e => changeReminder$("description")(e.target.value)}
        />
      </FormGroup>
    </div>
    <FlexDiv>
      <ButtonGroup>
        <Button
          large={true}
          intent={Intent.PRIMARY}

          icon="edit"
          text="Zapisz przypomnienie"

          onClick={save}
        />
      </ButtonGroup>
    </FlexDiv>
  </div>
}

function ReminderInfo({days, time, email, push, description, edit, remove}) {
  return <div className="ReminderInfo">
    <div className="info">
      <ReminderInfoDays
        days={days}
      />
      <div className="date">
        {time.toLocaleDateString("pl", {dateStyle:undefined, timeStyle: "short"})}
      </div>
      <div className="types">
        <div className="email">
          <Icon
            icon="envelope"
            intent={email ? Intent.SUCCESS : Intent.DANGER}
          />
        </div>
        <div className="push">
          <Icon
            iconSize={Icon.SIZE_LARGE}
            icon="mobile-phone"
            intent={push ? Intent.SUCCESS : Intent.DANGER}
          />
        </div>
      </div>
      <div className="description">
        {description}
      </div>
    </div>
    <div className="actions">
      <ButtonGroup>
        <Button
          large={true}

          icon="edit"
          text="Edytuj"

          intent={Intent.PRIMARY}

          onClick={edit}
        />
        <Button
          large={true}
          icon="delete"

          intent={Intent.DANGER}

          onClick={remove}
        />
      </ButtonGroup>
    </div>
  </div>
}

function ReminderInfoDays({days}) {
  return <div className="ReminderInfoDays">
    <ul>
      {days.map((day, index) => (
        <li key={index} className={day ? "active" : "inactive"}>{DAYS_SHORT[index]}</li>
      ))}
    </ul>
  </div>
}