import React from 'react';
import {Button, Intent} from "@blueprintjs/core";

import "./CloseCardButton.scss";

export default function CloseCardButton({onClick}) {
  return <Button className="CloseCardButton"
    icon="cross"
    large={true}

    intent={Intent.DANGER}

    onClick={onClick}
  />
}