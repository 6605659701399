import {random} from "lodash";

import ButterflyIcon from "images/game-icons/butterfly.svg";
import RacingIcon from "images/game-icons/racing.svg";
import BoxingIcon from "images/game-icons/boxing.svg";

const REPORT_DATA = {
  REPORT: {
    durations: [],
    exercises: {
      1: {
        id: 1,
        name: "Boks",
        icon: BoxingIcon,
        stats: [],
      },
      2: {
        id: 2,
        name: "Motyl",
        icon: ButterflyIcon,
        stats: [],
      },
      3: {
        id: 3,
        name: "Tor",
        icon: RacingIcon,
        stats: [],
      },
    }
  }
};

const DAYS_OF_EXERCISE = 45;
const START_DATE = new Date();
const END_DATE = new Date();
START_DATE.setDate(END_DATE.getDate() - DAYS_OF_EXERCISE);

const PROBABILITY_OF_EXERCISING = 75;

let levels = {
  box: 2,
  butterfly: 3,
  racing: 2,
};

for (let delta = 0; delta <= DAYS_OF_EXERCISE; delta++) {
  const exercised = random(0, 100) <= PROBABILITY_OF_EXERCISING;
  let date = new Date(START_DATE);
  date.setDate(START_DATE.getDate() + delta);
  const duration = exercised ? random (18 * 60, 24 * 60) : 0;

  if (exercised) {
    levels = {
      box: Math.max(1, Math.min(28, levels["box"] + (random(0, 4) - 1))),
      butterfly: Math.max(1, Math.min(28, levels["butterfly"] + (random(0, 4) - 1))),
      racing: Math.max(1, Math.min(28, levels["racing"] + (random(0, 4) - 1))),
    };

    REPORT_DATA.REPORT.durations.push({
      date,
      duration,
    });
    REPORT_DATA.REPORT.exercises[1].stats.push({
      date,
      level: levels.box,
    });
    REPORT_DATA.REPORT.exercises[2].stats.push({
      date,
      level: levels.butterfly,
    });
    REPORT_DATA.REPORT.exercises[3].stats.push({
      date,
      level: levels.racing,
    });
  }
}

export default REPORT_DATA;