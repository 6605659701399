import {Collapse} from "@blueprintjs/core";
import React from "react";

import "./FormError.scss";

export default function FormError({errors, errorKey}) {
  return <Collapse
    className="error-message-collapse"
    isOpen={errors[errorKey] !== undefined}
  >
    <div className="FormError text-danger">
      {errors[errorKey] && errors[errorKey].map((error, index) => (
        <p key={index}>{error}</p>
      ))}
    </div>
  </Collapse>
}