import React, {useState} from "react";
import {Button, ButtonGroup, Intent} from "@blueprintjs/core";

import MultimediaRecorder from "ui/components/MultimediaRecorder";
import {TYPES} from "../Messages";

const videoJsOptions = {
  controls: false,
  fluid: true,
  inactivityTimeout: 100,
  audioEngine: "opus-recorder",
  plugins: {
    record: {
      audio: true,
      maxLength: 10,
      debug: true,
      autoMuteDevice: true,
    }
  }
};

export default function SendMultimediaMessageForm({sendMessage, close, video=false}) {
  const [recordedData, setRecordedData] = useState(undefined);
  const [trim, setTrim] = useState(undefined);
  const [duration, setDuration] = useState(undefined);

  const updateRecordedData = (newRecordedData, newTrim, newDuration) => {
    setRecordedData(newRecordedData);
    setTrim(newTrim);
    setDuration(newDuration);
  };

  videoJsOptions["plugins"]["record"]["video"] = video;
  if (!video) {
    videoJsOptions["plugins"]["wavesurfer"] = {
      src: 'live',
      waveColor: '#36393b',
      progressColor: 'black',
      debug: true,
      cursorWidth: 1,
      msDisplayMax: 20,
      hideScrollbar: true
    };
  }

  const innerSendMessage = () => {
    sendMessage(
      video ? TYPES.VIDEO : TYPES.VOICE,
      recordedData,
      trim,
      duration,
    );
  };

  return <div className="SendMultimediaMessageForm">
    <MultimediaRecorder
      video={video}
      onRecordingChanged={updateRecordedData}
    />
    <div className="send-button-container">
      <ButtonGroup>
        <Button
          text="Zamknij"
          large={true}
          icon="cross"

          onClick={close}
        />
        <Button
          disabled={!recordedData}
          text="Wyślij"
          large={true}
          intent={Intent.PRIMARY}
          icon="envelope"

          onClick={innerSendMessage}
        />
      </ButtonGroup>
    </div>
  </div>
}