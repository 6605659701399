import React, {useEffect, useState} from 'react';
import {Button, ButtonGroup, Checkbox, H4, HTMLTable, Icon, Intent, NonIdealState} from "@blueprintjs/core";
import classNames from 'classnames';
import MomentLocaleUtils from "react-day-picker/moment";
import 'moment/locale/pl';

import ResponsiveCard from "ui/ResponsiveCard";
import CardHeading from "ui/text/CardHeading";

import "./Tasks.scss";
import Loader from "../../../ui/Loader";
import {Link, withRouter} from "react-router-dom";
import {DatePicker} from "@blueprintjs/datetime";
import FullScreenOverlay from "../../../ui/FullScreenOverlay";
import useToggle from "../../../lib/useToggle";
import {useDispatch, useSelector} from "react-redux";
import TasksActions from "../../../redux/actions/therapist/tasks-actions";
import {defaultTo} from "lodash";

import {TASK_TYPES} from "redux/reducers/therapist/tasks-reducer";
import createDatePickerPolishShortcuts from "lib/createDatePickerPolishShortcuts";
import PatientsActions from "../../../redux/actions/therapist/patients-actions";
import {initPushSupport} from "../../../lib/pushNotifications";

export const TASK_MESSAGES = {
  [TASK_TYPES.NEW_SESSION]: "Stwórz nową sesję",
  [TASK_TYPES.NOT_EXERCISING]: "Zmotywuj pacjenta",
  [TASK_TYPES.REPORT]: "Napisz raport z ćwiczeń",
};

export const TASK_ICONS = {
  [TASK_TYPES.NEW_SESSION]: "properties",
  [TASK_TYPES.NOT_EXERCISING]: "delete",
  [TASK_TYPES.REPORT]: "saved",
};

export const TASK_ACTION_URL = {
  [TASK_TYPES.NEW_SESSION]: "/sessions/",
  [TASK_TYPES.NOT_EXERCISING]: "/messages/",
  [TASK_TYPES.REPORT]: "/reports/",
};

export default function Tasks(props) {
  return <ResponsiveCard className="OuterTasks"
    size={ResponsiveCard.SIZES.md}
  >
    <InlineTasks />
  </ResponsiveCard>
}

function getTasksForDate(date, tasks) {
  return tasks.filter((task) => {
    return task.dueDate.getDate() === date.getDate();
  });
}

export function InlineTasks() {
  const dispatch = useDispatch();
  const tasks = useSelector(state => state.therapist.tasks.list);

  const [calendarDate, setCalendarDate] = useState(undefined);
  const [calendarTasks, setCalendarTasks] = useState(undefined);
  const [isNotificationSettingsOpen, toggleIsNotificationSettingsOpen] = useToggle(false);

  useEffect(() => {
    dispatch(TasksActions.requestTasks());
  }, [dispatch]);

  useEffect(() => {
    if (calendarDate) {
      setCalendarTasks(getTasksForDate(calendarDate, tasks));
    } else {
      setCalendarTasks(undefined);
    }
  }, [tasks, calendarDate]);

  const hasTasks = (date) => {
    return tasks.filter((task) => {
      return task.dueDate.toDateString() === date.toDateString();
    }).length > 0;
  };

  return <div className="Tasks">
    <CardHeading>
      Zadania
    </CardHeading>

    <div className="task-actions">
      <ButtonGroup
        large={true}
      >
        <Button
          intent={Intent.PRIMARY}
          icon="notifications"
          text="Ustawienia powiadomień"

          onClick={toggleIsNotificationSettingsOpen}
        />
      </ButtonGroup>
    </div>
    <Loader
      visible={tasks===undefined}
      text="Wczytuję zadania"
    >
      <section className="calendar">
        <DatePicker
          locale="pl"
          localeUtils={MomentLocaleUtils}

          shortcuts={createDatePickerPolishShortcuts(
            true,
            false,
            true,
            true,
          )}

          value={calendarDate}
          onChange={setCalendarDate}

          modifiers={{
            hasTasks
          }}
        />
        <div className="calendar-tasks">
          <H4>Zadania na dzień</H4>
          {calendarTasks === undefined &&
          <NonIdealState
            icon="calendar"
            title="Wybierz datę, aby pokazać zadania na dany dzień"
          />
          }
          {calendarTasks !== undefined && calendarTasks.length === 0 &&
          <NonIdealState
            icon="tick"
            title="Brak zadań na podany dzień"
          />
          }
          {calendarTasks !== undefined && calendarTasks.length > 0 &&
          <ul>
            {calendarTasks.map(({patient, type}, index) => (
              <li key={index}>
                <Link to={`/patient/${patient.id}`}>{TASK_MESSAGES[type]} ({patient.name})</Link>
              </li>
            ))}
          </ul>
          }
        </div>
      </section>
      <section>
        <HTMLTable>
          <thead>
          <tr>
            <th className="type" />
            <th className="name">Zadanie</th>
            <th className="patient">Pacjent</th>
            <th className="due">Termin</th>
            <th className="actions">Akcje</th>
          </tr>
          </thead>
          <tbody>
          {defaultTo(tasks, []).map((task, index) => (
            <ActiveTaskRow key={index}
              {...task}
            />
          ))}
          </tbody>
        </HTMLTable>
      </section>
    </Loader>
    <FullScreenOverlay
      isOpen={isNotificationSettingsOpen}
      onClose={toggleIsNotificationSettingsOpen}
    >
      <ResponsiveCard
        size={ResponsiveCard.SIZES.sm}
      >
        <TaskNotificationSettings
          save={toggleIsNotificationSettingsOpen}
        />
      </ResponsiveCard>
    </FullScreenOverlay>
  </div>
}

const ActiveTaskRow = withRouter(function({
  type, patient, dueDate,

  history,
}) {
  const dispatch = useDispatch();
  const overdue = dueDate < new Date();

  const goTo$ = (link$) => () => {
    history.push(link$);
  };

  const changePatientAndGo$ = (patientId$, link$) => () => {
    history.push(link$);
    dispatch(PatientsActions.selectPatient(patientId$));
  };

  return <tr className={classNames({overdue})}>
    <td className="type"><Icon icon={TASK_ICONS[type]} /></td>
    <td className="name">{TASK_MESSAGES[type]}</td>
    <td className="patient">
      <Button
        minimal={true}
        intent={Intent.PRIMARY}

        onClick={goTo$(`/patient/${patient.id}`)}
      >
        {patient.name}
      </Button>
    </td>
    <td className="due">
      {overdue && <Icon
        icon="error"
        intent={Intent.DANGER}
      />}
      {dueDate.toLocaleDateString("pl", {dateStyle:"short"})}
    </td>
    <td className="actions">
      <ButtonGroup
        vertical={true}
      >
        <Button
          icon="tick"
          text="Wykonaj"
          intent={Intent.PRIMARY}

          onClick={changePatientAndGo$(patient.id, TASK_ACTION_URL[type])}
        />
      </ButtonGroup>
    </td>
  </tr>
});

const TaskNotificationSettings = ({save}) => {
  const [email, toggleEmail] = useToggle(true);
  const [push, togglePush] = useToggle(false);

  const innerSave = () => {
    if (push) {
      initPushSupport();
    }

    save();
  };

  return <div className="TaskNotificationSettings">
    <CardHeading>
      Ustawienia powiadomień
    </CardHeading>
    <div className="options">
      <Checkbox
        large={true}

        checked={email}
        onChange={toggleEmail}

        label="Wysyłać powiadomienia na e-mail?"
      />
      <Checkbox
        large={true}

        checked={push}
        onChange={togglePush}

        label="Wysyłać powiadomienia push?"
      />
    </div>
    <div className="actions">
      <ButtonGroup
        large={true}
      >
        <Button
          intent={Intent.PRIMARY}
          text="Zapisz"
          icon="confirm"

          onClick={innerSave}
        />
      </ButtonGroup>
    </div>
  </div>
};