import React from "react";
import {Button, Modal} from "react-bootstrap";
import clsx from "clsx";

import "./FullScreenOverlay.scss";

export default function FullScreenOverlay({className, children, isOpen, onClose, title, actions}) {
  return (
    <Modal size="lg" className={clsx("FullScreenOverlay", className)} show={isOpen} onHide={onClose}>
      {title && <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>}
      <Modal.Body>{children}</Modal.Body>
      {actions && <Modal.Footer>
        {actions.map(({name, onClick}, index) => (
          <Button key={index}
            onClick={onClick}
          >
            {name}
          </Button>
        ))}
      </Modal.Footer>}
    </Modal>
  )
}
