import React, {useEffect} from 'react';
import {useParams} from "react-router-dom";
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import {Redirect} from "react-router-dom";

import InlineableComponent from "ui/layout/InlineableComponent";
import CardHeading from "ui/text/CardHeading";

import "./PatientProfile.scss";
import Avatar from "../../../ui/components/Avatar";
import AccountServices from "../../AccountServices";
import {Button, ButtonGroup, Callout, Icon, Intent} from "@blueprintjs/core";
import FullScreenOverlay from "../../../ui/FullScreenOverlay";
import useToggle from "lib/useToggle";
import ResponsiveCard from "../../../ui/ResponsiveCard";
import {AppToaster} from "App";
import PatientsActions from "../../../redux/actions/therapist/patients-actions";

export default function PatientProfile({inline=false}) {
  const dispatch = useDispatch();
  const { patientId } = useParams();

  const [isExtendingAccess, toggleIsExtendingAccess] = useToggle(false);
  const currentPatient = useSelector(state => state.therapist.patients.currentPatient, shallowEqual);

  useEffect(() => {
    if (patientId !== undefined) {
      dispatch(PatientsActions.selectPatient(patientId));
    }
  }, [dispatch, patientId]);

  if (!currentPatient) {
    return <Redirect to="/patients/" />
  }

  return <InlineableComponent className="PatientProfile"
    inline={inline}
  >
    <CardHeading>
      Profil pacjenta
    </CardHeading>
    <section className="info">
      <Avatar
        size="128px"
        {...currentPatient.avatar}
      />
      <div className="text-info">
        <h2>
          {currentPatient.name}
        </h2>
        <div className="description">
          {currentPatient.description}
        </div>
      </div>
    </section>
    <section className="telenf-info">
      <Callout
        intent={Intent.PRIMARY}
        title="Teleopieka aktywna!"
      >
        Pacjenta posiada aktywną tele-opiekę do <strong>7.02.2020</strong>
      </Callout>
      <ButtonGroup
        large={true}
        fill={true}
      >
        <Button
          intent={Intent.PRIMARY}

          icon="bank-account"
          text="Przedłuż dostęp"

          onClick={toggleIsExtendingAccess}
        />
      </ButtonGroup>
    </section>
    <AccountServices
      showNextPayment={false}
      inline={true}
    />
    <FullScreenOverlay
      isOpen={isExtendingAccess}
      onClose={toggleIsExtendingAccess}
    >
      <ResponsiveCard
        size={ResponsiveCard.SIZES.sm}
      >
        <ExtendAccess
          name={currentPatient.name}
          close={toggleIsExtendingAccess}
        />
      </ResponsiveCard>
    </FullScreenOverlay>
  </InlineableComponent>
}

function ExtendAccess({name, close}) {
  const extendService = () => {
    AppToaster.show({
      icon: "endorsed",
      intent: Intent.SUCCESS,
      message: "Dodano miesiąc tele-opieki dla pacjenta!",
    });

    close();
  };

  return <div className="ExtendAccess">
    <CardHeading>
      Przedłużanie dostępu dla <strong>{name}</strong>
    </CardHeading>
    <div className="credits">
      <Icon
        intent={Intent.SUCCESS}
        icon="add"
      />
      <div className="credits-info">
        Na Twoim koncie dostępne jest <strong>{5}</strong> przedłużeń tele-opieki.
      </div>
    </div>
    <div className="description">
      Czy chcesz użyć jedno z nich, aby dodać miesiąc tele-opieki dla tego pacjenta?
    </div>
    <div className="actions">
      <ButtonGroup
        fill={true}
        large={true}
      >
        <Button
          intent={Intent.SUCCESS}
          text="Przedłuż"
          icon="tick"

          onClick={extendService}
        />
        <Button
          intent={Intent.DANGER}
          text="Anuluj"
          icon="cross"

          onClick={close}
        />
      </ButtonGroup>
    </div>
  </div>
}