import axios from 'axios';
axios.defaults.withCredentials = true;
axios.defaults.xsrfHeaderName = "X-CSRFToken";
axios.defaults.xsrfCookieName = "csrftoken";

export default function serverRequest(url, method, data, onSuccess, onFailure, additionalHeaders={}) {
  // const token = localStorage.getItem('authToken');

  let headers = {
    "Accept": "application/json",
    "Content-Type": "application/json",
    ...additionalHeaders,
  };

  // if (token) {
  //   headers["Authorization"] = `Token ${token}`;
  // }

  axios({
    // url: `${API_ADDRESS}/${url}`,
    url: `/${url}`,
    method,
    data,
    headers,
  }).then(result => {
    onSuccess(result.data)
  }).catch(error => {
    if (error.request.status === 401) {
      // localStorage.removeItem("authToken");
      // localStorage.removeItem("email");
      document.dispatchEvent(new Event("unauthorized"))


      // eslint-disable-next-line no-restricted-globals
      // location.reload();
    }

    onFailure(error)
  })
}
