import classNames from "classnames";
import {Button, ButtonGroup, Icon, Intent} from "@blueprintjs/core";
import React from "react";
import {TYPE_ICONS} from "../Messages";

export default function MessageRow({
  index,
  id,
  type,
  name,
  subject,
  date,
  read,

  openMessageThread$,
  deleteMessageThread$
}) {
  return <tr
    className={classNames("MessageRow", {"unread": !read})}
  >
    <td className="type">
      <Icon
        icon={TYPE_ICONS[type]}
      />
    </td>
    <td className="read">
      <Icon
        icon={read ? "eye-open" : "eye-off"}
        intent={read ? Intent.SUCCESS : Intent.DANGER}
      />
    </td>
    <td
      className="name"
      onClick={openMessageThread$(id)}
    >
      {name}
    </td>
    <td
      className="subject"
      onClick={openMessageThread$(id)}>
      {subject}
    </td>
    <td
      className="date"
      onClick={openMessageThread$(id)}
    >
      {date}
    </td>
    <td className="actions">
      <ButtonGroup>
        <Button
          icon="eye-open"
          intent={Intent.PRIMARY}
          text="Czytaj"

          onClick={openMessageThread$(id)}
        />
        <Button
          icon="delete"
          intent={Intent.DANGER}
          text="Usuń"

          onClick={deleteMessageThread$(index)}
        />
      </ButtonGroup>
    </td>
  </tr>
}