import Action from "../Action";

export default class AuthActions {
  static TYPES = {
    TRY_LOGGING_IN: "TRY_LOGGING_IN",
    LOGGED_IN: "LOGGED_IN",
    LOGIN_ERROR: "LOGIN_ERROR",
    LOGOUT: "LOGOUT",
    REGISTER: "REGISTER",
    REGISTERED: "REGISTERED",
    REGISTRATION_ERROR: "REGISTRATION_ERROR",
    CHANGE_PASSWORD: "CHANGE_PASSWORD",
    PASSWORD_CHANGED: "PASSWORD_CHANGED",
    PASSWORD_CHANGE_ERROR: "PASSWORD_CHANGE_ERROR",

    LOGIN_WITH_SOCIAL: "LOGIN_WITH_SOCIAL",
  };

  static tryLoggingIn = (email, password) => ({
    type: TYPES.TRY_LOGGING_IN,
    payload: {
      email,
      password,
    }
  });

  static loggedIn = Action.empty(AuthActions.TYPES.LOGGED_IN);
  static loginError = Action.errors(AuthActions.TYPES.LOGIN_ERROR);

  static register = (data, therapist) => ({
    type: TYPES.REGISTER,
    payload: {
      data,
      therapist,
    }
  });

  static registered = Action.empty(AuthActions.TYPES.REGISTERED);

  static registrationError = Action.errors(AuthActions.TYPES.REGISTRATION_ERROR);

  static changePassword = (oldPassword, newPassword) => ({
    type: TYPES.CHANGE_PASSWORD,
    payload: {
      oldPassword,
      newPassword,
    }
  });

  static passwordChanged = Action.empty(AuthActions.TYPES.PASSWORD_CHANGED);

  static passwordChangeError = Action.error(AuthActions.TYPES.PASSWORD_CHANGE_ERROR);

  static logout = Action.empty(AuthActions.TYPES.LOGOUT);

  static loginWithSocial = (type, accessToken) => ({
    type: TYPES.LOGIN_WITH_SOCIAL,
    payload: {
      type,
      accessToken,
    }
  });
}

const TYPES = AuthActions.TYPES;