import React from 'react';
import {Icon} from "@blueprintjs/core";

import "./Avatar.scss";

export default function Avatar({size, image, url, color, letters}) {
  return <div className="Avatar"
    style={{
      fontSize: size
    }}
  >
    {image &&
    <img src={url} alt="awatar"/>
    }
    {!image &&
    <div className="default-avatar">
      <Icon icon="user" color={color}/>
      <div className="letters">
        <span>{letters}</span>
      </div>
    </div>
    }
  </div>
}