import React, {useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {Redirect} from "react-router-dom";

import AuthActions from "redux/actions/common/auth-actions";

export default function Logout({redirectUri}) {
  const dispatch = useDispatch();
  const loggedIn = useSelector(state => state.auth.isUserLoggedIn);

  useEffect(() => {
    dispatch(AuthActions.logout());
  });

  return <>
    {!loggedIn &&
      <Redirect to={redirectUri} />
    }
  </>
}