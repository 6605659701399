import React from "react";
import {Card} from "@blueprintjs/core";
import classNames from 'classnames';

import "./ResponsiveCard.scss";

export default function ResponsiveCard({className, size, children}) {
  return <Card
    className={classNames("ResponsiveCard", className, {
      [size]: size,
    })}
  >
    {children}
  </Card>
}

ResponsiveCard.SIZES = {
  sm: "sm",
  md: "md",
  lg: "lg",
};