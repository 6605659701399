import classNames from "classnames";
import React, {useCallback, useEffect, useRef, useState} from "react";
import {useDispatch, useSelector} from "react-redux";

import ExercisesActions from "redux/actions/common/exercises-actions";

import "./UnityFrame.scss";
import * as Comlink from "comlink"

const GAME_URL = process.env.REACT_APP_GAME_URL;
const RECHECK_FOCUS_INTERVAL_MS = 1000;

async function load_runner(frame) {
  let runner = Comlink.wrap(Comlink.windowEndpoint(frame.contentWindow));
  await new Promise((resolve) =>
      window.addEventListener('message', function l(evt) {
            if (evt.data.source && evt.data.source.includes("devtools")) {
              return;
            }
            if (evt.data === 'ready') {
              window.removeEventListener('message', l);
              resolve(runner)
            }
          }
      ));

  return runner
}

export default function UnityFrame() {
  const dispatch = useDispatch();
  const exerciseRunning = useSelector(state => state.exercises.running);
  const exercise = useSelector(state => state.exercises.current);

  const refocusInterval = useRef(false);

  const [comlinkInfo, setComlinkInfo] = useState({
    initialised: false,
    runner: undefined,
  });
  const gameRef = useRef();

  const returnFocus = useCallback(() => {
    if (exerciseRunning) {
      gameRef.current.focus();

    }
  }, [exerciseRunning, gameRef]);

  useEffect(() => {
    async function checkRunner() {
      return await load_runner(gameRef.current);
    }

    checkRunner().then(runner => {
      setComlinkInfo({
        initialised: true,
        runner,
      });
    })
  }, []);

  useEffect(() => {
    if (exerciseRunning) {
      refocusInterval.current = setInterval(returnFocus, RECHECK_FOCUS_INTERVAL_MS);
    } else {
      clearInterval(refocusInterval.current)
    }

    return () => {
      clearInterval(refocusInterval.current)
    }
  }, [returnFocus, exerciseRunning]);

  useEffect(() => {
    if (document.getElementById("game-frame")) {
      if (exerciseRunning) {
        try {
          document.getElementById("game-frame").requestFullscreen();
        } catch (e) {
          console.warn(`Couldn't open full screen: ${e.message}`);
        }
      } else if (document.fullscreenElement) {
        document.exitFullscreen();
      }
    }
  }, [exerciseRunning]);

  const exerciseFinished = useCallback((data) => {
    dispatch(ExercisesActions.exerciseFinished(data.exerciseResults, data.exerciseResults.finishLevel));
  }, [dispatch]);

  useEffect(() => {
    if (comlinkInfo.runner !== undefined && comlinkInfo.initialised && exerciseRunning && exercise.id !== undefined) {
      const {runner} = comlinkInfo;
       async function runExercise(exerciseData) {
         const settings = exerciseData.patient_settings;
         let areas = [];
         for (let hand of settings.hand) {
           if (hand === 'left') {
             areas.push('tl', 'bl');
           } else if (hand === 'right') {
             areas.push('tr', 'br');
           }
         }

          await runner.load();
          const result = await runner.start({
                  exercise: exerciseData.base === undefined ? "halves" : exerciseData.base,
                  level: settings.level === undefined ? 10 : settings.level,
                  areas: areas.length === 0 ? ['tl','br','tr','bl'] : areas,
                  total_rounds: settings.duration > 0 ? settings.duration : 3,
                }
          );

          exerciseFinished(result);
        }

        runExercise(exercise.data);
    }
  }, [comlinkInfo, exerciseFinished, dispatch, exercise, exerciseRunning]);

  return (
      <div className={classNames("UnityFrame", {"exercise-running": exerciseRunning})}>
        <iframe id="game-frame" ref={gameRef} allow="camera" title="nf-exercises-frame" src={GAME_URL}
          allowFullScreen
        />
      </div>
  )
}
