import serverRequest from "../../lib/serverRequest";

const PROFILE_API = {
  async requestProfileInfo(email) {
    let url;
    if (email) {
      url = "api/user";
    } else {
      url = "api/user/logged-in";
    }

    return new Promise((resolve) => {
      serverRequest(
        url,
        "GET",
        {
          email,
        },
        (result) => {
          resolve(result);
        },
        (result) => {
          resolve(false);
        }
      );
    });
  },

  // async saveProfileInfo(data) {
  //   return new Promise((resolve) => {
  //     PROFILE_DATA[data['email']] = data;
  //     simulateRequestWithResolve(resolve, true);
  //   });
  // }
};

export default PROFILE_API;