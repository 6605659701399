import React from "react";
import {Dropdown, DropdownItem} from "react-bootstrap";
import {withRouter} from "react-router-dom";
import DropdownToggle from "react-bootstrap/DropdownToggle";
import DropdownMenu from "react-bootstrap/DropdownMenu";

import useGoToWithHistory from "lib/useGoToWithHistory";
import VAvatar from "vuexy/components/VAvatar";

import "./SettingsButton.scss";

function SettingsButton({
  actions,
  profile,

  history,
}) {
  const goTo = useGoToWithHistory(history);

  return <Dropdown className="SettingsButton">
    <DropdownToggle as={MenuProfileButtonForProfile$(profile)} id="user-menu-toggle" />
    <DropdownMenu alignRight={true}>
      {actions.map(({separator=false, icon, text, action, link}, index) => (
        separator
          ? <hr key={index} />
          : <DropdownItem key={index}

            onClick={link ? () => goTo(link) : action}
          >{text}</DropdownItem>
      ))}
    </DropdownMenu>
  </Dropdown>
}

const MenuProfileButtonForProfile$ = (profile$) => React.forwardRef(({...other}, ref) => {
  let display = "";

  if (profile$ !== undefined) {
    if (profile$.first_name && profile$.first_name.length > 0) {
      display += profile$.first_name[0].toUpperCase()
    }

    if (profile$.last_name && profile$.last_name.length > 0) {
      display += profile$.last_name[0].toUpperCase()
    }
  }

  return <div {...other}>
    <VAvatar
      content={display} color="primary"
    />
  </div>
});

export default withRouter(SettingsButton);