import React, {useEffect, useState} from 'react';
import {
  Button,
  Callout,
  Card,
  Collapse,
  FormGroup,
  H3,
  HTMLSelect,
  InputGroup,
  Intent,
  Spinner
} from "@blueprintjs/core";
import CardHeading from "ui/text/CardHeading";

import "./EditProfile.scss"
import {useDispatch, useSelector} from "react-redux";
import ProfileActions from "../../redux/actions/common/profile-actions";
import {defaultTo} from "lodash";
import Loader from "../../ui/Loader";
import ResponsiveCard from "../../ui/ResponsiveCard";

import {AppToaster} from "App";
import AuthActions from "../../redux/actions/common/auth-actions";

export default function EditProfile({forTherapist=false}) {
  const dispatch = useDispatch();
  const profile = useSelector(state => state.profile.current);
  const profileErrorMessage = useSelector(state => state.profile.processingErrorMessage);
  const passwordErrorMessage = useSelector(state => state.auth.error);
  const isProfileProcessing = useSelector(state => state.profile.isProcessing);
  const isPasswordProcessing = useSelector(state => state.auth.isProcessing);
  const [startedProcessingProfile, setStartedProcessingProfile] = useState(false);
  const [startedProcessingPassword, setStartedProcessingPassword] = useState(false);
  const [changePasswordData, setChangePasswordData] = useState({});

  useEffect(() => {
    if (profile === undefined) {
      dispatch(ProfileActions.requestProfile());
    }
  }, [profile, dispatch]);

  useEffect(() => {
    if (startedProcessingProfile && !isProfileProcessing && !profileErrorMessage) {
      AppToaster.show({
        icon: "endorsed",
        intent: Intent.SUCCESS,
        message: "Dane zaktualizowane.",
      });

      setStartedProcessingProfile(false);
    }

    if (startedProcessingPassword && !isPasswordProcessing && !passwordErrorMessage) {
      AppToaster.show({
        icon: "endorsed",
        intent: Intent.SUCCESS,
        message: "Hasło zmienione.",
      });

      setStartedProcessingPassword(false);
    }
  }, [
    startedProcessingProfile, startedProcessingPassword,
    profileErrorMessage, passwordErrorMessage,
    isProfileProcessing, isPasswordProcessing
  ]);

  const changeProfileFieldValue = (field, value) => {
    dispatch(ProfileActions.changeProfileField(field, value));
  };

  const changePasswordFieldValue = (field, value) => {
    setChangePasswordData({
      ...changePasswordData,
      [field]: value,
    })
  };

  const saveProfile = () => {
    setStartedProcessingProfile(true);
    dispatch(ProfileActions.saveProfile());
  };

  const savePassword = () => {
    if (changePasswordData['new_password'] !== changePasswordData['new_password_repeat']) {
      dispatch(AuthActions.passwordChangeError("Hasło i powtórzone hasło nie są takie same"));
    } else {
      setStartedProcessingPassword(true);
      dispatch(AuthActions.changePassword(changePasswordData['old_password'], changePasswordData['new_password']))
    }
  };

  if (!profile) {
    return <ResponsiveCard
      size={ResponsiveCard.SIZES.sm}
    >
      <Loader
        text="Wczytuję profil"
      />
    </ResponsiveCard>
  }

  return <Card className="EditProfile">
    <CardHeading>
      Edycja profilu
    </CardHeading>
    <H3>Podstawowe informacje</H3>
    <form>
      <FormGroup
        label="Adres e-mail"
        labelFor="email"
        labelInfo="*"
      >
        <InputGroup id="email"
          fill={true}
          disabled={true}

          value={defaultTo(profile["email"], "")}
          onChange={e => changeProfileFieldValue("email", e.target.value)}
        />
      </FormGroup>
      <FormGroup
        label="Imię"
        labelFor="first_name"
        labelInfo="*"
      >
        <InputGroup id="first_name"
          fill={true}

          value={defaultTo(profile["first_name"], "")}
          onChange={e => changeProfileFieldValue("first_name", e.target.value)}
        />
      </FormGroup>
      <FormGroup
        label="Nazwisko"
        labelFor="last_name"
        labelInfo="*"
      >
        <InputGroup id="last_name"
          fill={true}

          value={defaultTo(profile["last_name"], "")}
          onChange={e => changeProfileFieldValue("last_name", e.target.value)}
        />
      </FormGroup>
      {forTherapist && <>
        <FormGroup
          label="Stanowisko"
          labelFor="job-type"
        >
          <InputGroup id="job-type"
            fill={true}

            value={defaultTo(profile["job_type"], "")}
            onChange={e => changeProfileFieldValue("job_type", e.target.value)}
          />
        </FormGroup>
        <FormGroup
          label="Specjalizacja"
          labelFor="specialisation"
        >
          <HTMLSelect id="specialisation"
            fill={true}

            value={defaultTo(profile["specialisation"], "")}
            onChange={e => changeProfileFieldValue("specialisation", e.currentTarget.value)}
          >
            <option value="hands">Ręce</option>
            <option value="legs">Nogi</option>
            <option value="mobility">Zakres ruchu</option>
            <option value="neuro">Urazy neurologiczne</option>
          </HTMLSelect>
        </FormGroup>
      </>}
      <Collapse
        isOpen={profileErrorMessage}
      >
        <Callout
          intent={Intent.DANGER}
        >
          {profileErrorMessage}
        </Callout>
      </Collapse>
      <Button
        large={true}
        fill={true}

        disabled={isProfileProcessing}

        onClick={(e) => {
          e.preventDefault();
          saveProfile();
        }}
        text={isProfileProcessing ? "Aktualizuję profil" : "Zaktualizuj profil"}
        icon={isProfileProcessing ? <Spinner size={Spinner.SIZE_SMALL} /> : "automatic-updates"}
        intent={Intent.PRIMARY}

        type="submit"
      />
    </form>
    <H3>Zmiana hasła</H3>
    <form>
      <FormGroup
        label="Aktualne hasło"
        labelFor="old-password"
        labelInfo="*"
      >
        <InputGroup id="old-password"
          fill={true}
          type="password"

          value={defaultTo(changePasswordData["old_password"], "")}
          onChange={e => changePasswordFieldValue("old_password", e.target.value)}
        />
      </FormGroup>
      <FormGroup
        label="Nowe hasło"
        labelFor="new-password"
        labelInfo="*"
      >
        <InputGroup id="new-password"
          fill={true}
          type="password"

          value={defaultTo(changePasswordData["new_password"], "")}
          onChange={e => changePasswordFieldValue("new_password", e.target.value)}
        />
      </FormGroup>
      <FormGroup
        label="Powtórz hasło"
        labelFor="new-password-repeat"
        labelInfo="*"
      >
        <InputGroup id="new-password-repeat"
          fill={true}
          type="password"

          value={defaultTo(changePasswordData["new_password_repeat"], "")}
          onChange={e => changePasswordFieldValue("new_password_repeat", e.target.value)}
        />
      </FormGroup>
      <Collapse
        isOpen={passwordErrorMessage}
      >
        <Callout
          intent={Intent.DANGER}
        >
          {passwordErrorMessage}
        </Callout>
      </Collapse>
      <Button
        large={true}
        fill={true}

        type="submit"

        disabled={isPasswordProcessing}
        text={isPasswordProcessing ? "Zmieniam hasło" : "Zmień hasło"}
        icon={isPasswordProcessing ? <Spinner size={Spinner.SIZE_SMALL} /> : "automatic-updates"}
        intent={Intent.PRIMARY}

        onClick={(e) => {
          e.preventDefault();
          savePassword();
        }}
      />
    </form>
  </Card>
}