import simulateRequestWithResolve from "../simulateRequestWithResolve";
import EARNINGS_DATA from "test/data/therapist/earnings-data";

const EARNINGS_MOCK_API = {
  async requestEarnings() {
    return new Promise((resolve) => {
      simulateRequestWithResolve(
        resolve,
        {
          list: Object.values(EARNINGS_DATA.EARNINGS),
          nextPayment: EARNINGS_DATA.NEXT_PAY_DATE,
        },
      );
    });
  },
};

export default EARNINGS_MOCK_API;