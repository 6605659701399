export default class ReportActions {
  static TYPES = {
    REQUEST_REPORT: "REQUEST_REPORT",
    REPORT_CHANGED: "REPORT_CHANGED",
  };

  static requestReport = (startDate, endDate) => ({
    type: TYPES.REQUEST_REPORT,
    payload: {
      startDate,
      endDate,
    },
  });

  static reportChanged = (report) => ({
    type: TYPES.REPORT_CHANGED,
    payload: {
      report
    }
  });
}

const TYPES = ReportActions.TYPES;