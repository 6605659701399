const TYPES = {
  UPDATE_PATIENTS: "UPDATE_PATIENTS",
  PATIENTS_UPDATED: "PATIENTS_UPDATED",
  SELECT_PATIENT: "SELECT_PATIENT",
};

export default class PatientsActions {
  static TYPES = TYPES;

  static updatePatients = () => ({
    type: TYPES.UPDATE_PATIENTS,
    payload: {},
  });

  static patientsUpdated = (patientsList) => ({
    type: TYPES.PATIENTS_UPDATED,
    payload: {
      patientsList,
    }
  });

  static selectPatient = (patientId) => ({
    type: TYPES.SELECT_PATIENT,
    payload: {
      patientId: patientId,
    }
  });
}