import React, {useCallback, useEffect, useRef, useState} from "react";
import {Button, ButtonGroup, Collapse, Intent, RangeSlider} from "@blueprintjs/core";

import 'video.js/dist/video-js.css';
import videojs from 'video.js';
import WaveSurfer from 'wavesurfer.js';
import MicrophonePlugin from 'wavesurfer.js/dist/plugin/wavesurfer.microphone.js';

import "./MultimediaRecorder.scss";

WaveSurfer.microphone = MicrophonePlugin;

const videoJsOptions = {
  controls: false,
  fluid: true,
  inactivityTimeout: 100,
  plugins: {
    record: {
      audio: true,
      maxLength: 10,
      debug: false,
      autoMuteDevice: true,
      videoMimeType: "video/webm",
      convertEngine: 'ts-ebml',
    }
  }
};

let player;
let recordedData;
let gTrimStamps = [0, 0];
let stopAudioTimeout;

export default function MultimediaRecorder({onRecordingChanged, video=false}) {
  const [isRecording, setIsRecording] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);
  // const [recordedData, setRecordedData] = useState(undefined);
  const videoRef = useRef(null);
  const [duration, setDuration] = useState(0);
  const [trimStamps, setTrimStamps] = useState([0, 0]);
  const [isTrimming, setIsTrimming] = useState(false);
  const [isVideoReady, setIsVideoReady] = useState(false);

  videoJsOptions["plugins"]["record"]["video"] = video;

  if (!video) {
    // Without video we visualize in wavesurfer
    videoJsOptions["plugins"]["wavesurfer"] = {
      src: 'live',
      waveColor: '#36393b',
      progressColor: 'black',
      cursorWidth: 1,
      msDisplayMax: 20,
      hideScrollbar: true
    };
  }

  const updateSrcFromTrims = useCallback(
    () => {
      if (recordedData && video) {
        player.src({
          type: recordedData.type,
          src: `${URL.createObjectURL(recordedData)}#t=${gTrimStamps[0] / 10},${gTrimStamps[1] / 10}`,
        });
        onRecordingChanged(recordedData, trimStamps, duration)
      }
    },
    [duration, onRecordingChanged, trimStamps, video],
  );

  const playerFinishRecord = useCallback(() => {
    setIsRecording(false);
    player.record().stopDevice();
    recordedData = player.recordedData;
    // let trimData;
    // if (trimStamps[1] > 0) {
    //   trimData = [trimStamps[0] / 10, trimStamps[1] / 10];
    // }

    let newDuration;
    if (!video) {
      newDuration = player.wavesurfer().getDuration();
    } else {
      newDuration = player.duration();
    }

    onRecordingChanged(recordedData, trimStamps, newDuration);

    setDuration(newDuration);
    setTrimStamps([0, Math.floor(newDuration * 10)]);
    gTrimStamps = [0, Math.floor(newDuration * 10)];
  }, [onRecordingChanged, trimStamps, video]);

  const playerFinishConvert = useCallback(() => {
    player.src({
      type: player.convertedData.type,
      src: `${URL.createObjectURL(player.convertedData)}`,
    });
    player.load();
    recordedData = player.convertedData;
    // let trimData;
    // if (trimStamps[1] > 0) {
    //   trimData = [trimStamps[0] / 10, trimStamps[1] / 10];
    // }

    let newDuration;
    if (!video) {
      newDuration = player.wavesurfer().getDuration();
    } else {
      newDuration = player.duration();
    }
    setDuration(newDuration);

    onRecordingChanged(recordedData, trimStamps, newDuration);

    setIsVideoReady(true);
  }, [onRecordingChanged, trimStamps, video]);

  const finishedPlaying = useCallback(
    () => {
      setIsPlaying(false);
      updateSrcFromTrims();
    },
    [updateSrcFromTrims, setIsPlaying],
  );

  const toggleIsTrimming = () => {
    let newDuration;

    if (!video) {
      newDuration = player.wavesurfer().getDuration();
    } else {
      newDuration = player.duration();
    }

    if (newDuration < Infinity) {
      setDuration(newDuration * 10);
      setTrimStamps([0, Math.floor(newDuration * 10)]);
      gTrimStamps = [0, Math.floor(newDuration * 10)];
      setIsTrimming(!isTrimming);
    } else {
      player.play().then(() => player.pause());
      setTimeout(toggleIsTrimming, 100);
    }
  };

  const checkDuration = useCallback(() => {
    if (!player) {
      return
    }

    let newDuration;
    if (!video) {
      newDuration = player.wavesurfer().getDuration();
    } else {
      newDuration = player.duration();
    }

    if (newDuration === Infinity) {
      setTimeout(checkDuration, 100);
      console.log(":(");
    } else {
      setDuration(newDuration);
      onRecordingChanged(recordedData, trimStamps, newDuration);
      console.log(`:) ${newDuration}`);
    }
  }, [onRecordingChanged, trimStamps, video]);

  useEffect(() => {
    checkDuration();
  }, [duration, checkDuration]);

  useEffect(() => {
    player = videojs(videoRef.current, videoJsOptions);

    player.on('deviceReady', () => {
      player.record().start();
    });

    player.on('startRecord', () => {
      setIsRecording(true);
      recordedData = undefined;
      setTrimStamps([0, 0]);
      gTrimStamps = [0, 0];
      setDuration(0);
      setIsTrimming(false);
    });


    player.on('finishRecord', playerFinishRecord);

    player.on('finishConvert', playerFinishConvert);

    player.on('finishPlayback', finishedPlaying);
    player.on('ended', finishedPlaying);
    player.on('pause', finishedPlaying);

    // error handling
    player.on('error', (element, error) => {
      console.warn(`Error: ${error}`);
    });

    player.on('deviceError', () => {
      console.error('device error:', player.deviceErrorCode);
    });

    player.on('timeupdate', e => {
      console.log(e);
    });

    // destroy player on unmount
    return () => {
      if (player) {
        player.dispose();
      }
      clearTimeout(stopAudioTimeout);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const changeTrimStamps = ([start, finish]) => {
    setTrimStamps([start/10, finish/10]);
    gTrimStamps = [start/10, finish/10];
    updateSrcFromTrims();
  };

  const trimStampsValueForSlider = () => {
    return([trimStamps[0]*10, trimStamps[1]*10]);
  };

  const playRecorded = () => {
    setIsPlaying(true);
    // updateSrcFromTrims();
    if (!video) {
      // player.wavesurfer().surfer.seekTo(trimStamps[0] / duration);
      if (trimStamps[1] > 0) {
        player.wavesurfer().surfer.play(trimStamps[0] / 10, trimStamps[1] / 10);
        setTimeout(finishedPlaying, (trimStamps[1] - trimStamps[0]) * 100)
      } else {
        player.wavesurfer().play();
      }
    } else {
      player.play();
    }
  };

  const stopRecorded = () => {
    clearTimeout(stopAudioTimeout);

    setIsPlaying(false);
    if (!video) {
      player.wavesurfer().surfer.stop();
    } else {
      player.pause();
    }
  };

  // function finishedPlaying() {
  //   setIsPlaying(false);
  //   updateSrcFromTrims();
  // }

  const startRecording = () => {
    if (isPlaying) {
      stopRecorded();
    }
    setIsRecording(true);
    player.record().getDevice();
  };

  const stopRecording = () => {
    setIsRecording(false);
    player.record().stop();
  };

  return <div className="MultimediaRecorder">
    <div data-vjs-player="">
      <video id="myVideo" ref={videoRef} className="video-js vjs-default-skin" playsInline/>
    </div>
    <Collapse className="duration-slider" isOpen={isTrimming}>
      <RangeSlider
        min={0}
        max={Math.floor(duration)*10}
        labelStepSize={10}
        labelRenderer={v => `${v/10}s`}

        value={trimStampsValueForSlider()}
        onChange={changeTrimStamps}
      />
    </Collapse>
    <ButtonGroup className="record-controls"
      large={true}
      fill={true}
    >
      <Button
        intent={Intent.PRIMARY}
        icon={isPlaying ? "stop" : "play"}
        text={isPlaying ? "Zastopuj" : "Odtwórz"}

        disabled={!isVideoReady}

        onClick={isPlaying ? stopRecorded : playRecorded}
      />
      <Button
        intent={Intent.PRIMARY}
        icon={"cut"}
        text={"Przytnij"}

        disabled={!isVideoReady}

        onClick={toggleIsTrimming}
      />
      <Button
        intent={Intent.DANGER}
        icon={isRecording ? "stop" : "record"}
        text={isRecording ? "Zastopuj" : "Nagraj"}

        onClick={isRecording ? stopRecording : startRecording}
      />
    </ButtonGroup>
  </div>
}