import SERVICES_MOCK_API from "test/mock-api/common/services-mock-api";
import {put, all, takeEvery,} from "redux-saga/effects";
import ServicesActions from "redux/actions/common/services-actions";

class ServicesSagas {
  static* requestServices() {
    const list = yield SERVICES_MOCK_API.requestServices();
    yield put(ServicesActions.servicesChanged(list));
  }

  static* watchRequestServices() {
    yield takeEvery(ServicesActions.TYPES.REQUEST_SERVICES, ServicesSagas.requestServices);
  }

  static* requestPayments() {
    const list = yield SERVICES_MOCK_API.requestPayments();
    yield put(ServicesActions.paymentsChanged(list));
  }

  static* watchRequestPayments() {
    yield takeEvery(ServicesActions.TYPES.REQUEST_PAYMENTS, ServicesSagas.requestPayments);
  }
}

export default function* servicesSaga() {
  yield all([
    ServicesSagas.watchRequestServices(),
    ServicesSagas.watchRequestPayments(),
  ])
}