import serverRequest from "lib/serverRequest";

const MESSAGES_API = {
  async requestMessages(patientId) {
    return new Promise(resolve => {
      serverRequest(
        `api/patients/${patientId}/messages`,
        "GET",
        {},
        (result) => {
          resolve(result);
        },
        (result) => {
          resolve(false);
        }
      );
    })
  },

  async sendMessage(message, patientId) {
    return new Promise(resolve => {
      let type = 'application/json';

      if (message.recording !== undefined) {
        type = "multipart/form-data";
      }

      // TODO: should use patientId
      serverRequest(
        `api/messages`,
        "POST",
        {
          ...message,
        },
        (result) => {
          resolve(result);
        },
        (result) => {
          resolve(false);
        },
        {
          "Content-Type": type,
        }
      );
    });
  },

  // async requestMessages() {
  //   return new Promise((resolve) => {
  //     simulateRequestWithResolve(resolve, Object.values(MESSAGES_DATA.messages).sort((a, b) => {
  //       return b.date > a.date ? 1 : -1;
  //     }));
  //   });
  // },
  //
  // async deleteMessage(id) {
  //   return new Promise((resolve) => {
  //     delete MESSAGES_DATA.messages[id];
  //     simulateRequestWithResolve(resolve, true);
  //   });
  // },
  //
  // async sendMessage(message) {
  //   return new Promise(resolve => {
  //     debugger;
  //     MESSAGES_DATA.messages[NEXT_ID] = {
  //       ...message,
  //       id: NEXT_ID,
  //       read: false,
  //       date: new Date().toLocaleString(undefined, {
  //         year: "numeric",
  //         month:	"2-digit",
  //         day: "2-digit",
  //         hour: "2-digit",
  //         minute:	"2-digit",
  //       }),
  //       avatar: BozenaPhoto,
  //       name: "Bożena Stokowa",
  //       subject: "Wiadomość od pacjenta",
  //     };
  //     NEXT_ID++;
  //     simulateRequestWithResolve(resolve, true);
  //   });
  // }
};

export default MESSAGES_API;