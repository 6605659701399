import groupByKey from "lib/groupByKey";
import ServicesActions from "../../actions/common/services-actions";

const initialState = {
  active: undefined,
  inactive: undefined,
  byId: undefined,

  payments: undefined,
};

const TYPES = ServicesActions.TYPES;

export const SERVICE_TYPES = {
  ACCESS: "ACCESS",
  TELENF: "TELENF",
};

export const PAYMENT_TYPES = {
  CREDIT_CARD: "CREDIT_CARD",
};

export default function servicesReducer(state = initialState, action = {}) {
  const {type, payload} = action;

  switch(type) {
    case TYPES.SERVICES_CHANGED: {
      const {list} = payload;
      const byId = groupByKey(list, "id");
      const active = list.filter(service => service.from < new Date() && new Date() < service.to);
      const inactive = list.filter(service => service.from >= new Date() || new Date() >= service.to);

      return {
        ...state,
        active,
        inactive,
        byId,
      };
    }

    case TYPES.PAYMENTS_CHANGED: {
      const {list} = payload;

      return {
        ...state,
        payments: list,
      };
    }

    default:
      return state
  }
}