import React, {useEffect, useState} from 'react';
import {Link, Redirect, Switch, useLocation} from "react-router-dom";
import {useDispatch, useSelector} from 'react-redux';
// import {Alignment, ButtonGroup, Intent} from "@blueprintjs/core";
import clsx from 'clsx';

// import NFLogo from "images/logo.png";
// import NFName from "images/name.png";
//
// import NavLinkButton from "ui/NavLinkButton";

import {AppToaster} from "App";

import './Portal.scss';
import {initPushSupport} from "lib/pushNotifications";
import ProfileActions from "redux/actions/common/profile-actions";
import UnityFrame from "views/UnityFrame/UnityFrame";
import AuthActions from "../../redux/actions/common/auth-actions";
// import Loader from "../../ui/Loader";
import VContent from "../../vuexy/components/VContent";
import {Row, Container, Col} from "react-bootstrap";

import "vuexy/css/core/menu/menu-types/horizontal-menu.css";

import PATIENT_URLS from "enums/patient/urls";
import THERAPIST_URLS from "enums/therapist/urls";
import Menu from "./subcomponents/Menu";

function Portal({
  className,
  isTherapist,
  subtitle,

  routeComponents,

  menuItems,
  customMenuItemsLeft,
  customMenuItemsRight,

  settingsActions,
}) {
  const dispatch = useDispatch();
  const location = useLocation();

  const isUserLoggedIn = useSelector(state => state.auth.isUserLoggedIn);
  const profile = useSelector(state => state.profile.loggedIn ? state.profile.loggedIn : undefined);
  const usesPush = useSelector(state => state.profile.loggedIn ? state.profile.loggedIn.uses_push : false);

  const [breadcrumbs, setBreadcrumbs] = useState([]);
  const [authorizationChecked, setAuthorizationChecked] = useState(false);

  const isProfileLoaded = isUserLoggedIn && profile !== undefined && profile.id !== undefined;

  useEffect(() => {
    if (location !== undefined && location.pathname !== undefined) {
      let newBreadcrumbs = [];
      let urls = isTherapist ? THERAPIST_URLS : PATIENT_URLS;
      let currentUrl = "";

      for (let part of location.pathname.split("/")) {
        if (part === "") {
          continue
        }

        let info = urls[part];
        if (info === undefined) {
          break;
        } else {
          currentUrl += `/${part}`;
          newBreadcrumbs.push({
            name: info.name,
            url: currentUrl,
          })
          urls = info.urls;
        }
      }

      setBreadcrumbs(newBreadcrumbs);
    }
  }, [location, isTherapist]);

  useEffect(() => {
    document.addEventListener("unauthorized", () => {
      if (isUserLoggedIn) {
        dispatch(AuthActions.logout());
      }
      setAuthorizationChecked(true);
    });
  }, [dispatch, isUserLoggedIn]);

  useEffect(() => {
    dispatch(ProfileActions.requestProfile());
  }, [dispatch]);

  useEffect(() => {
    if (isUserLoggedIn) {
      AppToaster.show({
        icon: "endorsed",
        // intent: Intent.SUCCESS,
        message: "Zalogowano!",
        timeout: 2000,
      });

      setAuthorizationChecked(true);
      dispatch(ProfileActions.requestProfile());
    }
  }, [dispatch, isUserLoggedIn]);

  useEffect(() => {
    if (usesPush) {
      initPushSupport().then(r => console.log(r));
    }
  }, [usesPush]);

  if (authorizationChecked && !isUserLoggedIn) {
    return <Redirect to="/login/" />
  }

  return <div className={clsx("Portal", className)}>
    <Menu
      profile={profile}
      settingsActions={settingsActions}
    />
    <VContent>
      {/*{false && <Navbar fixedToTop={true}>*/}
      {/*  <Navbar.Group align={Alignment.LEFT}>*/}
      {/*    <Navbar.Heading id="nf-heading">*/}
      {/*      <img className="logo" src={NFLogo} alt="Neuroforma logo"/>*/}
      {/*      <div className="name">*/}
      {/*        <img src={NFName} alt="Neuroforma" />*/}
      {/*        <span className="subtitle">{subtitle}</span>*/}
      {/*      </div>*/}
      {/*    </Navbar.Heading>*/}
      {/*  </Navbar.Group>*/}
      {/*  {!authorizationChecked && !isUserLoggedIn &&*/}
      {/*    <Loader className="login-check"*/}
      {/*      inline={true}*/}
      {/*      text="Sprawdzam dane logowania"*/}
      {/*    />*/}
      {/*  }*/}
      {/*  { isProfileLoaded && <>*/}
      {/*    <Navbar.Group align={Alignment.LEFT}>*/}
      {/*      <ButtonGroup className="menu">*/}
      {/*        {*/}
      {/*          customMenuItemsLeft*/}
      {/*        }*/}
      {/*        {*/}
      {/*          menuItems.map(({text, link, disabled=false}, index) => (*/}
      {/*            <NavLinkButton key={index}*/}
      {/*              to={link}*/}
      {/*              disabled={disabled}*/}

      {/*              large={true}*/}
      {/*              minimal={true}*/}
      {/*            >*/}
      {/*              { text }*/}
      {/*            </NavLinkButton>*/}
      {/*          ))*/}
      {/*        }*/}
      {/*      </ButtonGroup>*/}
      {/*    </Navbar.Group>*/}
      {/*    <Navbar.Group align={Alignment.RIGHT}>*/}
      {/*      <div className="custom-menu-right">*/}
      {/*        {*/}
      {/*          customMenuItemsRight*/}
      {/*        }*/}
      {/*      </div>*/}
      {/*      <SettingsButton*/}
      {/*        actions={settingsActions}*/}
      {/*      />*/}
      {/*    </Navbar.Group>*/}
      {/*  </>}*/}
      {/*</Navbar>}*/}
      <VContent.Content>
        <Container>
          <Row>
            <Col className="breadcrumbs d-flex justify-content-start"
              xs={12}
            >
              <h2 className="content-header-title float-left mb-0">Ćwicz</h2>
              <div className="breadcrumb-wrapper col-12">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/">Kokpit</Link>
                  </li>
                  {breadcrumbs.map(({name, url}, index) => (
                    <li key={index} className="breadcrumb-item">
                      <Link to={url}>{name}</Link>
                    </li>
                  ))}
                </ol>
              </div>
            </Col>
          </Row>
          <Row>
            <Col lg={12}>
              {isProfileLoaded &&
              <Switch>
                {routeComponents}
              </Switch>
              }
            </Col>
          </Row>
        </Container>
      </VContent.Content>
    </VContent>
    { isProfileLoaded &&
      <UnityFrame/>
    }
  </div>;
}



export default Portal;
