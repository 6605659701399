import serverRequest from "lib/serverRequest";

const AUTH_API = {
  async tryLoggingIn(email, password) {
    return new Promise((resolve) => {
      serverRequest(
        "api/auth/login/",
        "POST",
        {
          // username: email,
          email,
          password,
        },
        (result) => {
          resolve({
            loggedIn: true
          });
        },
        (result) => {
          resolve({
            loggedIn: false,
            errors: result.response.data,
          });
        }
      );
    });
  },

  async loginWithSocial(type, accessToken) {
    return new Promise((resolve) => {
      serverRequest(
        `api/auth/${type}`,
        "POST",
        {
          "access_token": accessToken,
        },
        (result) => {
          resolve(true);
        },
        (result) => {
          resolve(false);
        }
      );
    });
  },

  async logout() {
    return new Promise((resolve) => {
      serverRequest(
        "api/auth/logout/",
        "POST",
        {},
        () => {
          resolve(true);
        },
        () => {
          resolve(false);
        }
      );
    });
  },

  async register(data, therapist) {
    return new Promise((resolve) => {
      const url = "api/auth/registration/";

      serverRequest(
        url,
        "POST",
        data,
        (result) => {
          resolve({
            profile: result.user,
            errors: {},
          });
        },
        (result) => {
          resolve({
            profile: undefined,
            errors: result.response.data,
          });
        }
      )
    });
  },
  //
  // async changePassword(email, oldPassword, newPassword) {
  //   return new Promise((resolve) => {
  //     if (AUTH_DATA[email] !== oldPassword) {
  //       simulateRequestWithResolve(resolve, false);
  //     } else {
  //       AUTH_DATA[email] = newPassword;
  //       simulateRequestWithResolve(resolve, true);
  //     }
  //   });
  // },
};

export default AUTH_API;