import React from 'react';
import CardHeading from "../../../ui/text/CardHeading";

import {ReactComponent as ButterflyIcon} from "images/game-icons/butterfly.svg"
import {ReactComponent as RacingIcon} from "images/game-icons/racing.svg"
import {ReactComponent as BoxingIcon} from "images/game-icons/boxing.svg"
import {Button, ButtonGroup, Icon, Intent} from "@blueprintjs/core";
import classNames from 'classnames';

import "./ExerciseFinished.scss";
import {withRouter} from "react-router-dom";

const GAME_ICON_SIZE = 48;

const PLAYED_EXERCISES = [
  {
    icon: <BoxingIcon width={GAME_ICON_SIZE} height={GAME_ICON_SIZE} />,
    name: "Boks",
    duration: 4,
    level_change: 2,
    points_percentage: 85,
  },
  {
    icon: <ButterflyIcon width={GAME_ICON_SIZE} height={GAME_ICON_SIZE} />,
    name: "Motyl",
    duration: 5,
    level_change: -1,
    points_percentage: 85,
  },
  {
    icon: <RacingIcon width={GAME_ICON_SIZE} height={GAME_ICON_SIZE} />,
    name: "Tor",
    duration: 4,
    level_change: 0,
    points_percentage: 85,
  },
];

const ExerciseFinished = ({
  history,
  forTherapist=false,
  inSession=false,
  close
}) => {
  function goTo(link) {
    history.push(link);
  }

  const exercises = inSession ? [...PLAYED_EXERCISES] : [PLAYED_EXERCISES[0]];

  return <div className="ExerciseFinished">
    <CardHeading>
      Koniec {inSession ? "sesji" : "ćwiczenia"}!
    </CardHeading>
    <div className="exercises-info">
      {exercises.map(({icon, name, duration, level_change, points_percentage}, index) => (
        <ExerciseInfo key={index}
          icon={icon}
          name={name}
          duration={duration}
          levelChange={level_change}
          pointsPercentage={points_percentage}
        />
      ))}
    </div>
    <div className="actions">
      <ButtonGroup
        large={true}
      >
        {!forTherapist && <Button
          icon="notifications"
          text="Ustaw przypomnienie o następnej sesji"

          onClick={() => goTo("/reminders/")}
        />}
        <Button
          icon="cross"
          text="Zamknij"
          intent={Intent.PRIMARY}

          onClick={close}
        />
      </ButtonGroup>
    </div>
  </div>
};

function ExerciseInfo({icon, name, duration, levelChange, pointsPercentage}) {
  let levelChangeType = "";
  if (levelChange > 0) {
    levelChangeType = "positive";
  } else if (levelChange < 0) {
    levelChangeType = "negative";
  }

  return <div className="ExerciseInfo">
    <div className="description">
      <Icon className="icon"
        icon={icon}
      />
      <span className="name">{name}</span>
    </div>
    <div className="parameter duration">
      <span className="parameter-description">Czas trwania</span>
      <span className="value">{duration}m.</span>
    </div>
    <div className={classNames("parameter", "level-change", levelChangeType)}>
      <span className="parameter-description">Zmiana poziomu</span>
      <span className="value">{levelChange}{levelChangeType === "positive" && <Icon
        icon="thumbs-up"
        intent={Intent.SUCCESS}
      />}</span>
    </div>
    <div className="parameter points">
      <span className="parameter-description">Zdobyte punkty</span>
      <span className="value">{pointsPercentage}%</span>
    </div>
  </div>
}

export default withRouter(ExerciseFinished);