import PatientsActions from "redux/actions/therapist/patients-actions";
import groupByKey from "lib/groupByKey";
import PATIENTS_DATA from "test/data/therapist/patients-data";

const initialState = {
  currentPatient: Object.values(PATIENTS_DATA.PATIENTS_LIST)[0],//undefined,
  patientsList: groupByKey(Object.values(PATIENTS_DATA.PATIENTS_LIST), "id"),//undefined,
  // currentPatient: undefined,
  // patientsList: undefined,
};

const TYPES = PatientsActions.TYPES;

export default function patients(state = initialState, action = {}) {
  const {type, payload} = action;

  switch(type) {
    case TYPES.PATIENTS_UPDATED: {
      let {patientsList} = payload;
      patientsList = groupByKey(patientsList, "id");

      let currentPatient;
      if (state.currentPatient !== undefined) {
        const currentPatientId = state.currentPatient["id"];
        currentPatient = patientsList[currentPatientId];
      }

      return {
        ...state,
        currentPatient,
        patientsList,
      }
    }

    case TYPES.SELECT_PATIENT: {
      const {patientId} = payload;
      const currentPatient = state.patientsList[patientId];

      return {
        ...state,
        currentPatient,
      };
    }

    default:
      return state
  }
}