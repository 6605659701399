// import useToggle from "lib/useToggle";
// import {ButtonGroup, Collapse, H2, H3, Icon, Intent, Tag} from "@blueprintjs/core";
// import MultimediaPlayer from "../../../ui/components/MultimediaPlayer";
import React from "react";
import {Card, Button} from "react-bootstrap";

import "./Exercise.scss";
import ExerciseTitle from "../subcomponents/ExerciseTitle";

export default function Exercise({id, name, short_description, description, icon, video, categories, parameters, selectedCategory,

  setCurrentExerciseId, actions,
  isFavourite, toggleIsFavourite,

  setCategory$
}) {
  // const [isMoreShown, toggleIsMoreShown] = useToggle(false);
  const executeAction$ = (action$) => () => {
    setCurrentExerciseId(id);
    action$(id);
  };

  // const {hand, level, duration} = parameters;

  return <Card className="Exercise">
    <Card.Header>
      <ExerciseTitle
        name={name}
        icon={icon}
        categories={categories}

        selectedCategoryId={selectedCategory}
        setCategory$={setCategory$}

        favouritesPosition={"top"}
        favouritesText={false}
        isFavourite={isFavourite}
        toggleIsFavourite={toggleIsFavourite}
      />
    </Card.Header>
    <Card.Body>
      <p className="description">
        {short_description}
      </p>
      <div className="actions">
        {actions.map(({name, primary, action}, index) => (
          <Button variant={primary ? "primary" : "outline-primary"} key={index}
            onClick={executeAction$(action)}
          >
            {name}
          </Button>)
        )}
      </div>
    </Card.Body>
  </Card>

  // return <div className="Exercise">
  //   <div className="description">
  //     <H2>{name}</H2>
  //     <div className="tags">
  //       { categories.map(({id: categoryId, name: categoryName}, index) => (
  //         <Tag key={index}
  //           interactive={true}
  //           intent={categoryId === selectedCategory ? Intent.SUCCESS : Intent.PRIMARY}
  //
  //           onClick={setCategory$(categoryId)}
  //         >
  //           {categoryName}
  //         </Tag>
  //       ))}
  //     </div>
  //     <div className="details">
  //       <p>
  //         {short_description} <Button
  //         minimal={true}
  //         intent={Intent.PRIMARY}
  //         onClick={toggleIsMoreShown}
  //       >
  //         {isMoreShown ? "Ukryj szczegóły..." : "Pokaż szczegóły..."}
  //       </Button>
  //       </p>
  //       <Collapse
  //         isOpen={isMoreShown}
  //       >
  //         <div className="more">
  //           <div className="long-description">
  //             {description}
  //           </div>
  //           {video && <>
  //             <H3>Film instruktażowy</H3>
  //             <MultimediaPlayer
  //               content={video.content}
  //               type={video.type}
  //             />
  //           </>}
  //         </div>
  //       </Collapse>
  //     </div>
  //     <div className="parameters">
  //       {level &&
  //       <div className="parameter">
  //         <Icon
  //           icon="trending-up"
  //         />
  //         Poziom: <strong>{level}</strong>
  //       </div>
  //       }
  //       {hand &&
  //       <div className="parameter">
  //         {hand.includes("left") ?
  //           <Icon
  //             icon="hand-left"
  //           />
  //           : ""
  //         }
  //         {hand.includes("right") ?
  //           <Icon
  //             icon="hand-right"
  //           />
  //           : ""
  //         }
  //         {hand.length === 2 ? "Obie ręce" :
  //           (hand.length === 0 ? "" :
  //               (hand.includes("left") ? "Lewa ręka" : "Prawa ręka")
  //           )
  //         }
  //       </div>
  //       }
  //       {duration &&
  //       <div className="parameter">
  //         <Icon
  //           icon="time"
  //         />
  //         Czas: <strong>{parameters.duration}m</strong>
  //       </div>
  //       }
  //     </div>
  //     <ButtonGroup
  //       fill={true}
  //       large={true}
  //     >
  //       {actions.map(({name, intent, icon, action}, index) => (
  //         <Button key={index}
  //           text={name}
  //           icon={icon}
  //           intent={intent}
  //
  //           onClick={executeAction$(action)}
  //         />)
  //       )}
  //     </ButtonGroup>
  //   </div>
  // </div>
}