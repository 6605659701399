const pushServerPublicKey = "BIlLkybSl78lLnxhy80BqLH4gNmvZX_9YstXV_vC_UyENahuIElAodUlNjCJ1MarDZ2IFTBkY7XOPZtxeW50ssQ";
const serverAddress = "http://localhost:8000";

function urlB64ToUint8Array(base64String) {
  const padding = '='.repeat((4 - base64String.length % 4) % 4);
  const base64 = (base64String + padding)
  .replace(/-/g, '+')
  .replace(/_/g, '/');

  const rawData = window.atob(base64);
  const outputArray = new Uint8Array(rawData.length);
  return outputArray.map((output, index) => rawData.charCodeAt(index));
}

export function isPushNotificationSupported() {
  return "serviceWorker" in navigator && "PushManager" in window;
}

export async function registerPushServiceWorker() {
  return await navigator.serviceWorker.register("/push-sw.js");
}

export async function askUserPermission() {
  return await Notification.requestPermission();
}

export async function createNotificationSubscription() {
  const serviceWorker = await navigator.serviceWorker.ready;

  const subscription = await serviceWorker.pushManager.getSubscription();
  if (subscription) {
    return false;
  } else {
    return await serviceWorker.pushManager.subscribe({
      userVisibleOnly: true,
      applicationServerKey: urlB64ToUint8Array(pushServerPublicKey),
    });
  }
}

export async function sendSubData(subscription) {
  const browser = navigator.userAgent.match(/(firefox|msie|chrome|safari|trident)/ig)[0].toLowerCase();
  const data = {
    status_type: 'subscribe',
    subscription: subscription.toJSON(),
    browser,
  };

  await fetch(`${serverAddress}/register_push`, {
    method: 'POST',
    body: JSON.stringify(data),
    headers: {
      "content-type": "application/json;charset=UTF-8",
      "sec-fetch-mode": "cors"
    },
    mode: "cors"
  });

  // handleResponse(res);
}

export async function initPushSupport() {
  if (isPushNotificationSupported()) {
    await askUserPermission();
    await registerPushServiceWorker();
    const subscription = await createNotificationSubscription();
    if (subscription) {
      await sendSubData(subscription);
    }
  }
}