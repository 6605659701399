import SESSIONS_MOCK_API from "test/mock-api/common/sessions-mock-api";
import {put, all, takeEvery, select} from "redux-saga/effects";
import SessionsActions from "redux/actions/common/sessions-actions";
import ProcessingActions from "../../actions/common/processing-actions";

class SessionsSagas {
  static* requestSessions() {
    const sessionsList = yield SESSIONS_MOCK_API.requestSessionList();
    yield put(SessionsActions.sessionsChanged(sessionsList));
  }

  static* watchRequestSessions() {
    yield takeEvery(SessionsActions.TYPES.REQUEST_SESSIONS, SessionsSagas.requestSessions);
  }

  static* addSession() {
    yield put(ProcessingActions.startProcessing("ADD_SESSION"));
    const currentData = yield select(state => state.sessions.current.data);

    const wasSessionAdded = yield SESSIONS_MOCK_API.addSession(currentData);
    yield put(ProcessingActions.stopProcessing("ADD_SESSION"));
    if (wasSessionAdded) {
      yield SessionsSagas.requestSessions();
    }
  }

  static* watchAddSession() {
    yield takeEvery(SessionsActions.TYPES.ADD_CURRENT_SESSION, SessionsSagas.addSession);
  }

  static* saveSession() {
    yield put(ProcessingActions.startProcessing("UPDATE_SESSION"));
    const currentId = yield select(state => state.sessions.current.id);
    const currentData = yield select(state => state.sessions.current.data);

    const wasSessionSaved = yield SESSIONS_MOCK_API.updateSession(currentId, currentData);
    yield put(ProcessingActions.stopProcessing("UPDATE_SESSION"));
    if (wasSessionSaved) {
      yield SessionsSagas.requestSessions();
    }
  }

  static* watchSaveSession() {
    yield takeEvery(SessionsActions.TYPES.SAVE_CURRENT_SESSION, SessionsSagas.saveSession);
  }
}

export default function* exercisesSaga() {
  yield all([
    SessionsSagas.watchRequestSessions(),
    SessionsSagas.watchAddSession(),
    SessionsSagas.watchSaveSession(),
  ])
}