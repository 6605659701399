import {Button, ButtonGroup, Callout, Card, H4, Intent} from "@blueprintjs/core";
import React from "react";

import CardHeading from "ui/text/CardHeading";

import "./SendVideoPopup.scss";

export default function SendVideoPopup({acceptRecording, rejectRecording}) {

  return <Card className="SendVideoPopup">
    <CardHeading>Nagrywanie sesji</CardHeading>
    <div className="description">
      <Callout intent={Intent.SUCCESS}>
        <H4>Teleopieka aktywna.</H4>
        <p>Czy chcesz nagrywać sesję ćwiczeniową, aby przekazać ją swojemu opiekunowi?</p>
      </Callout>
    </div>
    <ButtonGroup className="actions">
      <Button
        text="Nagrywaj"
        intent={Intent.SUCCESS}
        onClick={acceptRecording}
      />
      <Button
        text="Nie nagrywaj"
        intent={Intent.DANGER}
        onClick={rejectRecording}
      />
    </ButtonGroup>
  </Card>
}