import {put, all, takeEvery,} from "redux-saga/effects";

import EARNINGS_MOCK_API from "test/mock-api/therapist/earnings-mock-api";
import EarningsActions from "redux/actions/therapist/earnings-actions";

class EarningsSagas {
  static* requestEarnings() {
    const {list, nextPayment} = yield EARNINGS_MOCK_API.requestEarnings();
    yield put(EarningsActions.earningsChanged(list, nextPayment));
  }

  static* watchRequestEarnings() {
    yield takeEvery(EarningsActions.TYPES.REQUEST_EARNINGS, EarningsSagas.requestEarnings);
  }
}

export default function* earningsSaga() {
  yield all([
    EarningsSagas.watchRequestEarnings(),
  ])
}