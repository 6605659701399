import React, {useEffect} from 'react';
import AuthActions from "../../../redux/actions/common/auth-actions";
import {useDispatch, useSelector} from "react-redux";
import {Redirect, Route, withRouter} from "react-router-dom";

import SessionList from "views/SessionList";
import Messages from "views/Messages";
import Reports from "views/Reports";
import EditProfile from "views/EditProfile";
import Settings from "views/Settings";
import Patients from "views/therapist/Patients";
import NotificationsButton from "views/Notifications";
import Tasks from "views/therapist/Tasks";
import Earnings from "views/therapist/Earnings";
import ExerciseList from "views/ExerciseList";
import PatientInfo from "./subcomponents/PatientInfo";
import PatientProfile from "views/therapist/PatientProfile";

import "./TherapistPortal.scss";
import Portal from "../Portal";
import useGoToWithHistory from "lib/useGoToWithHistory";
import PatientsActions from "redux/actions/therapist/patients-actions";

function TherapistPortal({
  history
}) {
  const goTo = useGoToWithHistory(history);
  const currentPatient = useSelector(state => state.therapist.patients.currentPatient);
  const noPatientSelected = (currentPatient === undefined);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(PatientsActions.updatePatients());
  }, [dispatch]);

  const redirectToPatientsComponent = <Redirect
    to="/patients/"
  />;

  const routes = <>
    <Route exact path="/">
      <Redirect to="/patients/" />
    </Route>
    <Route path="/patients/">
      <Patients />
    </Route>
    <Route path="/patient/:patientId?">
      {noPatientSelected && redirectToPatientsComponent}
      <PatientProfile />
    </Route>
    <Route path="/messages/">
      {noPatientSelected && redirectToPatientsComponent}
      <Messages forTherapist={true} />
    </Route>
    <Route path="/sessions/">
      {noPatientSelected && redirectToPatientsComponent}
      <SessionList forTherapist={true} />
    </Route>
    <Route path="/reports/">
      {noPatientSelected && redirectToPatientsComponent}
      <Reports />
    </Route>
    <Route path="/tasks/">
      <Tasks />
    </Route>
    <Route path="/exercises/">
      <ExerciseList forTherapist={true} />
    </Route>
    <Route path="/edit-profile/">
      <EditProfile forTherapist={true} />
    </Route>
    <Route path="/earnings/">
      <Earnings />
    </Route>
    <Route path="/other-settings/">
      <Settings />
    </Route>
  </>;

  const customMenuItemsLeft = <>
    <PatientInfo
      choosePatient={() => goTo("/patients/")}
    />
  </>;

  const customMenuItemsRight = <>
    <NotificationsButton

    />
  </>;

  const menuItems = [
    {
      link: "/patient/",
      text: "Profil",
      disabled: noPatientSelected,
    },
    {
      link: "/messages/",
      text: "Komunikacja",
      disabled: noPatientSelected,
    },
    {
      link: "/reports/",
      text: "Rezultaty",
      disabled: noPatientSelected,
    },
    {
      link: "/sessions/",
      text: "Sesje",
      disabled: noPatientSelected,
    },
    {
      link: "/tasks/",
      text: "Zadania",
    },
    {
      link: "/exercises/",
      text: "Ćwiczenia",
    },
  ];

  const settingsActions = [
    {
      icon: "user",
      text: "Edycja profilu",
      link: "/edit-profile/",
    },
    {
      icon: "bank-account",
      text: "Usługi i płatności",
      link: "/earnings/",
    },
    {
      icon: "settings",
      text: "Pozostałe ustawienia",
      link: "/other-settings/",
    },
    {
      separator: true,
    },
    {
      icon: "log-out",
      text: "Wyloguj",
      action: () => dispatch(AuthActions.logout()),
    },
  ];

  return <Portal className="PatientPortal"
    isTherapist={true}

    subtitle="Strefa terapeuty"
    routeComponents={routes}

    customMenuItemsLeft={customMenuItemsLeft}
    customMenuItemsRight={customMenuItemsRight}
    menuItems={menuItems}
    settingsActions={settingsActions}
  />;
}

export default withRouter(TherapistPortal);
