import {defaultTo, without} from "lodash";

import ProcessingActions from "redux/actions/common/processing-actions";

const initialState = {
  isProcessing: false,
  processingIds: [],
  messages: {},
  exitIds: {},
};

const TYPES = ProcessingActions.TYPES;

export default function processingReducer(state = initialState, action = {}) {
  const {type, payload} = action;

  switch(type) {
    case(TYPES.START_PROCESSING): {
      const {processingType} = payload;
      const newProcessingIds = [...state.processingIds];
      newProcessingIds.push(processingType);

      const messages = without({...state.messages}, processingType);
      const exitIds = without({...state.exitIds}, processingType);

      return {
        ...state,
        isProcessing: true,
        processingIds: newProcessingIds,
        messages,
        exitIds,
      }
    }

    case(TYPES.STOP_PROCESSING): {
      const {processingType, message, exitId} = payload;
      const processingIds = without(state.processingIds, processingType);

      return {
        ...state,
        isProcessing: processingIds.length > 0,
        processingIds: processingIds,
        messages: {
          ...state.messages,
          [processingType]: message,
        },
        exitIds: {
          ...state.exitIds,
          [processingType]: defaultTo(exitId, 0),
        }
      }
    }

    default:
      return state
  }
}