import React, {useEffect, useState} from "react";

import {Button, ButtonGroup, Callout, H2, Icon, Intent} from "@blueprintjs/core";
import FullScreenOverlay from "ui/FullScreenOverlay";
import SendVideoPopup from "../ExerciseList/subviews/SendVideoPopup";

import "./SessionList.scss";
import ResponsiveCard from "ui/ResponsiveCard";
import SessionEditor from "./subviews/SessionEditor";
import {useToggleWithSet} from "lib/useToggle";
import ExerciseFinished from "../ExerciseList/subviews/ExerciseFinished";
import {useDispatch, useSelector} from "react-redux";
import InlineableComponent from "../../ui/layout/InlineableComponent";
import Loader from "../../ui/Loader";
import SessionsActions from "../../redux/actions/common/sessions-actions";
import {withRouter} from "react-router-dom";
import useGoToWithHistory from "../../lib/useGoToWithHistory";

function SessionList({
  inline=false,
  forTherapist=false,

  history,
}) {
  const dispatch = useDispatch();
  const sessions = useSelector(state => state.sessions.list);
  const goTo = useGoToWithHistory(history);

  const [sendVideo, setSendVideo, toggleSendVideoOverlay] = useToggleWithSet(false);
  const [sessionEditing, setSessionEditing] = useState({
    active: false,
    creating: false,
  });
  const [isExerciseFinished, setIsExerciseFinished, toggleIsExerciseFinished] = useToggleWithSet(false);

  useEffect(() => {
    dispatch(SessionsActions.requestSessions());
  }, [dispatch]);

  const beginSession$ = (id$) => () => {
    goTo(`/session/${id$}`);

    // if (forTherapist) {
    //   setIsExerciseFinished(true);
    // } else {
    //   setSendVideo(true);
    // }
  };

  const createSession = () => {
    dispatch(SessionsActions.createEmptyCurrentSession());
    setSessionEditing({
      active: true,
      creating: true,
    });
  };

  const editSession$ = (sessionId$) => () => {
    dispatch(SessionsActions.setCurrentSessionId(sessionId$));
    setSessionEditing({
      active: true,
      creating: false,
    });
  };

  const toggleSessionEditing = () => {
    setSessionEditing({
      ...sessionEditing,
      active: !sessionEditing.active,
    })
  };

  const saveSession = () => {
    if (sessionEditing.creating) {
      dispatch(SessionsActions.addCurrentSession());
    } else {
      dispatch(SessionsActions.saveCurrentSession());
    }
    toggleSessionEditing();
  };

  function finishSession() {
    setSendVideo(false);
    setIsExerciseFinished(true);
  }

  if (sessions === undefined) {
    return <InlineableComponent
      inline={inline}
    >
      <Loader
        text="Wczytuję sesje"
      />
    </InlineableComponent>
  }

  return <InlineableComponent className="SessionList"
    inline={inline}
  >
    <section className="actions">
      <Button
        large={true}
        intent={Intent.PRIMARY}
        text="Utwórz nową sesję"

        icon="add"

        onClick={createSession}
      />
    </section>
    <section className="telenf-session">
      <Callout
        icon={null}
        intent={Intent.PRIMARY}
      >
        <div className="session">
          <H2 className="title">Twoja sesja Teleopieki</H2>
          <p className="made-by">
            <Icon icon="person" /> Utworzona przez: <strong>Franek Terapeutowski</strong>
          </p>
          <p className="last-updated">
            <Icon icon="calendar" /> Ostatnio zmodyfikowana: <strong>07.01.2020</strong>
          </p>
          <Session
            forTherapist={forTherapist}

            beginSession={beginSession$(sessions[0].id)}
            editSession={editSession$(sessions[0].id)}

            {...sessions[0]}
          />
        </div>
      </Callout>
    </section>
    <section className="sessions">
    { sessions.map((session, index) => (
      <Session key={index}
        forTherapist={forTherapist}

        beginSession={beginSession$(session.id)}
        editSession={editSession$(session["id"])}

        {...session}
      />
    ))}
    </section>
    <FullScreenOverlay isOpen={sendVideo} onClose={toggleSendVideoOverlay}>
      <SendVideoPopup
        acceptRecording={finishSession}
        rejectRecording={finishSession}
      />
    </FullScreenOverlay>
    <FullScreenOverlay isOpen={isExerciseFinished} onClose={toggleIsExerciseFinished}>
      <ResponsiveCard
        size={ResponsiveCard.SIZES.md}
      >
        <ExerciseFinished
          inSession={true}
          forTherapist={forTherapist}
          close={toggleIsExerciseFinished}
        />
      </ResponsiveCard>
    </FullScreenOverlay>
    <FullScreenOverlay
      isOpen={sessionEditing.active}
      onClose={toggleSessionEditing}
    >
      <ResponsiveCard
        size={ResponsiveCard.SIZES.md}
      >
        <SessionEditor
          save={saveSession}
        />
      </ResponsiveCard>
    </FullScreenOverlay>
  </InlineableComponent>;
}

function Session({
  name, description, exercises, editable,

  forTherapist,

  beginSession,
  editSession,
}) {
  return <div className="Session">
    <H2>{name}</H2>
    <div className="description">{description}</div>
    <SessionExerciseIcons exerciseList={exercises} />
    <ButtonGroup className="action-buttons"
      large={true}
      fill={true}
    >
      {(forTherapist || editable) &&
        <Button
          text={"Edytuj sesję"}
          icon="edit"
          intent={Intent.SUCCESS}

          onClick={editSession}
        />
      }
      <Button
        text={forTherapist ? "Wypróbuj sesję" : "Rozpocznij sesję"}
        icon="play"
        intent={forTherapist ? undefined : Intent.PRIMARY}

        onClick={beginSession}
      />
    </ButtonGroup>
  </div>
}

function SessionExerciseIcons({exerciseList}) {
  let totalDuration = 0;

  return <div className="SessionExerciseIcons">
    <div className="exercises">
    {exerciseList.map(({icon, type, duration}, index) => {
      totalDuration += duration;

      return <div className="exercise" key={index}>
        <img className="icon" alt={type} src={icon}/>
        <span className="duration">{duration}m</span>
      </div>
    })}
    </div>
    <div className="total-duration">
      <p>Łączny czas ćwiczeń: <span className="duration">{totalDuration} minut</span></p>
    </div>
  </div>
}

export default withRouter(SessionList);