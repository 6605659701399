import {put, all, takeEvery, select} from "redux-saga/effects";

import {PROFILE_API} from "api/common";

import ProfileActions from "redux/actions/common/profile-actions";
import AuthActions from "../../actions/common/auth-actions";
import ProcessingActions from "../../actions/common/processing-actions";
import PROFILE_MOCK_API from "../../../test/mock-api/common/profile-mock-api";

const getCurrentProfile = state => state.profile.current;

class ProfileSagas {
  static* getProfile(action) {
    const {email} = action.payload;
    let message;
    let id = 0;

    yield put(ProcessingActions.startProcessing(ProfileActions.TYPES.GET_PROFILE));
    const profile = yield PROFILE_API.requestProfileInfo(email);
    if (profile) {
      yield put(ProfileActions.profileChanged(profile));
    } else {
      message = "Nie mogę pobrać profilu";
      id = 1;
    }

    yield put(ProcessingActions.stopProcessing(ProfileActions.TYPES.GET_PROFILE, message, id));
  }

  static* watchGetProfile() {
    yield takeEvery(ProfileActions.TYPES.GET_PROFILE, ProfileSagas.getProfile);
  }

  static* requestProfile() {
    const profile = yield PROFILE_API.requestProfileInfo();
    if (profile) {
      yield put(AuthActions.loggedIn());
      yield put(ProfileActions.profileChanged(profile));
      yield put(ProfileActions.loggedProfileChanged(profile));
    } else {
      yield put(AuthActions.logout());
    }
  }

  static* watchRequestProfile() {
    yield takeEvery(ProfileActions.TYPES.REQUEST_PROFILE, ProfileSagas.requestProfile);
  }

  static* saveProfile(action) {
    let {data} = action.payload;
    if (!data) {
      data = yield select(getCurrentProfile);
    }

    delete data['password'];
    delete data['password_repeat'];

    const wasProfileSaved = yield PROFILE_MOCK_API.saveProfileInfo(data);
    if (wasProfileSaved) {
      const profile = yield PROFILE_MOCK_API.requestProfileInfo(data['email']);
      yield put(ProfileActions.profileChanged(profile));
    } else {
      yield put(ProfileActions.profileSaveError("Incorrect profile data"));
    }
  }

  static* watchSaveProfile() {
    yield takeEvery(ProfileActions.TYPES.SAVE_PROFILE, ProfileSagas.saveProfile);
  }
}

export default function* profileSagas() {
  yield all([
    ProfileSagas.watchGetProfile(),
    ProfileSagas.watchRequestProfile(),
    ProfileSagas.watchSaveProfile(),
  ])
}