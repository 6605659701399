import AuthActions from "redux/actions/common/auth-actions";
import ProfileActions from "../../actions/common/profile-actions";

const initialState = {
  authorizationChecked: false,

  isUserLoggedIn: false,
  email: undefined,
  isProcessing: false,
  errors: {},
};

const TYPES = AuthActions.TYPES;

export default function authReducer(state = initialState, action = {}) {
  const {type, payload} = action;

  switch(type) {
    case ProfileActions.TYPES.REQUEST_PROFILE: {
      return {
        ...state,
        authorizationChecked: true,
      }
    }

    case TYPES.TRY_LOGGING_IN: {
      const {email} = payload;

      return {
        ...state,
        isProcessing: true,
        email,
        error: undefined,
      };
    }

    case TYPES.REGISTRATION_ERROR:
    case TYPES.LOGIN_ERROR: {
      const {errors} = payload;

      return {
        ...state,
        email: undefined,
        isUserLoggedIn: false,
        isProcessing: false,
        errors,
      }
    }

    case TYPES.PASSWORD_CHANGE_ERROR: {
      const {message} = payload;

      return {
        ...state,
        isProcessing: false,
        error: message,
      }
    }

    case TYPES.PASSWORD_CHANGED:
    case TYPES.REGISTERED:
    case TYPES.LOGGED_IN: {
      return {
        ...state,
        isUserLoggedIn: true,
        isProcessing: false,
        error: undefined,
      }
    }

    case TYPES.LOGOUT: {
      return {
        ...state,
        isUserLoggedIn: false,
        isProcessing: false,
        error: undefined,
      }
    }

    case TYPES.REGISTER: {
      const {data} = payload;
      const {email} = data;

      return {
        ...state,
        email,
        isProcessing: true,
      }
    }

    case TYPES.CHANGE_PASSWORD: {
      return {
        ...state,
        isProcessing: true,
      }
    }

    default:
      return state
  }
}