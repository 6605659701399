import {FormControl, FormGroup, FormLabel} from "react-bootstrap";
import FormError from "./FormError";
import React from "react";

export default function FormField({fieldKey, type, name, data, setDataForKey, processedErrors}) {
  return (
    <FormGroup >
      <FormLabel htmlFor={fieldKey}>{name}</FormLabel>
      <FormControl
        id={fieldKey}
        type={type}
        name={fieldKey}

        value={data[fieldKey]}
        onChange={e => setDataForKey(fieldKey, e.target.value)}
        isInvalid={processedErrors[fieldKey] !== undefined}
      />
      <FormError errors={processedErrors} errorKey={fieldKey} />
    </FormGroup>
  );
}