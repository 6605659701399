import {withRouter} from "react-router-dom";
import useGoToWithHistory from "../../../lib/useGoToWithHistory";
import {Button, ButtonGroup, Card, Intent} from "@blueprintjs/core";
import CloseCardButton from "../../../ui/CloseCardButton";
import CardHeading from "../../../ui/text/CardHeading";
import React from "react";

import {Message} from ".";

const MessageCard = withRouter(({history, message, close}) => {
  const goTo = useGoToWithHistory(history);

  return <Card className="MessageCard">
    <CloseCardButton
      onClick={close}
    />
    <CardHeading>
      {message.subject}
    </CardHeading>
    <div className="messages">
      <Message
        {...message}
      />
    </div>
    <div className="message-actions">
      <ButtonGroup
        large={true}
      >
        <Button
          icon="notifications"
          text="Ustaw przypomnienie"
          intent={Intent.PRIMARY}

          onClick={() => goTo("/reminders/")}
        />
        <Button
          icon="numbered-list"
          text="Przejdź do sesji"
          intent={Intent.PRIMARY}

          onClick={() => goTo("/sessions/")}
        />
        <Button
          icon="cross"
          text="Zamknij"
          intent={Intent.DANGER}

          onClick={close}
        />
      </ButtonGroup>
    </div>
  </Card>
});

export default MessageCard;