import TASKS_DATA from "test/data/therapist/tasks-data";
import simulateRequestWithResolve from "test/mock-api/simulateRequestWithResolve";

const TASKS_MOCK_API = {
  async requestTasks() {
    return new Promise((resolve) => {
      simulateRequestWithResolve(resolve, Object.values(TASKS_DATA.TASKS));
    });
  }
};

export default TASKS_MOCK_API;