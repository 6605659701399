import MESSAGES_MOCK_API from "test/mock-api/common/messages-mock-api";
import {MESSAGES_API} from "api/common";
import {put, all, takeEvery, select} from "redux-saga/effects";
import MessagesActions from "redux/actions/common/messages-actions";

class MessagesSaga {
  static* requestMessages() {
    const patientId = yield select(state => state.profile.current.id);

    const messages = yield MESSAGES_API.requestMessages(patientId);

    if (messages) {
      yield put(MessagesActions.messagesChanged(messages));
    }
  }

  static* watchRequestMessages() {
    yield takeEvery(MessagesActions.TYPES.REQUEST_MESSAGES, MessagesSaga.requestMessages);
  }

  static* deleteMessage(action) {
    const {id} = action.payload;

    yield MESSAGES_MOCK_API.deleteMessage(id);
    yield MessagesSaga.requestMessages();
  }

  static* watchDeleteMessage() {
    yield takeEvery(MessagesActions.TYPES.DELETE_MESSAGE, MessagesSaga.deleteMessage);
  }

  static* sendMessage(action) {
    const {message} = action.payload;
    const patientId = yield select(state => state.profile.current.id);

    yield MESSAGES_API.sendMessage(message, patientId);
    yield MessagesSaga.requestMessages();
  }

  static* watchSendMessage() {
    yield takeEvery(MessagesActions.TYPES.SEND_MESSAGE, MessagesSaga.sendMessage)
  }
}

export default function* messagesSaga() {
  yield all([
    MessagesSaga.watchRequestMessages(),
    MessagesSaga.watchDeleteMessage(),
    MessagesSaga.watchSendMessage(),
  ])
}