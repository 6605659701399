export default class ProfileActions {
  static TYPES = {
    REQUEST_PROFILE: "REQUEST_PROFILE",
    GET_PROFILE: "GET_PROFILE",
    PROFILE_CHANGED: "PROFILE_CHANGED",
    LOGGED_PROFILE_CHANGED: "LOGGED_PROFILE_CHANGED",
    CHANGE_PROFILE_FIELD: "CHANGE_PROFILE_FIELD",
    SAVE_PROFILE: "SAVE_PROFILE",
    PROFILE_SAVE_ERROR: "PROFILE_SAVE_ERROR",
  };

  static requestProfile = () => ({
    type: TYPES.REQUEST_PROFILE,
    payload: {},
  });

  static getProfile = (email) => ({
    type: TYPES.GET_PROFILE,
    payload: {
      email,
    }
  });

  static loggedProfileChanged = (profile) => ({
    type: TYPES.LOGGED_PROFILE_CHANGED,
    payload: {
      profile
    }
  });

  static profileChanged = (profile) => ({
    type: TYPES.PROFILE_CHANGED,
    payload: {
      profile,
    }
  });

  static changeProfileField = (field, value) => ({
    type: TYPES.CHANGE_PROFILE_FIELD,
    payload: {
      field,
      value,
    }
  });

  static saveProfile = (data) => ({
    type: TYPES.SAVE_PROFILE,
    payload: {
      data,
    }
  });

  static profileSaveError = (message) => ({
    type: TYPES.PROFILE_SAVE_ERROR,
    payload: {
      message,
    }
  })
}

const TYPES = ProfileActions.TYPES;