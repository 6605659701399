import TASKS_MOCK_API from "test/mock-api/therapist/tasks-mock-api";
import {put, all, takeEvery} from "redux-saga/effects";
import TasksActions from "redux/actions/therapist/tasks-actions";

class TasksSagas {
  static* requestTasks() {
    const tasks = yield TASKS_MOCK_API.requestTasks();
    yield put(TasksActions.tasksChanged(tasks));
  }

  static* watchRequestTasks() {
    yield takeEvery(TasksActions.TYPES.REQUEST_TASKS, TasksSagas.requestTasks);
  }
}

export default function* tasksSaga() {
  yield all([
    TasksSagas.watchRequestTasks(),
  ])
}