import React from "react";
import {shallowEqual, useSelector} from "react-redux";
import {Button, Intent} from "@blueprintjs/core";

import "./PatientInfo.scss";

export default function PatientInfo({choosePatient}) {
  const currentPatient = useSelector(state => state.therapist.patients.currentPatient, shallowEqual);
  const patientSelected = (currentPatient !== undefined);

  return<Button className="PatientInfo"
    fill={true}
    minimal={true}

    intent={patientSelected ? Intent.PRIMARY : Intent.DANGER}
    text={
      <div>
        <div className="name">
          <strong>{patientSelected ? currentPatient["name"] : "Wybierz pacjenta"}</strong>
        </div>
        <div className="choose-patient">
          {patientSelected ? "Zmień pacjenta" : ""}
        </div>
      </div>
    }

    onClick={choosePatient}
  />
}